<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline as="h3" variant="h3" class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("application.applications") }}
        </EcHeadline>

        <!-- Add  -->
        <EcButton
          class="mb-3 lg:mb-0"
          v-permissions:application-create
          iconPrefix="plus-circle"
          variant="primary-sm"
          id="add-it-app"
          @click="handleClickAdd"
        >
          {{ $t("application.buttons.addApplication") }}
        </EcButton>
      </EcFlex>

      <!-- Search box -->
    </EcFlex>
    <EcFlex class="mt-3 justify-between w-full lg:w-auto lg:mr-4">
      <RDataFilter
        ref="filterBoxRef"
        v-model="apiCriteria"
        :filterOptions="filterOptions"
        @appliedFilters="listApplications"
      ></RDataFilter>
      <div>
        <EcButton
          v-permissions:application-download
          class="mb-3 lg:mb-0"
          id="btn-export"
          :iconPrefix="exportApplicationsIcon"
          variant="primary-sm"
          @click="handleClickDownloadApplications"
        >
          {{ $t("application.buttons.exportApplication") }}
        </EcButton>
      </div>
    </EcFlex>
    <!-- Table -->
    <EcTable
      ref="dataTableRef"
      name="tbl-application-list"
      :columns="headerData"
      :dataSource="listData"
      :isLoading="isLoading"
      :rowClicked="handleClickRowItem"
      :rowCustomCss="rowCustomeCss"
      :actionMenu="true"
      :apiCriteria="apiCriteria"
      @sorted="handleColumnSort"
    >
      <!-- SLA -->
      <template v-slot:sla="{ item }">
        <EcFlex class="w-full items-center content-center justify-center">
          <EcCheckbox :disabled="true" v-model="item.sla" />
        </EcFlex>
      </template>

      <template v-slot:actionMenu="{ item }">
        <EcFlex class="items-center justify-center">
          <EcIcon icon="Pencil" class="text-c1-800 hover:cursor-pointer" width="16" @click="handleClickEdit(item.uid)"></EcIcon>
        </EcFlex>
      </template>
    </EcTable>
    <!-- Pagination -->
    <EcFlex class="flex-col my-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="apiCriteria.pagination.page"
        :limit="apiCriteria.pagination.size"
        :totalCount="apiCriteria.pagination.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="apiCriteria.pagination.page"
        :itemPerPage="apiCriteria.pagination.size"
        :totalItems="apiCriteria.pagination.total"
        @pageSizeChanged="
          (perPage) => {
            apiCriteria.setPageSize(perPage)
          }
        "
      />
    </EcFlex>

    <!-- Modal  delete owner -->
    <teleport to="#layer1">
      <ModalAddApplication
        :isModalAddNewOpen="isModalAddNewOpen"
        @handleCloseAddNewModal="handleCloseAddNewModal"
        @handleCallbackAddNew="() => listApplications(true)"
      />
      <ModalActivityByDependency :selectedDependency="selectedDependency" dependable="application"></ModalActivityByDependency>
    </teleport>
  </RLayout>
</template>

<script>
import { useApplicationList } from "@/modules/application/use/useApplicationList"
import { useGlobalStore } from "@/stores/global"
import { formatData, goto } from "@/modules/core/composables"
import ModalAddApplication from "@/modules/application/components/ModalAddApplication.vue"
import { SearchJoinTypeEnum, SortDirectionEnum, TypeCompareEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"
import ModalActivityByDependency from "@/modules/activity/components/ModalActivityByDependency.vue"

export default {
  name: "ViewApplicationList",
  setup() {
    const globalStore = useGlobalStore()
    const { listApplications, t, listData, apiCriteria, isLoading, downloadApplications } = useApplicationList()

    return {
      globalStore,
      downloadApplications,
      listApplications,
      t,
      listData,
      apiCriteria,
      isLoading,
    }
  },
  data() {
    return {
      headerData: [
        {
          key: "name",
          title: this.$t("application.labels.name"),
          sortable: true,
        },
        {
          key: "activities_count",
          sortKey: "activities_count",
          title: this.$t("application.labels.linked_activities"),
          sortable: true,
          numeric: true,
        },
        {
          key: "shortest_mtpd_name",
          title: this.$t("application.labels.shortestMTPD"),
          sortable: true,
          sortKey: "shortest_mtpd",
          numeric: true,
        },
        {
          key: "sla",
          headerAlign: "center",
          title: this.$t("application.sla.abbr"),
        },
        {
          key: "created_at",
          title: this.$t("application.labels.createdAt"),
          sortable: true,
          date: true,
        },
      ],
      filterOptions: [
        {
          key: "name",
          title: this.$t("application.labels.name"),
        },
        {
          key: "activities.tolerablePeriodDisruptions.name",
          title: this.$t("application.labels.shortestMTPD"),
        },
        {
          key: "activities.sla",
          title: this.$t("application.sla.abbr"),
        },
        {
          key: "created_at",
          title: this.$t("application.labels.createdAt"),
          type: "date",
        },
      ],

      searchQuery: "",
      isModalAddNewOpen: false,
      isModalApplicationDetailOpen: false,
      currentSelectApplication: null,
      isDownloading: false,
      selectedDependency: null,
    }
  },
  async mounted() {
    await this.apiCriteria.bindCriteria()

    this.$refs.filterBoxRef?.handleSetActiveConditions(this.apiCriteria.getSearchCriteria())
    this.$refs.dataTableRef?.setColumnActiveSort(...this.apiCriteria.getActiveSort())
    this.listApplications(true)
  },
  computed: {
    /**
     * Format time
     */
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
    /**
     * Export Icone
     */
    exportApplicationsIcon() {
      return this.isDownloading ? "Spinner" : "DocumentDownload"
    },
  },

  methods: {
    formatData,

    // Handle events

    /**
     * Add new application
     */
    handleClickAdd() {
      this.isModalAddNewOpen = true
    },

    // Handle events
    /**
     * Download
     */
    async handleClickDownloadApplications() {
      this.isDownloading = true
      await this.downloadApplications()
      this.isDownloading = false
    },

    /**
     * Cancel add applicationList
     */
    handleCloseAddNewModal() {
      this.isModalAddNewOpen = false
    },

    /**
     *
     * @param {*}  application Uid
     */
    handleClickEdit(Uid) {
      goto("ViewApplicationDetail", {
        params: {
          uid: Uid,
        },
      })
    },

    /**
     * Handle Search
     */
    async handleSearch() {
      if (this.searchQuery?.length > 0) {
        this.apiCriteria.setSearch("name", this.searchQuery, TypeCompareEnum.LIKE)
        this.apiCriteria.setRelationSearch("activities", "name", this.searchQuery, TypeCompareEnum.LIKE)
        this.apiCriteria.setRelationSearch(
          "activities.tolerablePeriodDisruptions",
          "name",
          this.searchQuery,
          TypeCompareEnum.LIKE
        )
        this.apiCriteria.setSearchJoin(SearchJoinTypeEnum.OR)
      } else {
        this.apiCriteria.clearSearch()
      }

      this.apiCriteria.resetPage()

      await this.listApplications(true)
    },

    /**
     * Handle clear search
     * @returns {Promise<void>}
     */
    async handleClearSearch() {
      this.searchQuery = ""
      this.apiCriteria.reset()
      await this.listApplications(true)
    },

    /**
     * Handle sort
     * @param sortKey
     * @returns {Promise<void>}
     */
    async handleColumnSort(col, sorted) {
      const direction = sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC

      this.apiCriteria.setSort(col?.sortKey || col?.key, direction, col?.customSort)

      this.apiCriteria.resetPage()
      await this.listApplications(true)
    },

    /**
     * Handle click application item
     * @param application
     */
    handleClickRowItem(application) {
      application.time = Date.now()
      this.selectedDependency = application
      this.isModalApplicationDetailOpen = true
    },

    /**
     *
     * @param {*} item
     */
    rowCustomeCss(item) {
      return item?.activities_count > 0 ? "text-c1-800 hover:cursor-pointer" : ""
    },
  },
  components: { ModalActivityByDependency, ModalAddApplication },
}
</script>
