<template>
  <div
    ref="ecMultiSelect"
    v-click-outside="hideOptions"
    class="relative flex justify-between items-center ec-dropdown"
    :class="[disabled ? variantCls.root_disabled : variantCls.root]"
    style="min-height: 2rem"
    @click="handleClickSelect($event)"
  >
    <!-- Tags -->
    <span v-if="tags.length === 0 && !showOptions" :class="variantCls.placeholder">
      {{ placeholder }}
    </span>
    <div v-else :class="isGroupOptions ? variantCls.tagRootGroupOptions : variantCls.tagRoot">
      <div
        v-for="(tag, idx) in tags"
        :key="tag[valueKey]"
        :class="[variantCls.tag, tag?.tag_color || 'bg-c1-800', tag?.tag_text_color || 'text-cWhite', 'tag-badge']"
      >
        <img v-if="tag[iconKey]?.length > 0" :src="tag[iconKey]" class="w-4 h-6 mr-1" />
        <span> {{ isGroupOptions ? tag?.type + ": " : "" }} {{ tag[nameKey] }}</span>
        <span
          v-if="!isSingleSelect || (isSingleSelect && allowSelectNothing)"
          class="cursor-pointer"
          :class="variantCls.tagRemove"
          @click.stop="removeTag(idx)"
          >&times;</span
        >
      </div>
    </div>
    <!-- Options -->
    <teleport v-if="isOptionsPositionAbsolute" to="#layer5">
      <transition
        :enterClass="variantCls.transition.enter"
        :enterActiveClass="variantCls.transition.enterActive"
        :leaveActiveClass="variantCls.transition.leaveActive"
        :leaveToClass="variantCls.transition.leave"
      >
        <div
          ref="dropdownOptions"
          v-if="showOptions"
          :style="dropdownStyle"
          style="max-height: 12rem"
          class="absolute overflow-y-auto z-20 scrolling-touch my-1"
          :class="variantCls.options"
        >
          <!-- Input -->
          <input
            ref="searchInput"
            class="sticky top-0"
            v-model="searchTerm"
            :class="variantCls.input"
            :placeholder="searchPlaceholder"
            :disabled="disabled"
            @input="handleInputSearch"
            @keyup.enter.exact.prevent="handleNewOptionOnNotFound($event)"
            @keyup.enter.shift.exact.prevent="handleForceNewOption($event)"
          />
          <!-- Options -->
          <div v-if="!isGroupOptions">
            <div v-for="option in filteredOptions" :key="option[valueKey]" class="option" @click="onOptionClick(option)">
              <slot :name="`option-${option[valueKey]}`">
                <p style="min-height: 2rem" :class="[getOptionClass(option), getDisabledOptionClass(option)]" :isOption="true">
                  <img v-if="option[iconKey]?.length > 0" :src="option[iconKey]" class="w-4 h-6 mr-1" />
                  {{ option[nameKey] }}
                </p>
              </slot>
            </div>

            <!-- No option was found -->
            <div v-if="filteredOptions?.length === 0" :class="variantCls.noOption">
              <EcSpinner v-if="isLoadingDataNotFound" class="inline mr-3"> </EcSpinner>{{ noDataPlaceholder }}
            </div>
            <div class="text-c1-500" v-if="filteredOptions.length > 0 && isAllowToForceCreate" :class="variantCls.noOption">
              <EcSpinner v-if="isLoadingDataNotFound" class="inline mr-3"> </EcSpinner> {{ forceCreatePlaceholder }}
            </div>
          </div>

          <!-- Option Groups -->
          <div v-if="isGroupOptions">
            <div v-for="group in filteredGroupOptions" :key="group?.name">
              <!-- Group name -->
              <div :class="variantCls.groupLabel" @click="handleClickGroupName(group?.name)">
                {{ group?.name }}

                <EcIcon :icon="groupExpansion[group?.name] ? 'CaretDown' : 'CaretRight'" width="14" height="14" />
              </div>
              <!-- group items -->
              <div class="optionGroupItems" :class="groupExpansion[group?.name] ? '' : 'hidden'">
                <div v-for="option in group.data" :key="option[valueKey]" class="option" @click="onOptionClick(option)">
                  <slot :name="`option-${option[valueKey]}`">
                    <p
                      style="min-height: 2rem"
                      :class="[getOptionClass(option), getDisabledOptionClass(option)]"
                      :isOption="true"
                    >
                      {{ option[nameKey] }}
                    </p>
                  </slot>
                </div>
              </div>
            </div>

            <!-- No option was found -->
            <div v-if="filteredGroupOptions.length === 0" :class="variantCls.noOption">
              <EcSpinner v-if="isLoadingDataNotFound" class="inline mr-3"> </EcSpinner>{{ noDataPlaceholder }}
            </div>
          </div>
        </div>
      </transition>
    </teleport>
    <transition
      v-else
      :enterClass="variantCls.transition.enter"
      :enterActiveClass="variantCls.transition.enterActive"
      :leaveActiveClass="variantCls.transition.leaveActive"
      :leaveToClass="variantCls.transition.leave"
    >
      <div
        v-if="showOptions"
        :style="variantCls.optionsStyles"
        class="absolute overflow-y-auto z-20 scrolling-touch mb-4"
        :class="variantCls.options"
      >
        <!-- Input -->
        <input
          ref="searchInput"
          class="sticky top-0"
          v-model="searchTerm"
          :class="variantCls.input"
          :placeholder="searchPlaceholder"
          :disabled="disabled"
          @input="handleInputSearch"
          @keyup.enter.exact.prevent="handleNewOptionOnNotFound($event)"
          @keyup.enter.shift.exact.prevent="handleForceNewOption($event)"
        />
        <!-- Options -->
        <div v-if="!isGroupOptions">
          <div v-for="option in filteredOptions" :key="option[valueKey]" class="option" @click="onOptionClick(option)">
            <slot :name="`option-${option[valueKey]}`">
              <p style="min-height: 2rem" :class="[getOptionClass(option), getDisabledOptionClass(option)]" :isOption="true">
                <img v-if="option[iconKey]?.length > 0" :src="option[iconKey]" class="w-4 h-6 mr-1" />
                {{ option[nameKey] }}
              </p>
            </slot>
          </div>

          <!-- No option was found -->
          <div v-if="filteredOptions?.length === 0" :class="variantCls.noOption">
            <EcSpinner v-if="isLoadingDataNotFound" class="inline mr-3"> </EcSpinner>{{ noDataPlaceholder }}
          </div>
          <div class="text-c1-500" v-if="filteredOptions.length > 0 && isAllowToForceCreate" :class="variantCls.noOption">
            <EcSpinner v-if="isLoadingDataNotFound" class="inline mr-3"> </EcSpinner> {{ forceCreatePlaceholder }}
          </div>
        </div>

        <!-- Option Groups -->
        <div v-if="isGroupOptions">
          <div v-for="group in filteredGroupOptions" :key="group?.name">
            <!-- Group name -->
            <div :class="variantCls.groupLabel" @click="handleClickGroupName(group?.name)">
              {{ group?.name }}

              <EcIcon :icon="groupExpansion[group?.name] ? 'CaretDown' : 'CaretRight'" width="14" height="14" />
            </div>
            <!-- group items -->
            <div class="optionGroupItems" :class="groupExpansion[group?.name] ? '' : 'hidden'">
              <div v-for="option in group.data" :key="option[valueKey]" class="option" @click="onOptionClick(option)">
                <slot :name="`option-${option[valueKey]}`">
                  <p style="min-height: 2rem" :class="[getOptionClass(option), getDisabledOptionClass(option)]" :isOption="true">
                    {{ option[nameKey] }}
                  </p>
                </slot>
              </div>
            </div>
          </div>

          <!-- No option was found -->
          <div v-if="filteredGroupOptions.length === 0" :class="variantCls.noOption">
            <EcSpinner v-if="isLoadingDataNotFound" class="inline mr-3"> </EcSpinner>{{ noDataPlaceholder }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import debounce from "lodash.debounce"
import { ref } from "vue"
export default {
  name: "EcMultiSelect",
  emits: ["update:modelValue", "search", "focus", "blur", "addNewOptionOnNotFound"],
  props: {
    variant: {
      type: String,
      default: "default",
    },
    nameKey: {
      type: String,
      default: "name",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    iconKey: {
      type: String,
      default: "icon",
    },
    modelValue: {
      type: [Array, Object],
      default: () => [],
    },
    options: {
      type: [Array, Object],
      required: true,
    },

    /** Group option */
    isGroupOptions: {
      type: Boolean,
      default: false,
    },
    groupKey: {
      type: Boolean,
      default: false,
    },

    isOptionsPositionAbsolute: {
      type: Boolean,
      default: false,
    },
    groupDataKey: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: "Please select",
    },
    searchPlaceholder: {
      type: String,
      default: "Type to search",
    },
    noDataPlaceholder: {
      type: String,
      default: () => "No data found",
    },
    forceCreatePlaceholder: {
      type: String,
      default: () => "Press Shift + Enter to add new record",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSingleSelect: {
      type: Boolean,
      default: false,
    },
    allowSelectNothing: {
      type: Boolean,
      default: false,
    },
    addNewOnNotFound: {
      type: Boolean,
      default: false,
    },
    closeOnAddNew: {
      type: Boolean,
      default: false,
    },
    iconCollapsed: {
      type: String,
    },
    iconExpanded: {
      type: String,
    },
    isLoadingDataNotFound: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tags: [],
      searchTerm: "",
      showOptions: false,
      groupExpansion: ref({}),
      dropdownStyle: ref({}),
    }
  },
  computed: {
    /** Single filtered options */
    filteredOptions() {
      if (!this.searchTerm) {
        return this.options
      }
      const filteredOptions = this.options.filter((item) => {
        if (item && item[this.nameKey] && item[this.nameKey].toLowerCase().includes(this.searchTerm.toLowerCase())) {
          return true
        }

        return false
      })

      return filteredOptions
    },

    /** Group Options */
    filteredGroupOptions() {
      if (!this.searchTerm) {
        return this.options
      }

      const filteredGroupOptions = this.options.map((group) => {
        // Expand the group
        this.groupExpansion[group?.name] = true

        // Clone group
        const groupClone = { ...group }

        groupClone.data = groupClone?.data.filter((item) => {
          if (item && item[this.nameKey] && item[this.nameKey].toLowerCase().includes(this.searchTerm.toLowerCase())) {
            return true
          }

          return false
        })

        return groupClone
      })

      return filteredGroupOptions
    },

    /** Variant */
    variantCls() {
      return (
        this.getComponentVariants({
          componentName: "EcMultiSelect",
          variant: this.variant,
        })?.el ?? {}
      )
    },
    isAllowToForceCreate() {
      return (
        this.addNewOnNotFound &&
        this.searchTerm?.length > 0 &&
        this.filteredOptions.every((item) => item[this.nameKey]?.toLowerCase() !== this.searchTerm.toLowerCase())
      )
    },
  },
  watch: {
    modelValue: {
      handler() {
        if (this.isSingleSelect) {
          this.tags = this.modelValue && this.modelValue[this.valueKey] ? [this.modelValue] : []
        } else {
          this.tags = this.modelValue || []
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleClickSelect(event) {
      if (this.isOptionsPositionAbsolute) {
        const rect = event.target.closest(".ec-dropdown").getBoundingClientRect() // Get the position and dimensions of the target element
        const scrollY = window.scrollY || window.pageYOffset // Get the vertical scroll position of the page
        const maxDropdownOptionHeight = 192 // Maximum height for the dropdown options
        // Define the dropdown style based on the target element's position
        const dropdownStyle = {
          width: rect.width + "px",
          left: rect.left + "px",
        }
        /**
         * If there's enough space below the target element, position the dropdown below it
         *  Adjust by adding a small buffer (10 pixels) for spacing
         *  Calculate the exact position by accounting for the scroll position to ensure accurate placement even when the page is scrolled
         */

        if (window.innerHeight - rect.bottom > maxDropdownOptionHeight + 10) {
          dropdownStyle.top = rect.bottom + scrollY + "px"
        } else {
          /**
           * If there's not enough space below, position the dropdown above the target element
           * Calculate the position from the bottom of the viewport by subtracting the target element's top position and also account for the scroll position
           */
          dropdownStyle.bottom = window.innerHeight - rect.top - scrollY + "px"
        }
        // Apply the calculated dropdown style
        this.dropdownStyle = dropdownStyle
      }

      if (!this.disabled) {
        /**
         * For Single select we will hide the options imediatly after the choosen, otherwise,
         * show the options to allow user to select more
         */
        if (event.target?.__vnode?.props?.isOption && this.isSingleSelect) {
          this.toggleOptions(false)
        } else if (!event.target?.__vnode?.props?.isOption) {
          this.toggleOptions(true)
        }

        // Focus on search input after render it
        this.$nextTick(
          function () {
            this.$refs?.searchInput?.focus()
          }.bind(this)
        )
      }
    },
    handleFocus() {
      if (!this.disabled) {
        this.$emit("focus")
        this.toggleOptions(true)
      }
    },
    handleBlur() {
      this.$emit("blur")
    },
    emit(...args) {
      if (!this.disabled) {
        this.$emit(...args)
      }
    },
    toggleOptions(value) {
      if (value === undefined) this.showOptions = !this.showOptions
      this.showOptions = value
    },

    /**
     *
     * @param {*} event
     */
    hideOptions(event) {
      if (event) {
        event.stopPropagation()
      }
      if (this.showOptions) {
        this.toggleOptions(false)
        this.clearSearch()
        this.$nextTick(
          function () {
            this.$refs?.ecMultiSelect?.focus()
          }.bind(this)
        )
      }
    },

    /**
     *
     */
    handleClickGroupName(groupName) {
      this.groupExpansion[groupName] = !this.groupExpansion[groupName]
    },

    /**
     *
     * @param {*} option
     */
    onOptionClick(option) {
      if (option?.disabled) {
        return
      }

      this.addTag(option)
      if (this.isSingleSelect) {
        this.toggleOptions(false)
        this.$nextTick(
          function () {
            this.$refs?.ecMultiSelect?.focus()
          }.bind(this)
        )
      }

      this.searchTerm = ""

      if (this.isSingleSelect) {
        this.$emit("update:modelValue", this.tags[0])
      } else {
        this.$emit("update:modelValue", this.tags)
      }
    },

    /**
     *
     * @param {*} tag
     */
    addTag(tag) {
      if (this.isSingleSelect) {
        this.tags = [JSON.parse(JSON.stringify(tag))]
        return
      }
      if (this.tags.find((t) => t[this.valueKey] === tag[this.valueKey])) return
      this.tags.push(JSON.parse(JSON.stringify(tag)))
    },

    /**
     *
     * @param {*} idx
     */
    removeTag(idx) {
      if (this.disabled) return

      this.tags.splice(idx, 1)
      this.$emit("update:modelValue", this.tags)
    },

    /**
     * Clear search
     */
    clearSearch() {
      this.searchTerm = ""
    },

    /**
     * Handle input
     */
    handleInputSearch: debounce(function () {
      this.$emit("search", this.searchTerm)
    }, 400),

    /**
     *
     * @param {*} option
     */
    getOptionClass(option) {
      return this.tags?.includes(option) ? this.variantCls?.selectedOption : this.variantCls?.option
    },
    /**
     *
     * @param {*} option
     */
    getDisabledOptionClass(option) {
      return option?.disabled ? this.variantCls?.disabledOption : ""
    },

    /**
     *
     * @param {*} event
     */
    handleNewOptionOnNotFound(event) {
      if (this.filteredOptions.length <= 0 && this.addNewOnNotFound) {
        this.addNewOption(event)
      }
    },
    handleForceNewOption(event) {
      if (this.isAllowToForceCreate && this.addNewOnNotFound) {
        this.addNewOption(event)
      }
    },
    addNewOption(event) {
      const newOption = {}

      newOption[this.nameKey] = event.target.value
      newOption.name = event.target.value
      newOption[this.valueKey] = "new-" + Date.now()

      this.$emit("addNewOptionOnNotFound", newOption)
      if (this.closeOnAddNew) {
        this.toggleOptions(false)
        this.$nextTick(
          function () {
            this.$refs?.ecMultiSelect?.focus()
          }.bind(this)
        )
      }
    },
  },
}
</script>
