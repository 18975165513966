import { ref } from "vue"
import { useDivisionList } from "@/readybc/composables/use/useDivisionList"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"

export const useSBU = () => {
  const divisions = ref([])
  const isLoading = ref(false)

  const apiCriteria = new ApiCriteria('list_business_units')
  apiCriteria.withRelation("business_units")

  const formFilters = ref({
    selectedSBUs: [],
  })

  /**
   * Fetch SBU
   */
  const fetchSBUs = async () => {
    const { getAllDivisionList } = useDivisionList()

    isLoading.value = true

    const { data } = await getAllDivisionList(apiCriteria.toQuery())
    isLoading.value = false

    if (data) {
      divisions.value = data?.map((division) => {
        division.hasChildren = division?.bussiness_unit_cnt > 0
        return division
      })
    }
  }

  return {
    fetchSBUs,
    divisions,
    formFilters,
    isLoading,
  }
}
