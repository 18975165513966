import { reactive } from "vue"
import { required, email, maxLength } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { useGlobalStore } from "@/stores/global"
import * as api from "../api/userFetcher"
import { goto } from "@/modules/core/composables"
import { nameValidator } from "@/readybc/composables/helpers/validator"

export function useUserNew() {
  const globalStore = useGlobalStore()

  const form = reactive({
    firstName: "",
    lastName: "",
    username: "",
    roles: [],
    selectedSBUs: [],
  })

  const rules = {
    form: {
      firstName: { required, nameValidator },
      lastName: { required, nameValidator },
      username: { required, email, maxLength: maxLength(225) },
      roles: { required },
    },
  }

  // Validate
  const v$ = useVuelidate(rules, { form })

  async function createNewUser(form) {
    const payload = {
      username: form.username,
      firstName: form.firstName,
      lastName: form.lastName,
      roles: form.roles,
      selectedSBUs: form.selectedSBUs,
    }

    try {
      const { data } = await api.createUser(payload)

      if (!data || data.error) {
        globalStore.addErrorToastMessage("Error, unable to create user...")

        return;
      }

      globalStore.addSuccessToastMessage("Created success, loading...")

      goto("ViewUserDetail", {
        params: {
          userId: data?.uid,
        },
      })
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  return {
    form,
    v$,
    createNewUser,
  }
}
