<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("activity.activities") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Filter-->
    <EcBox class="xl:grid-cols-2 lg:grid-cols-1 grid sm:grid-cols-1 md:grid-cols-1 gap-2 mt-4 lg:mt-8">
      <EcBox class="grid grid-cols-3 lg:mt-2">
        <!-- Data filter -->
        <EcFlex class="items-center">
          <RDataFilter
            ref="filterBoxRef"
            v-model="apiCriteria"
            :filterOptions="filterOptions"
            @appliedFilters="getActivityList"
          />
        </EcFlex>
        <EcFlex class="col-span-4 grid grid-cols-2 gap-2"> </EcFlex>
      </EcBox>

      <!-- Buttons -->
      <EcFlex class="flex-end w-full w-flex-row justify-end gap-2">
        <!-- Add activity -->
        <EcButton
          v-permissions:activity-create
          class="mb-3 lg:mb-0"
          id="btn-add-activity"
          iconPrefix="plus-circle"
          variant="primary-sm"
          @click="handleClickAddActivity"
        >
          {{ $t("activity.buttons.addActivity") }}
        </EcButton>

        <EcButton
          v-permissions:activity-bulk_modify
          class="mb-3 lg:mb-0 w-[220px]"
          id="btn-add-activity"
          iconPrefix="plus-circle"
          variant="primary-sm"
          @click="handleClickAddBulkActivities"
        >
          {{ $t("activity.buttons.addBulkActivities") }}
        </EcButton>

        <EcDropdown placement="right" position="right" :isButtonGroup="true" id="more-activity-buttons">
          <!-- Button content -->
          <template v-slot:button>
            <EcButton variant="primary-sm" class="p-2 mb-3 lg:mb-0 w-[60px]" v-tooltip="{ text: 'More actions' }">
              <EcIcon class="mt-2 ml-[-15px]" icon="Grid" width="20" height="20" />
            </EcButton>
          </template>

          <template v-slot:content>
            <EcFlex class="flex-col gap-2 w-[250px] p-2">
              <EcButton
                v-permissions:activity-download
                class="mb-3 lg:mb-0 w-full"
                :iconPrefix="exportActivityIcon"
                variant="primary-sm"
                id="btn-export-activities"
                @click="handleClickDownloadActivities"
              >
                {{ $t("activity.buttons.exportActivities") }}
              </EcButton>

              <!-- Activity roles -->
              <EcButton
                class="mb-3 lg:mb-0 w-full"
                iconPrefix="user-group"
                variant="primary-sm"
                id="btn-activity-roles"
                @click="handleClickRole"
              >
                {{ $t("activity.buttons.activityRole") }}
              </EcButton>
            </EcFlex>
          </template>
        </EcDropdown>
      </EcFlex>
    </EcBox>

    <!-- Bulk activities -->
    <EcFlex
      class="w-full flex-col gap-1 mt-2"
      v-permissions:activity-bulk_modify
      v-if="bulkAddActivitiesDraftList && bulkAddActivitiesDraftList?.length > 0"
    >
      <EcFlex
        class="w-full align-middle gap-2 px-1 py-2 border-c1-200 border-dashed border-1 rounded-md items-center"
        v-for="(draft, index) in bulkAddActivitiesDraftList"
        v-bind:key="index"
      >
        <EcText class="pl-2 text-sm">{{ draft.name }} on draft</EcText>
        <EcButton
          class="h-[35px]"
          iconPrefix="plus-circle"
          variant="primary-xs"
          id="btn-export-activities"
          @click="handleClickContinueBulkAddActivities(draft.uid)"
        >
          {{ $t("activity.buttons.continueBulkAddActivities") }}
        </EcButton>
      </EcFlex>
    </EcFlex>

    <!-- Table -->

    <EcTable
      name="tbl-activity-list"
      :isLoading="isLoading"
      :columns="headerData"
      :dataSource="activities"
      :actionMenu="true"
      :apiCriteria="apiCriteria"
      @sorted="handleSort"
      @onEdit="handleClickEditActivity"
      @onDelete="handleOpenDeleteModal"
      ref="dataTableRef"
    >
      <!-- Step -->
      <template v-slot:step="{ item }">
        <EcFlex class="items-center w-full justify-center">
          <EcBadge :variant="getActivityStep(item)?.type" class="px-2 py-1 rounded-md min-w-48 justify-center">
            {{ getActivityStep(item)?.label }}
          </EcBadge>
        </EcFlex>
      </template>
      <!-- End Step -->

      <!-- Status -->
      <template v-slot:status="{ item }">
        <EcFlex class="items-center w-full justify-center">
          <EcBadge :variant="getActivityStatus(item.status)?.type" class="px-2 py-1 rounded-full min-w-24 justify-center">
            {{ getActivityStatus(item.status)?.name }}
          </EcBadge>
        </EcFlex>
      </template>

      <template v-slot:updated_at="{ item }">
        <EcBadge :variant="getLastUpdateClassType(item.updated_at)?.type" class="px-2 py-1 rounded-full min-w-24 justify-center">
          {{ formatData(item.updated_at, dateTimeFormat) }}
        </EcBadge>
      </template>

      <!-- End Status -->
    </EcTable>

    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="apiCriteria.pagination.page"
        :limit="apiCriteria.pagination.size"
        :totalCount="apiCriteria.pagination.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="apiCriteria.pagination.page"
        :itemPerPage="apiCriteria.pagination.size"
        :totalItems="apiCriteria.pagination.total"
        @pageSizeChanged="
          (size) => {
            apiCriteria.pagination.size = size
          }
        "
      />
    </EcFlex>

    <!-- Modals -->
    <teleport to="#layer1">
      <!-- Modal Delete -->
      <EcModalSimple :isVisible="isModalDeleteOpen" variant="center-3xl" @overlay-click="handleCloseDeleteModal">
        <EcBox class="text-center">
          <EcFlex class="justify-center">
            <EcIcon class="text-cError-500" width="4rem" height="4rem" icon="TrashAlt" />
          </EcFlex>

          <!-- Messages -->
          <EcBox>
            <EcHeadline as="h2" variant="h2" class="text-cError-500 text-4xl">
              {{ $t("activity.confirmToDelete") }}
            </EcHeadline>
            <!-- Org name -->
            <EcText class="text-lg font-semibold">
              {{ this.activityNameDelete }}
            </EcText>
            <EcText class="text-c0-500 mt-4">
              {{ $t("activity.confirmDeleteQuestion") }}
            </EcText>
            <EcText class="text-c0-500 mt-2">
              {{ $t("activity.confirmDeleteAction") }}
            </EcText>
          </EcBox>

          <!-- Confirm Org name -->
          <EcBox class="mt-4">
            <RFormInput componentName="EcInputText" v-model="confirmedActivityNameDelete"></RFormInput>
          </EcBox>

          <!-- Actions -->
          <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
            <EcButton v-if="isMatchedDeleteActivityName" variant="warning" @click="handleDeleteActivity">
              {{ $t("activity.buttons.delete") }}
            </EcButton>
            <EcButton class="ml-3" variant="tertiary-outline" @click="handleCloseDeleteModal">
              {{ $t("activity.buttons.cancel") }}
            </EcButton>
          </EcFlex>

          <EcFlex v-else class="items-center justify-center mt-10 h-10">
            <EcSpinner type="dots" />
          </EcFlex>
        </EcBox>
      </EcModalSimple>

      <!-- End modal -->
    </teleport>
  </RLayout>
</template>

<script>
import { useActivityList } from "@/modules/activity/use/useActivityList"
import { useActivityDetail } from "@/modules/activity/use/useActivityDetail"
import { useDivisionList } from "../use/useDivisionList"
import { useGlobalStore } from "@/stores/global"
import { formatData, goto } from "@/modules/core/composables"
import { useBusinessUnitList } from "../use/useBusinessUnitList"
import { ref } from "vue"
import { useActivityStage } from "@/modules/activity/use/useActivityStage"
import { useActivityStatus } from "@/modules/activity/use/useActivityStatus"
import dayjs from "dayjs"
import { TypeCompareEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"
import EcDropdown from "@/components/EcDropdown"
import { useUserFilterPreference } from "@/modules/preference/use/useUserFilterPreference"

export default {
  name: "ViewActivityList",
  components: { EcDropdown },
  setup() {
    const globalStore = useGlobalStore()
    const {
      getActivityList,
      downloadActivities,
      fetchActivityListByDivisionUid,
      getListAddBulkActivitiesDraft,
      softDeleteActivity,
      activities,
      isLoading,
      apiCriteria,
      handleSort,
      t,
    } = useActivityList()

    const { getUserFilterPreference, saveUserFilterPreference } = useUserFilterPreference()

    const { statuses } = useActivityDetail()

    const divisions = ref([])
    const businessUnits = ref([])
    const { getDivisionList } = useDivisionList()
    const { getBusinessUnitList } = useBusinessUnitList()

    // Pre-selected
    const selectedDivisionUid = ref("")
    const selectedBuUid = ref("")

    const filterOptions = ref([])
    filterOptions.value = [
      {
        key: "division.uid",
        title: t("activity.labels.division"),
        data: divisions,
        type: "select",
        valueKey: "uid",
        nameKey: "name",
        selected: selectedDivisionUid,
      },
      {
        key: "businessUnit.uid",
        title: t("activity.labels.businessUnit"),
        data: businessUnits,
        type: "select",
        valueKey: "uid",
        nameKey: "name",
        selected: selectedBuUid,
      },
      { key: "name", title: t("activity.labels.name") },
      {
        key: "status",
        title: t("activity.labels.status"),
        data: [
          { name: "Started", value: "1" },
          { name: "In Progress", value: "2" },
          { name: "Complete", value: "3" },
        ],
        type: "select",
        valueKey: "value",
        nameKey: "name",
      },
      { key: "created_at", title: t("activity.labels.createdAt"), type: "date" },
      { key: "updated_at", title: t("activity.labels.updatedAt"), type: "date" },
    ]

    return {
      globalStore,
      getActivityList,
      downloadActivities,
      softDeleteActivity,
      activities,
      isLoading,
      apiCriteria,
      t,
      getDivisionList,
      getBusinessUnitList,
      divisions,
      businessUnits,
      fetchActivityListByDivisionUid,
      statuses,
      getListAddBulkActivitiesDraft,
      handleSort,

      // filters
      filterOptions,
      getUserFilterPreference,
      saveUserFilterPreference,

      // Pre-selected
      selectedDivisionUid,
      selectedBuUid,
    }
  },
  data() {
    return {
      headerData: [
        { key: "name", title: this.$t("activity.labels.activityName"), sortable: true },
        { key: "step", title: this.$t("activity.labels.step"), sortable: true, sortKey: "step;status" },
        { key: "status", title: this.$t("activity.labels.status"), sortable: true, filters: this.statuses },
        { key: "mtpd", title: this.$t("activity.labels.mtpd"), sortable: true, sortKey: "mtpd_value" },
        // { key: "created_at", title: this.$t("activity.labels.createdAt"), sortable: true, date: true },
        { key: "updated_at", title: this.$t("activity.labels.updatedAt"), sortable: true, date: true },
      ],

      isDivisionLoading: false,
      isBusinessUnitLoading: false,
      isLoadingAddBulkActivityDraft: false,
      isDownloading: false,

      // Search
      businessUnitsByDivision: [],
      isModalDeleteOpen: false,
      activityNameDelete: "",
      activityUidDelete: "",
      isDeleteLoading: false,
      confirmedActivityNameDelete: "",
      bulkAddActivitiesDraftList: [],
      currentSort: {},
    }
  },

  /**
   * Handle set pre-selected data with routes
   * @param {*} to
   * @param {*} from
   * @param {*} next
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const filterSelected = to.query
      // Setup filter from selected BU
      if (filterSelected?.selectedDivision) {
        vm.setDivisionFilter(filterSelected.selectedDivision)
      }
      if (filterSelected?.selectedBU) {
        vm.setBusinessUnitFilter(filterSelected.selectedBU)
        // Clear Query URL
        vm.$router.replace({ query: null })
      }

      next()
    })
  },

  /**
   * Before mount, fetch divisions, BUs
   */
  beforeMount() {
    this.fetchDivisions()
    this.fetchBusinessUnits()
  },

  async mounted() {
    await this.apiCriteria.bindCriteria()

    this.$refs.filterBoxRef?.handleSetActiveConditions(this.apiCriteria.getSearchCriteria())
    this.$refs.dataTableRef?.setColumnActiveSort(...this.apiCriteria.getActiveSort())
    // Set preselected
    this.selectedDivisionUid = this.apiCriteria.getSearchValue("division.uid")
    this.selectedBuUid = this.apiCriteria.getSearchValue("businessUnit.uid")

    this.fetchActivities()
    this.fetchBulkAddActivityDraft()
  },

  computed: {
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
    exportActivityIcon() {
      return this.isDownloading ? "Spinner" : "DocumentDownload"
    },
    isMatchedDeleteActivityName() {
      return this.confirmedActivityNameDelete === this.activityNameDelete
    },
  },
  watch: {
    selectedDivision() {
      this.apiCriteria.resetPage()
      this.filtersBusinessUnits()
      this.fetchActivities()
    },
    selectedBU() {
      this.apiCriteria.resetPage()
      this.fetchActivities()
    },
  },
  methods: {
    formatData,

    /**
     * fetch activities
     * @returns {Promise<void>}
     */
    async fetchActivities() {
      // set request param

      // search when have selected division
      if (this.selectedDivisionUid) {
        this.apiCriteria.setSearch("division.uid", this.selectedDivisionUid, TypeCompareEnum.EQUAL)
      } else {
        this.apiCriteria.removeSearch("division.uid", TypeCompareEnum.EQUAL)
      }
      // search when have selected business unit
      if (this.selectedBuUid) {
        this.apiCriteria.setSearch("businessUnit.uid", this.selectedBuUid, TypeCompareEnum.EQUAL)
      } else {
        this.apiCriteria.removeSearch("businessUnit.uid", TypeCompareEnum.EQUAL)
      }

      await this.getActivityList()
    },

    /**
     * convert activity status to string status
     * @param value
     * @returns {string}
     */
    getActivityStatus(value) {
      const status = this.statuses.find((item) => {
        return item.value === value
      })

      let type = "misc"
      switch (value) {
        case 2:
          type = "info"
          break
        case 3:
          type = "success"
          break
        case 4:
          type = "warning"
          break
      }

      return {
        type,
        name: status?.name || "N/A",
      }
    },

    /**
     *
     * @param {*} time
     */
    getLastUpdateClassType(time) {
      const month = dayjs(Date.now())?.diff(time, "month")

      if (month > 8 && month < 12) {
        return {
          type: "misc",
        }
      } else if (month > 12) {
        return {
          type: "orange",
        }
      }

      return {
        type: "",
      }
    },

    /**
     *
     * @param {*} item
     */
    isActivityComplete(item) {
      return item?.status === useActivityStatus.COMPLETE
    },

    /**
     *
     * @param {*} value
     */
    getActivityStep(item) {
      const stage = useActivityStage.newActivityStages()?.find((stageItem) => {
        return stageItem?.step === item?.step
      })

      let type = "misc"

      if (this.isActivityComplete(item)) {
        type = "success"
      } else {
        switch (item?.step) {
          case useActivityStage.GENERAL:
            type = "gold"
            break

          case useActivityStage.APPLICATION:
            type = "gray"
            break
        }
      }

      return {
        type,
        label: this.isActivityComplete(item) ? "Completed" : stage?.label,
      }
    },

    // =========== Handle events =========
    /**
     * Download
     */
    async handleClickDownloadActivities() {
      this.isDownloading = true
      await this.downloadActivities(this.selectedDivisionUid, this.selectedBuUid)
      this.isDownloading = false
    },

    /**
     * Add new activity
     */
    handleClickAddActivity() {
      const query = {}

      if (this.selectedDivisionUid?.length > 0) {
        query.division = this.selectedDivisionUid
      }

      if (this.selectedBuUid?.length > 0) {
        query.businessUnit = this.selectedBuUid
      }

      goto("ViewActivityNew", {
        query,
      })
    },

    /**
     * To bulk create
     */
    handleClickAddBulkActivities() {
      goto("ViewActivityBulkCreate")
    },

    /**
     * Add new activity
     */
    handleClickRole() {
      goto("ViewActivityRoleList")
    },
    /**
     *
     * @param {*} actitivtyUid
     */
    handleClickEditActivity(activity) {
      goto("ViewActivityDetail", {
        params: {
          uid: activity?.uid,
        },
      })
    },

    /**
     * Continue to Bulk
     * @param {*} uid
     */
    handleClickContinueBulkAddActivities(uid) {
      goto("ViewActivityBulkDraftDetail", {
        params: {
          uid,
        },
      })
    },

    /**
     * Selected division uid
     * @param {*} uid
     */
    setDivisionFilter(uid) {
      this.selectedDivisionUid = uid
    },

    /**
     *
     * @param {*} uid
     */
    setBusinessUnitFilter(uid) {
      this.selectedBuUid = uid
    },

    // ==== PRE-LOAD ==========
    /**
     * Fetch Division
     */
    async fetchDivisions() {
      this.isDivisionLoading = true
      const divisionRes = await this.getDivisionList()
      if (divisionRes && divisionRes.data) {
        this.divisions = divisionRes.data
      }
      this.isDivisionLoading = false
    },
    /**
     * Fetch BU
     */
    async fetchBusinessUnits() {
      this.isBusinessUnitLoading = true

      const buRes = await this.getBusinessUnitList()
      if (buRes && buRes.data) {
        this.businessUnits = buRes.data
        this.businessUnitsByDivision = this.businessUnits
      }

      this.isBusinessUnitLoading = false
    },

    /**
     * Fetch Bulk Add Activities Draft
     */
    async fetchBulkAddActivityDraft() {
      if (!this.$permissions(`activity.bulk_modify`)) {
        return
      }

      this.isLoadingAddBulkActivityDraft = true

      const response = await this.getListAddBulkActivitiesDraft()
      if (response && response.data) {
        this.bulkAddActivitiesDraftList = response.data
      }

      this.isLoadingAddBulkActivityDraft = false
    },

    // =========== Delete =========== \\

    /**
     * handle delete activity
     * @param uid
     */
    async handleDeleteActivity() {
      this.isDeleteLoading = true
      await this.softDeleteActivity(this.activityUidDelete)
      await this.getActivityList()
      this.handleCloseDeleteModal()
      this.isDeleteLoading = false
    },

    /** Open delete modal */
    handleOpenDeleteModal(activity) {
      this.activityNameDelete = activity?.name
      this.activityUidDelete = activity?.uid
      this.isModalDeleteOpen = true
    },

    /** Close delete modal */
    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
      this.confirmedActivityNameDelete = ""
      this.activityNameDelete = ""
      this.activityUidDelete = ""
    },

    /**
     *
     */
    filtersBusinessUnits() {
      if (this.selectedDivisionUid.length > 0) {
        this.businessUnitsByDivision = this.businessUnits.filter((businessUnit) => {
          return !businessUnit?.division?.uid || businessUnit?.division?.uid === this.selectedDivisionUid
        })
      } else {
        this.businessUnitsByDivision = this.businessUnits
      }
    },
  },
}
</script>
