<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="items-center flex-wrap">
      <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ $t("activity.title.editActivity") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Step bar -->
    <EcStepBar
      :options="filteredSteps"
      :current="form.step"
      :onStep="onStep"
      :clickable="!isLoading"
      @onClick="handleClickSave"
    />

    <!-- Body -->
    <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
      <!-- Title and cancel button -->
      <EcFlex>
        <EcText class="w-11/12 font-bold text-lg mb-4">{{ $t("activity.title.tolerant") }}</EcText>
      </EcFlex>

      <!-- Time -->
      <EcBox class="w-11/12 mb-8">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium mr-1">
            {{ $t("activity.tolerant.time") }}
          </EcLabel>
          <EcButton variant="transparent" v-tooltip="{ text: $t('activity.tooltips.tolerantTime') }">
            <EcIcon icon="QuestionMark" width="16" />
          </EcButton>
        </EcFlex>

        <!-- Slider -->
        <EcOptionSlider
          v-if="!isLoadingMTDPTimeOptions"
          class="mt-12"
          v-model="form.tolerable_period_disruptions"
          field="form.tolerable_period_disruptions"
          :options="filteredTimeOptions"
          valueKey="uid"
          :validator="v$"
        />
        <EcSpinner v-else />
      </EcBox>
      <!-- End add more application -->

      <!-- dependent_time-->
      <EcBox class="mb-8">
        <!-- Status -->
        <EcFlex class="flex-wrap max-w-md mb-8">
          <RFormInput
            v-model="form.dependent_time"
            :label="$t('activity.tolerant.timeOf')"
            :rows="2"
            componentName="EcInputLongText"
            showLabel
            :validator="v$"
            field="form.dependent_time"
            @input="v$.form.dependent_time.$touch()"
          />
        </EcFlex>
      </EcBox>
      <!-- Time of -->

      <!-- Time of-->
      <EcBox class="mb-8">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium"> {{ $t("activity.tolerant.reason") }}</EcLabel>
          <!-- tooltip -->
          <EcButton v-if="requiresMtpdJusitification" class="ml-2" variant="transparent" v-tooltip="{ text: $t('activity.tooltips.requireField') }">
            <EcIcon class="text-cError-500" icon="QuestionMark" width="16" height="16" />
          </EcButton>
        </EcFlex>
        <!-- Status -->
        <EcFlex class="flex-wrap max-w-md mb-8">
          <RFormInput
            v-model="form.reason_choose_dependent_time"
            :rows="2"
            componentName="EcInputLongText"
            showLabel
            :validator="v$"
            field="form.reason_choose_dependent_time"
            @input="v$.form.reason_choose_dependent_time.$touch()"
          />
        </EcFlex>
      </EcBox>
      <!-- Time of -->

      <!-- End body -->
    </EcBox>

    <!-- Actions -->
    <EcBox class="width-full mt-8 px-4 sm:px-10">
      <!-- Button back -->
      <EcFlex v-if="!isLoading" class="mt-6">
        <EcButton variant="tertiary-outline" id="btn-back" @click="handleClickBack">
          {{ $t("activity.buttons.back") }}
        </EcButton>

        <EcButton variant="primary" class="ml-4" id="btn-next" @click="handleClickNext">
          {{ $t("activity.buttons.next") }}
        </EcButton>

        <EcButton variant="primary" class="ml-8" id="btn-save" @click="handleClickSave">
          {{ $t("activity.buttons.save") }}
        </EcButton>
      </EcFlex>

      <!-- Loading -->
      <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
    </EcBox>
    <!-- End actions -->

    <!-- Modals -->
    <teleport to="#layer2">
      <ModalCancelAddActivity :isModalCancelOpen="isModalCancelOpen" @handleCloseCancelModal="handleCloseCancelModal" />
    </teleport>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useActivityTolerant } from "../use/useActivityTolerant"
import ModalCancelAddActivity from "../components/ModalCancelAddActivity.vue"
import { useActivityDetail } from "../use/useActivityDetail"
import isEmpty from "lodash.isempty"
import { useActivityStage } from "@/modules/activity/use/useActivityStage"

export default {
  name: "ViewActivityTolerant",
  data() {
    return {
      isModalCancelOpen: false,
      isLoading: false,
      isLoadingMTDPTimeOptions: false,
    }
  },
  setup() {
    // PRE LOAD

    // Functions
    const { form, v$, mtdpTimeOptions, getMTDPTimeOptions, updateActivityMTDP } = useActivityTolerant()
    const { getActivity } = useActivityDetail()

    return {
      form,
      v$,
      mtdpTimeOptions,
      getActivity,
      getMTDPTimeOptions,
      updateActivityMTDP,
    }
  },

  mounted() {
    this.fetchActivity()
    this.fetchMTDPTimeOptions()
  },

  computed: {
    /**
     * Filter Time Options
     */
    filteredTimeOptions() {
      return this.mtdpTimeOptions
    },

    /**
     *
     */
    filteredSteps() {
      return useActivityStage.updateActivityStages()
    },

    onStep() {
      return useActivityStage.MTDP
    },

    requiresMtpdJusitification() {
      return ! this.form.tolerable_period_disruptions?.value || this.form.tolerable_period_disruptions.value <= 24;
    }
  },
  methods: {
    /**
     * Create Activity
     */
    async handleClickNext() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      const { uid } = this.$route.params
      this.isLoading = true

      const payload = {
        tolerable_period_disruptions: [
          {
            uid: this.form.tolerable_period_disruptions?.uid,
            dependent_time: this.form.dependent_time,
            reason_choose_dependent_time: this.form.reason_choose_dependent_time,
          },
        ],
      }
      const response = await this.updateActivityMTDP(payload, uid)

      if (response && response.uid) {
        setTimeout(this.redirectToNextStep, 1000)
      }
      this.isLoading = false
    },
    /**
     * Save and redirect to list activities
     */
    async handleClickSave(redirect = true) {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      const { uid } = this.$route.params
      this.isLoading = true

      const payload = {
        tolerable_period_disruptions: [
          {
            uid: this.form.tolerable_period_disruptions?.uid,
            dependent_time: this.form.dependent_time,
            reason_choose_dependent_time: this.form.reason_choose_dependent_time,
          },
        ],
      }
      const response = await this.updateActivityMTDP(payload, uid)

      if (response && response.uid && redirect) {
        setTimeout(this.redirectToActivityList, 1000)
      }
      this.isLoading = false
    },
    /**
     * Redirect to activity list
     */
    redirectToActivityList() {
      goto("ViewActivityList")
    },

    /**
     * Redirect to next step
     */
    redirectToNextStep() {
      goto("ViewActivityUpdateRTO")
    },

    /**
     * Back to previous step
     */
    handleClickBack() {
      goto("ViewActivityUpdateDependencies")
    },

    /**
     * Open cancel modal
     */
    handleOpenCancelModal() {
      this.isModalCancelOpen = true
    },

    /**
     * Open cancel modal
     */
    handleCloseCancelModal() {
      this.isModalCancelOpen = false
    },

    // ======== Pre-load =======//

    /**
     * Fetch Activity
     */
    async fetchActivity() {
      const { uid } = this.$route.params

      this.isLoading = true

      const response = await this.getActivity(uid, ["tolerablePeriodDisruptions", "recoveryTimes"])

      if (response && response.uid) {
        this.transformFormData(response)
      }

      this.isLoading = false
    },

    /**
     * Transform data
     */
    transformFormData(response) {
      this.form.step = response.step
      const tpd = response?.tolerable_period_disruptions[0]

      if (tpd) {
        this.form.tolerable_period_disruptions = {
          uid: tpd?.uid,
          name: tpd?.name,
          value: tpd?.value,
        }

        this.form.dependent_time = tpd?.pivot?.dependent_time
        this.form.reason_choose_dependent_time = tpd?.pivot?.reason_choose_dependent_time
      }

      // RTO
      const rto = response?.recovery_times[0]
      if (rto) {
        this.form.recoveryTime = {
          uid: rto?.uid,
          name: rto?.name,
          value: rto?.value,
        }
      }
    },

    /**
     * Time MTDP Options
     */
    async fetchMTDPTimeOptions() {
      this.isLoadingMTDPTimeOptions = true
      const response = await this.getMTDPTimeOptions()

      if (response) {
        this.mtdpTimeOptions = response

        // Set the first option to tolerable_period_disruptions if not exists
        if (isEmpty(this.form?.tolerable_period_disruptions)) {
          this.form.tolerable_period_disruptions = this.mtdpTimeOptions[0]
        }
      }

      this.isLoadingMTDPTimeOptions = false
    },
  },
  components: { ModalCancelAddActivity },
}
</script>
