import { ref } from "vue"
import * as api from "../api/reportGeneratorFetcher"
import { downloadFromDirectLink } from "@/readybc/composables/helpers/downloadHelper"
import { useGlobalStore } from "@/stores/global"

export const useReportGenerator = () => {
  const globalStore = useGlobalStore()
  const generatedReport = ref({})
  const isTriggeredReportGenerator = ref(false)

  /**
   *
   * @param {*} type
   * @param revisionUid
   * @param filters
   * @returns
   */
  const generateReport = async (type, revisionUid, filters = {}) => {
    try {
      const { data } = await api.generateReport(type, revisionUid, filters)

      if (data && data?.uid) {
        generatedReport.value = data

        const document = data?.document
        if (document?.url) {
          const fileName = generateReportTemplateDocumentName(type)
          downloadFromDirectLink(document.url.replace(/^http:/, 'https:'), fileName, "docx")
        }
      } else {
        generatedReport.value = {}
      }

      isTriggeredReportGenerator.value = true

      return data
    } catch (error) {
      globalStore.addErrorToastMessage("Report generator has error, please contact administrator")
    }
  }

  const generateReportTemplateDocumentName = (type) => {
    const version = new Date().toISOString()
    return type?.toUpperCase() + `-Summary-Report-` + version
  }

  return {
    generatedReport,
    generateReport,
    isTriggeredReportGenerator,
    generateReportTemplateDocumentName,
  }
}
