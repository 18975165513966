import { ref } from "vue"
import { useDivisionList } from "@/readybc/composables/use/useDivisionList"
import * as api from "@/readybc/composables/api/apiDivision"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"

/**
 *
 * @returns
 */
export const useModalReportSbuSelection = () => {
  const { getAllDivisionList } = useDivisionList()

  const isLoading = ref(false)

  const reportTypes = ref([
    {
      value: "",
      name: "Select Report Type",
    },
    {
      value: "bia",
      name: "BIA Summary",
    },

    {
      value: "bcp",
      name: "BCP Report",
    },
  ])

  // List
  const divisions = ref([])

  const division = ref({})

  const formFilters = ref({
    activityCreatedAtObj: {},
    selectedSBUs: [],
  })

  /**
   * Fetch division list
   */
  const fetchDivisionList = async () => {
    isLoading.value = true

    const { data } = await getAllDivisionList()
    isLoading.value = false

    if (data) {
      divisions.value = data?.map((division) => {
        division.hasChildren = division?.bussiness_unit_cnt > 0
        return division
      })
    }
  }

  /**
   *
   * @param {*} uid
   * @param {*} params
   */
  const fetchDivisionBUs = async (divObj) => {
    const division = divisions.value?.find((div) => {
      return div?.uid === divObj?.uid
    })

    if (division) {
      try {
        const apiCriteria = new ApiCriteria('list_report_business_units')
        apiCriteria.withRelation("business_units")

        const { data } = await api.fetchDivision(division.uid, apiCriteria.toQuery())

        if (data && data?.business_units) {
          division.children = data.business_units
        }

        return true
      } catch (error) {
        return false
      }
    }

    isLoading.value = false
  }

  return {
    divisions,
    division,
    fetchDivisionList,
    fetchDivisionBUs,
    formFilters,
    reportTypes,
    isLoading,
  }
}
