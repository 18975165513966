import { SearchJoinTypeEnum, TypeCompareEnum, SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"
import { useUserFilterPreference } from "@/modules/preference/use/useUserFilterPreference"

/**
 * Pagination
 */
export class Pagination {
  page = 1
  total = 0
  size = 20

  constructor(page = 1, size = 20, total = 0) {
    this.page = page
    this.size = size
    this.total = total
  }
}

/**
 * Search conditions
 */
export class SearchCondition {
  field = null
  value = null
  operator = TypeCompareEnum.LIKE

  constructor(field = null, value = null, operator = TypeCompareEnum.LIKE) {
    this.field = field
    this.value = value
    this.operator = operator
  }
}

/**
 * Sortable
 */
export class Sortable {
  field = null
  direction = SortDirectionEnum.ASC

  constructor(field, direction = SortDirectionEnum.ASC) {
    this.field = field
    this.direction = direction
  }
}

/**
 * Criteria
 */
export class ApiCriteria {
  relations = []
  search = []
  sort = []
  customSort = []
  pagination = new Pagination()
  searchJoin = SearchJoinTypeEnum.AND
  queries = {}
  activeUserPreference = false
  screenCode = null
  userSavedPreference = null

  constructor(preferenceScreen = null) {
    this.setSort("created_at", "DESC")

    if (preferenceScreen) {
      this.setPreferenceScreen(preferenceScreen)
    }
  }

  /**
   * Bind api criteria from save preference or url params
   * @returns {Promise<void>}
   */
  async bindCriteria() {
    const shouldLoadPreference = this.shouldLoadPreferenceScreen()
    if (!shouldLoadPreference) {
      await this.bindURLApiCriteria()
    }

    if (shouldLoadPreference) {
      await this.loadUserPreferenceCriteria()
    }
  }

  /**
   *
   * @param {*} searchJoin
   */
  setSearchJoin(searchJoin) {
    this.searchJoin = searchJoin
  }

  /** ===== PAGINATION === */
  setPage(page) {
    this.pagination.page = page
  }

  setTotalPage(total) {
    this.pagination.total = total
  }

  /** ============================ RELATIONS ============================ */
  withRelation(relation) {
    if (!this.relations?.includes(relation)) {
      this.relations.push(relation)
    }
  }

  /**
   *
   * @param {*} relations
   */
  withRelations(relations) {
    this.relations = relations
  }

  clearRelations() {
    this.relations = []
  }

  /**
   *
   * @param {*} relation
   */
  removeRelation(relation) {
    const idx = this.relations?.findIndex((r) => {
      return (relation = r)
    })
    if (idx >= 0) {
      this.relations?.splice(idx, 1)
    }
  }

  /**
   *
   */
  removeRelations() {
    this.relations = []
  }

  /** ============================ QUERY ============================= */
  setQueries(queries = {}) {
    this.queries = queries
  }

  setQuery(key, value) {
    this.queries[key] = value
  }

  deleteQuery(key) {
    delete this.queries[key]
  }

  /** ============================ SORT ============================== */
  clearSorts() {
    this.sort = []
  }

  /**
   *
   * @param {*} field
   * @param {*} direction
   */
  setSort(field, direction = SortDirectionEnum.ASC, isCustomSort = false, clearOthers = true) {
    if (clearOthers) {
      this.clearSorts()
    }

    if (isCustomSort) {
      this.setCustomSortObj(new Sortable(field, direction))
      return
    }

    this.setSortObj(new Sortable(field, direction))
  }

  /**
   *
   * @param {*} sortObj
   */
  setSortObj(sortObj) {
    const idx = this.sort?.findIndex((sort) => {
      return sort?.field === sortObj?.field
    })

    if (idx >= 0) {
      this.sort[idx] = sortObj
    } else {
      this.sort.push(sortObj)
    }
  }

  /**
   *
   * @param {*} sortObj
   */
  setCustomSortObj(sortObj) {
    const idx = this.customSort?.findIndex((customSort) => {
      return customSort?.field === sortObj?.field
    })

    if (idx >= 0) {
      this.customSort[idx] = sortObj
    } else {
      this.customSort.push(sortObj)
    }
  }

  /** ============================ SEARCH ============================== */

  /**
   *
   * @param field
   * @param value
   * @param operator
   */
  setSearch(field, value, operator) {
    this.setSearchObj(new SearchCondition(field, value, operator))
  }

  /**
   *
   * @param {*} field
   * @param {*} startValue
   * @param {*} toValue
   */
  setSearchBetween(field, startValue, toValue) {
    this.setSearch(field, `${startValue},${toValue}`, TypeCompareEnum.BETWEEN)
  }

  /**
   *
   * @param {*} relation
   * @param field
   * @param {*} value
   * @param {*} operator
   */
  setRelationSearch(relation, field, value, operator) {
    const searchField = `${relation}.${field}`
    this.setSearch(searchField, value, operator)
  }

  /**
   *
   * @param searchObj
   */
  setSearchObj(searchObj) {
    const idx = this.search?.findIndex((s) => {
      return s.field === searchObj?.field && s.operator === searchObj?.operator
    })

    if (idx >= 0) {
      this.search[idx] = searchObj
    } else {
      this.search.push(searchObj)
    }
  }

  /**
   *
   * @param {*} field
   * @param {*} operator
   */
  removeSearch(field, operator = TypeCompareEnum.LIKE) {
    const idx = this.search?.findIndex((s) => {
      return s.field === field && s.operator === operator
    })

    if (idx >= 0) {
      this.search?.splice(idx, 1)
    }
  }

  clearSearch() {
    this.search = []
  }

  /**
   * ====================== PAGINATION ========
   */

  setPageSize(size) {
    this.pagination.size = parseInt(size) || 10
  }

  resetPage() {
    this.pagination.page = 1
  }

  /**
   * ------------
   */

  reset() {
    this.relations = []
    this.search = []
    this.sort = []

    // Always keep the setup size
    const pagination = new Pagination()
    pagination.size = this.pagination.size
    this.pagination = pagination
    this.searchJoin = SearchJoinTypeEnum.AND
  }
  /**
   * ======================= BUILD QUERY =======================
   */

  getRelations() {
    if (this.relations?.length <= 0) {
      return {}
    }
    return {
      with: this.relations?.join(";"),
    }
  }

  /**
   *
   * @returns
   */
  getSearches() {
    const searchValues = []
    const searchConditions = []

    this.search?.forEach((s) => {
      searchValues.push(`${s.field}:${s.value}`)
      searchConditions.push(`${s.field}:${s.operator}`)
    })

    if (searchValues?.length > 0) {
      return {
        search: searchValues?.join(";"),
        searchFields: searchConditions?.join(";"),
      }
    }

    return {}
  }

  /**
   *
   * @returns
   */
  getSorts() {
    const orderBy = []
    const direction = []
    this.sort?.forEach((sort) => {
      orderBy.push(sort.field)
      direction.push(sort.direction)
    })

    if (orderBy?.length > 0) {
      return {
        orderBy: orderBy.join(";"),
        sortedBy: direction?.join(";"),
      }
    }

    return {}
  }

  /**
   *
   * @returns
   */
  getCustomSorts() {
    const orderBy = []
    const direction = []
    this.customSort?.forEach((sort) => {
      orderBy.push(sort.field)
      direction.push(sort.direction)
    })

    if (orderBy?.length > 0) {
      return {
        customSortBy: orderBy.join(";"),
        customSortedBy: direction?.join(";"),
      }
    }

    return {}
  }

  /**
   *
   * @returns
   */
  getPagination() {
    return {
      page: this.pagination.page,
      per_page: this.pagination.size,
    }
  }

  getQueries() {
    return this.queries
  }

  toQuery() {
    const query = {
      ...this.getRelations(),
      ...this.getSearches(),
      ...this.getSorts(),
      ...this.getCustomSorts(),
      ...this.getPagination(),
      ...this.getQueries(),
      searchJoin: this.searchJoin,
    }
    this.applyQueryToRoute(query)
    return query
  }

  getSearchCriteria() {
    return this.search
  }

  /** ============================ Load Criteria ============================== */

  /**
   * Bind query to route
   * @param query
   */
  applyQueryToRoute(query) {
    const url = new URL(window.location)

    Object.keys(query)?.forEach((key) => {
      url.searchParams.set(key, query[key])
    })
    window.history.pushState({}, "", url)
  }

  /**
   * Bind URL to criteria
   */
  bindURLApiCriteria() {
    const queryParams = this.getQueryParams()
    this.loadCriteriaFromURLParams(queryParams)
  }

  /**
   * Get query params
   * @returns
   */
  getQueryParams() {
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    if (params?.size === 0) {
      return null
    }

    const queryParams = {}

    params.forEach((value, key) => {
      queryParams[key] = value
    })

    return queryParams
  }

  /**
   * Load criteria
   * @param params
   * @returns {[]|null}
   */
  loadCriteriaFromURLParams(params) {
    if (!params) {
      return
    }

    const searchJoin = params?.searchJoin ?? this.searchJoin
    const pageSize = params?.per_page ?? 20
    const page = params?.page ?? 1
    this.setSearchJoin(searchJoin)
    this.setPageSize(pageSize)
    this.setPage(page)

    const searchValueParams = params?.search?.split(";").reduce((acc, pair) => {
      const [key, value] = pair.split(":")
      acc[key] = value
      return acc
    }, {})

    const searchOperationParams = params?.searchFields?.split(";").reduce((acc, pair) => {
      const [key, value] = pair.split(":")
      acc[key] = value
      return acc
    }, {})

    if (searchValueParams && searchOperationParams) {
      Object.keys(searchValueParams).forEach((element) => {
        if (
          Object.prototype.hasOwnProperty.call(searchValueParams, element) &&
          Object.prototype.hasOwnProperty.call(searchOperationParams, element)
        ) {
          this.setSearch(element, searchValueParams[element], searchOperationParams[element])
        }
      })
    }

    const sortKey = params?.orderBy ?? null
    const orderType = params?.sortedBy ?? null

    if (sortKey && orderType) {
      this.setSort(sortKey, orderType)
    }
  }

  /** ============================ User Saved Preference ============================== */

  /**
   * Check should load from Preference
   * @returns {null|boolean}
   */
  shouldLoadPreferenceScreen() {
    return this.screenCode && !this.getQueryParams()
  }

  /**
   * Set screen code to fetch preference
   * @param screenCode
   */
  setPreferenceScreen(screenCode) {
    this.activeUserPreference = true
    this.screenCode = screenCode
  }

  /**
   * Load criteria from preference
   * @returns {Promise<void>}
   */
  async loadUserPreferenceCriteria() {
    const { getUserFilterPreference } = useUserFilterPreference()
    this.userSavedPreference = await getUserFilterPreference(this.screenCode)
    if (!this.userSavedPreference) {
      return
    }

    this.applySavedUserSortFilterPreference()
    this.applySavedUserSearchFilterPreference()
  }

  /**
   * Handle save the filter
   */
  async saveCriteriaAsUserPreference() {
    if (!this.screenCode || !this.activeUserPreference) {
      return
    }

    const payload = {}

    // Append sort data
    if (this.sort.length > 0) {
      const sort = this.sort[0]
      payload.sort = {
        key: sort?.field,
        sort_key: sort?.sort_key ?? sort?.field,
        direction: sort?.direction,
      }
    }

    // Save search data
    if (this.search?.length > 0) {
      payload.search = []
      this.search.forEach((search) => {
        payload.search.push({
          field: search.field,
          operator: search.operator,
          value: search.value,
        })
      })
    }

    if (!payload || payload.length === 0) {
      return
    }

    // Add searchJoin condition type
    if (payload?.search?.length > 0) {
      payload.searchJoin = this.searchJoin
    }
    const { saveUserFilterPreference } = useUserFilterPreference()

    await saveUserFilterPreference(this.screenCode, payload)
  }

  /**
   * Handle apply saved preference : sort filter
   */
  applySavedUserSortFilterPreference() {
    const filter = this.userSavedPreference?.preference?.filter_criteria ?? null
    if (!filter) {
      return
    }

    const sort = filter?.sort ?? null
    if (sort && (sort?.key || sort.sort_key)) {
      this.setSort(sort.sort_key || sort.key, sort.direction)
    }
  }

  /**
   * Handle apply saved preference : search filter
   */
  applySavedUserSearchFilterPreference() {
    const filter = this.userSavedPreference?.preference?.filter_criteria ?? null
    if (!filter) {
      return
    }

    const search = filter?.search ?? null

    if (search && search.length > 0) {
      for (let i = 0; i < search.length; i++) {
        const criteria = search[i]
        this.setSearch(criteria.field, criteria.value, criteria.operator)
      }

      if (filter?.searchJoin) {
        this.setSearchJoin(filter?.searchJoin)
      }
    }
  }

  /**
   * Get active sort
   */
  getActiveSort() {
    if (this.sort.length === 0) {
      return [null, null]
    }

    const sort = this.sort[0]
    return [sort.field, sort?.direction]
  }

  getSearchValue(searchKey) {
    const match = this.search?.find((item) => item.field === searchKey)

    if (!match) {
      return null
    }

    return match.value
  }
}

export { TypeCompareEnum }
