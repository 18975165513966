<template>
  <!-- context menu -->
  <EcBox
    v-show="isOpen"
    class="absolute z-10 bg-cWhite shadow-5 rounded w-[15%] p-3"
    :class="[menuPosition]"
    :style="this.class"
    id="bulk-activities-ctx-menu"
    v-click-outside="handleCloseMenuClickOutside"
    ref="menu"
  >
    <EcBox>
      <!-- Copy field value -->
      <EcFlex v-for="(menuItem, idx) in menuItems" :key="idx">
        <EcFlex
          v-if="menuItem.condition"
          :class="[menuItem?.disabled ? 'text-c0-400 cursor-not-allowed ' : ' cursor-pointer ']"
          class="w-full text-sm items-center p-1 hover:bg-c0-100"
          @click="!menuItem?.disabled && handleClickOnMenuItem(menuItem.action)"
        >
          <EcBox class="w-1/12 mr-2">
            <EcIcon
              :icon="menuItem.icon"
              :width="menuItem?.iconSize || '18'"
              :heigth="menuItem?.iconSize || '18'"
              :class="[menuItem.class]"
            />
          </EcBox>
          <EcBox class="w-11/12">
            <EcLabel :class="menuItem?.disabled ? 'cursor-not-allowed ' : 'cursor-pointer'">{{ menuItem.label }}</EcLabel>
          </EcBox>
        </EcFlex>
      </EcFlex>

      <!-- End actions-->
    </EcBox>
  </EcBox>
</template>
<script>
import * as menuAction from "@/modules/activity/use/useBulkActivityContextMenu"
import getNestedObject from "lodash.get"

export default {
  name: "BulkActivityContextMenu",
  emits: ["handleContextMenuEvent"],
  data() {
    const menuItems = [
      {
        action: menuAction.COPY_FIELD,
        label: "Copy field value",
        icon: "PaperClip",
        class: "text-c1-800",
        condition: false,
      },
      { action: menuAction.PASTE_FIELD, label: "Paste field value", icon: "ClipboardCheck", condition: true },
      {
        action: menuAction.COPY_ROW,
        label: "Copy row values",
        icon: "Copy",
        class: "text-c1-800",
        condition: false,
      },
      { action: menuAction.PASTE_ROW, label: "Paste row values", icon: "Clipboard", condition: true },
      {
        action: menuAction.DUPLICATE_ROW,
        label: "Duplicate this row",
        icon: "DocumentDuplicate",
        class: "text-cWarning-600",
        condition: false,
      },
      {
        action: menuAction.PASTE_FROM_EXCEL,
        label: "Paste row values from Excel",
        icon: "OfficeExcel",
        iconSize: "16",
        class: "text-cSuccess-800",
        condition: true,
      },
      {
        action: menuAction.PASTE_FROM_EXCEL,
        label: "Paste mutiple rows from Excel (Add $$ in last column)",
        icon: "OfficeExcel",
        iconSize: "16",
        class: "text-cSuccess-800",
        condition: true,
      },
      { action: menuAction.INSERT_ROW, label: "Insert new row below", icon: "PlusCircle", class: "text-c1-800", condition: true },
      { action: menuAction.REMOVE_ROW, label: "Remove", icon: "MinusCircle", class: "text-cError-500", condition: true },
    ]

    return {
      position: 0,
      isOpen: false,
      isCtxOpenByMouse: false,
      class: "",
      activityRow: null,
      activityIndex: null,
      field: null,
      fieldContext: null,
      menuItems,
    }
  },
  setup() {
    return {}
  },
  computed: {
    menuPosition() {
      return ``
    },
  },

  watch: {
    isCtxOpenByMouse: {
      handler(event) {
        if (event) {
          this.handleContextMenuPosition(event)
          this.handleContextMenuCondition(event)
        }

        setTimeout(() => {
          this.isOpen = !! event;
        }, 100);
      },
    },
  },

  methods: {
    handleCloseMenuClickOutside() {
      if (!this.isOpen) {
        return;
      }

      this.handleCloseMenu();
    },
    /**
     * Close menu
     */
    handleCloseMenu() {
      this.activityIndex = null
      this.activityRow = null
      this.field = null
      this.isOpen = false
      this.isCtxOpenByMouse = false
      this.fieldContext = null
    },

    /**
     * Change menu posistion
     */
    handleContextMenuPosition(event) {
      let clientY = event.clientY
      if (clientY + 260 > window.innerHeight) {
        clientY = clientY - (clientY + 260 - window.innerHeight)
      }
      this.class = `top:${clientY}px; left:${event.clientX}px;`
    },

    /**
     * Change condition
     */
    handleContextMenuCondition(event) {
      this.menuItems?.forEach((item, idx) => {
        // Copy field
        if (item.action === menuAction.COPY_FIELD) {
          item.condition = this.evaluateCopyFieldMenuCondition()
        }

        // Copy row and duplicate row
        if ([menuAction.COPY_ROW, menuAction.DUPLICATE_ROW].includes(item.action)) {
          item.condition = this.activityRow?.name?.length > 0
        }

        // Copy field
        if (item.action === menuAction.PASTE_FROM_EXCEL) {
          // Check permission first
          window.navigator.permissions.query({ name: "clipboard-read" }).then((result) => {
            if (!result || result?.state === "denied") {
              item.disabled = true
            }
          })

          window.navigator?.clipboard?.readText()?.then((result) => {
            item.condition = result?.trim()?.length > 0
          }).catch(error => {
            item.disabled = true
            console.warn("Brower does not support copy from Clipboard", error)
          })
        }
      })
    },

    /**
     * Evaluate copy menu field
     * @returns {*|boolean}
     */
    evaluateCopyFieldMenuCondition() {
      if (this.field?.length === 0) {
        return false
      }

      const object = !this.field?.includes(".") ? this.activityRow[this.field] : getNestedObject(this.activityRow, this.field)

      if (!object) {
        return false
      }

      // Case is array
      if (object instanceof Array && this.fieldContext) {
        const objectCopy = object?.find((item) => item?.uid === this.fieldContext?.uid)
        if (!objectCopy || !objectCopy[this.fieldContext.field] || objectCopy[this.fieldContext.field]?.length === 0) {
          return false
        }
        return true
      }

      // Case normal object
      if (!this.fieldContext) {
        if (object instanceof Object) {
          return true
        }
        return object?.length > 0
      }

      if (this.fieldContext.field?.length > 0) {
        return !!object[this.fieldContext.field]
      }

      return false
    },

    /**
     * Menu item event
     * @param {*} eventName
     */
    handleClickOnMenuItem(eventName) {
      this.$emit("handleContextMenuEvent", eventName, this.activityIndex, this.field, this.fieldContext)
      this.handleCloseMenu()
    },
  },
}
</script>
