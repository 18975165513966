<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("equipment.equipment") }}
        </EcHeadline>

        <!-- Add Equipment -->
        <EcButton
          v-permissions:equipment-create
          class="mb-3 lg:mb-0"
          id="btn-add-equipment"
          iconPrefix="plus-circle"
          variant="primary-sm"
          @click="handleClickAddEquipment"
        >
          {{ $t("equipment.buttons.addEquipment") }}
        </EcButton>
      </EcFlex>
    </EcFlex>

    <!-- Filter-->
    <EcBox class="xl:grid-cols-2 lg:grid-cols-1 grid sm:grid-cols-1 md:grid-cols-1 gap-2 mt-8 lg:mt-16">
      <EcBox class="grid grid-cols-5 lg:mt-2">
        <RDataFilter
          ref="filterBoxRef"
          v-model="apiCriteria"
          :filterOptions="filterOptions"
          @appliedFilters="getEquipmentList(true)"
        ></RDataFilter>
      </EcBox>
      <EcBox class="lg:flex-wrap grid sm:grid-cols-1 md:grid-cols-3 gap-2">
        <!-- Export Equipments -->
        <EcButton
          v-permissions:equipment-download
          class="mb-3 lg:mb-0"
          id="btn-export"
          :iconPrefix="exportEquipmentsIcon"
          variant="primary-sm"
          @click="handleClickDownloadEquipments"
        >
          {{ $t("equipment.buttons.exportEquipment") }}
        </EcButton>

        <!-- View Equipment Category -->
        <EcButton
          v-permissions:equipment_category-read
          class="mb-3 lg:mb-0"
          id="btn-equipment-categories"
          iconPrefix="Resource"
          variant="primary-sm"
          @click="handleClickViewCategories"
        >
          {{ $t("equipment.buttons.viewEquipmentCategories") }}
        </EcButton>

        <!-- View Equipment Owner -->
        <EcButton
          v-permissions:equipment_owner-read
          class="mb-3 lg:mb-0"
          id="btn-equipment-owners"
          iconPrefix="UserGroup"
          variant="primary-sm"
          @click="handleClickViewOwners"
        >
          {{ $t("equipment.buttons.viewEquipmentOwners") }}
        </EcButton>
      </EcBox>
    </EcBox>

    <!-- Table -->

    <EcTable
      ref="dataTableRef"
      name="tbl-equipment-list"
      :isLoading="isLoading"
      :columns="headerData"
      :dataSource="filteredEquipments"
      :actionMenu="true"
      :rowCustomCss="rowCustomeCss"
      :rowClicked="handleClickRowItem"
      :apiCriteria="apiCriteria"
      @sorted="handleColumnSort"
      @onEdit="handleClickEditEquipment"
      @onDelete="handleOpenDeleteModal"
    ></EcTable>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="apiCriteria.pagination.page"
        :limit="apiCriteria.pagination.size"
        :totalCount="apiCriteria.pagination.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="apiCriteria.pagination.page"
        :itemPerPage="apiCriteria.pagination.size"
        :totalItems="apiCriteria.pagination.total"
        @pageSizeChanged="
          (size) => {
            apiCriteria.setPageSize(size)
          }
        "
      />
    </EcFlex>

    <!-- Modal  delete equipment -->
    <teleport to="#layer1">
      <ModalDeleteEquipment
        :equipmentUid="toDeleteEquipmentUid"
        :equipmentName="toDeleteEquipmentName"
        :isModalDeleteEquipmentOpen="isModalDeleteOpen"
        @handleCloseDeleteModal="handleCloseDeleteModal"
        @handleDeleteCallback="handleDeleteCallback"
      />
    </teleport>

    <Teleport to="#layer2">
      <ModalActivityByDependency :selectedDependency="selectedDependency" dependable="equipment"></ModalActivityByDependency>
    </Teleport>
  </RLayout>
</template>

<script>
import { useEquipmentList } from "@/modules/equipment/use/equipment/useEquipmentList"
import { useGlobalStore } from "@/stores/global"
import { formatData, goto } from "@/modules/core/composables"
import { ref } from "vue"
import { useCategoryList } from "../../use/category/useCategoryList"
import { useEquipmentStatusEnum } from "../../use/equipment/useEquipmentStatusEnum"
import ModalDeleteEquipment from "../../components/ModalDeleteEquipment.vue"
import { useI18n } from "vue-i18n"
import ModalActivityByDependency from "@/modules/activity/components/ModalActivityByDependency.vue"

export default {
  name: "ViewEquipmentList",
  setup() {
    // Pre load
    const { getEquipmentCategoryList } = useCategoryList()
    const globalStore = useGlobalStore()
    const { getEquipmentList, handleColumnSort, downloadEquipments, equipmentList, isLoading, apiCriteria } = useEquipmentList()

    const { statuses } = useEquipmentStatusEnum()
    const equipmentCategories = ref([])
    const { t } = useI18n()

    const filterOptions = ref([])
    filterOptions.value = [
      {
        key: "name",
        title: t("equipment.labels.name"),
      },
      {
        key: "category.name",
        title: t("equipment.labels.category"),
        type: "select",
        data: equipmentCategories,
        nameKey: "name",
        valueKey: "value",
      },
      {
        key: "created_at",
        title: t("equipment.labels.createdAt"),
        type: "date",
      },
    ]

    return {
      globalStore,
      getEquipmentList,
      handleColumnSort,
      downloadEquipments,
      isLoading,
      apiCriteria,
      getEquipmentCategoryList,
      equipmentList,
      statuses,
      equipmentCategories,
      filterOptions,
    }
  },
  data() {
    return {
      headerData: [
        {
          key: "name",
          title: this.$t("equipment.labels.name"),
          sortable: true,
        },
        {
          key: "description",
          title: this.$t("equipment.labels.description"),
          sortable: true,
        },
        {
          key: "category.name",
          title: this.$t("equipment.labels.category"),
          sortable: true,
          sortKey: "equipment_categories|equipment_categories.name",
        },
        {
          key: "activities_count",
          title: this.$t("equipment.labels.linked_activities"),
          sortable: true,
          numeric: true,
        },
        {
          key: "shortest_mtpd_name",
          title: this.$t("equipment.labels.shortestMTPD"),
          sortable: true,
          numeric: true,
        },
        {
          key: "created_at",
          title: this.$t("equipment.labels.createdAt"),
          sortable: true,
          date: true,
        },
      ],

      selectedCategory: "",
      searchQuery: "",
      isLoadingCategories: false,
      isDownloading: false,
      isModalDeleteOpen: false,
      // Equipment uid to delete
      toDeleteEquipmentUid: null,
      toDeleteEquipmentName: "",
      selectedDependency: null,
    }
  },
  async mounted() {
    await this.apiCriteria.bindCriteria()
    await this.getEquipmentList(true)

    this.$refs.filterBoxRef?.handleSetActiveConditions(this.apiCriteria.getSearchCriteria())
    this.$refs.dataTableRef?.setColumnActiveSort(...this.apiCriteria.getActiveSort())
    this.fetchEquipmentCategories()
  },
  computed: {
    /**
     * Format date
     */
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },

    /**
     * Export Icone
     */
    exportEquipmentsIcon() {
      return this.isDownloading ? "Spinner" : "DocumentDownload"
    },

    categoryPlaceHolder() {
      return this.isLoadingCategories ? this.$t("equipment.placeholders.loading") : this.$t("equipment.placeholders.category")
    },

    /**
     * Filtered
     */
    filteredEquipments() {
      if (this.selectedCategory.length > 0) {
        return this.equipmentList.filter((equipment) => {
          return equipment?.category?.uid === this.selectedCategory
        })
      }

      return this.equipmentList
    },
  },

  methods: {
    formatData,

    /**
     * convert equipment status to string status
     * @param value
     * @returns {string}
     */
    getEquipmentstatus(value) {
      const status = this.statuses.find((status) => {
        return status.value === value
      })

      return status?.name ?? "Unknown"
    },

    /**
     * get class property
     * @param value
     * @returns {string}
     */
    getEquipmentstatusType(value) {
      switch (value) {
        case 1:
          return "pill-cSuccess-inv"

        case 2:
          return "pill-cWarning-inv"

        default:
          return "pill-cError-inv"
      }
    },

    // Handle events
    /**
     * Download
     */
    async handleClickDownloadEquipments() {
      this.isDownloading = true
      await this.downloadEquipments(this.selectedCategory)
      this.isDownloading = false
    },

    /**
     * View category list
     */
    handleClickViewCategories() {
      goto("ViewCategoryList")
    },

    /**
     * View owner list
     */
    handleClickViewOwners() {
      goto("ViewOwnerList")
    },

    /**
     * Add new activity
     */
    handleClickAddEquipment() {
      goto("ViewEquipmentNew")
    },
    /**
     *
     * @param {*} equipmentUid
     */
    handleClickEditEquipment(equipment) {
      goto("ViewEquipmentDetail", {
        params: {
          uid: equipment.uid,
        },
      })
    },

    /**
     * Open delete equipment modal
     */
    handleOpenDeleteModal(equipment) {
      this.toDeleteEquipmentUid = equipment.uid
      this.toDeleteEquipmentName = equipment.name
      this.isModalDeleteOpen = true
    },

    /**
     * Open delete equipment modal
     */
    handleCloseDeleteModal() {
      this.toDeleteEquipmentUid = null
      this.toDeleteEquipmentName = ""
      this.isModalDeleteOpen = false
    },

    /**
     * Callback after delete
     */
    handleDeleteCallback() {
      this.getEquipmentList(true)
    },

    /**
     *
     * @param {*} equipment
     */
    handleClickRowItem(item) {
      item.time = Date.now()
      this.selectedDependency = item
    },

    /**
     *
     * @param {*} item
     */
    rowCustomeCss(item) {
      return item?.activities_count > 0 ? "text-c1-800 hover:cursor-pointer" : ""
    },

    // ==== PRE-LOAD ==========
    /**
     * Fetch Categories
     */
    async fetchEquipmentCategories() {
      this.isLoadingCategories = true
      const response = await this.getEquipmentCategoryList()
      if (response) {
        this.equipmentCategories = response
      }
      this.isLoadingCategories = false
    },
  },
  components: { ModalDeleteEquipment, ModalActivityByDependency },
}
</script>
