import * as api from "@/readybc/composables/api/apiRoles"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { useGlobalStore } from "@/stores/global"
import { ref } from "vue"

/**
 *
 * @returns
 */
export const useRoleList = () => {
  const apiCriteria = new ApiCriteria('list_role')
  apiCriteria.pagination.size = 1
  const roles = ref([])
  const isGetRoleLoading = ref(false)

  const globalStore = useGlobalStore()

  /**
   *
   * @returns
   */
  async function getRoles() {
    isGetRoleLoading.value = true
    try {
      const { data } = await api.fetchRoles(apiCriteria.toQuery())

      roles.value = data
      isGetRoleLoading.value = false
      return data
    } catch (error) {
      isGetRoleLoading.value = true
      return error
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  async function deleteRole(uid) {
    try {
      const { data } = await api.deleteRole(uid)

      if (!data || data.error) {
        throw new Error(data.error)
      }

      globalStore.addSuccessToastMessage("Deleted role successfully")
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return error
    }
  }

  return {
    getRoles,
    deleteRole,
    apiCriteria,
    roles,
    isGetRoleLoading,
  }
}
