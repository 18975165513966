<template>
  <EcPopover
    title="Filters"
    subTitle="Narrow down your list by applying filters below"
    class="xs:min-w-xs lg:min-w-lg"
    ref="modalFilter"
    @onOpen="handlePopupOpen"
  >
    <!-- Button -->
    <template v-slot:button>
      <EcFlex class="items-center hover:cursor-pointer hover:text-c1-800">
        <EcIcon :class="[appliedCriterias?.length > 0 ? 'text-c1-800' : '']" icon="Filter" width="20" />
        <EcText v-if="appliedCriterias?.length <= 0" class="text-base ml-1">Filter</EcText>
        <EcText v-else class="text-base ml-1 text-c1-800"> {{ appliedCriterias?.length }} filter(s) applied </EcText>
        <!-- <EcText class="text-base ml-1">1 filter(s) applied</EcText> -->
      </EcFlex>
    </template>

    <!-- Popup -->
    <template v-slot:popup-header> </template>
    <template v-slot:popup-content>
      <EcBox class="p-3">
        <!-- Each row -->
        <EcBox
          v-for="(filter, idx) in filterCriterias"
          :key="filter.key"
          :set="(inputDefinition[idx] = getOptionInputDefinition(filter))"
        >
          <EcFlex class="mb-2 items-top">
            <!-- Data point -->
            <EcSelect
              v-model="filter.key"
              :options="santinizedFilterOptions"
              valueKey="key"
              nameKey="title"
              class="relative xs:min-w-24 xs:max-w-32 min-w-48 max-w-48 h-8"
            ></EcSelect>

            <!-- Operators -->
            <EcSelect
              v-model="filter.operator"
              :options="getOptionOperators(filter.key)"
              valueKey="key"
              nameKey="label"
              class="relative ml-3 xs:min-w-24 xs:max-w-32 min-w-48 max-w-48 h-8"
            >
            </EcSelect>

            <!-- Input definition -->
            <EcFlex class="min-w-8 ml-3 max-w-48">
              <!-- Default is text -->

              <!-- Select -->
              <EcSelect
                v-model="filter.value"
                v-if="inputDefinition[idx]?.type === INPUT_TYPE_SELECT"
                :options="inputDefinition[idx]?.data"
                :valueKey="inputDefinition[idx]?.valueKey"
                :nameKey="inputDefinition[idx]?.nameKey"
                class="relative xs:min-w-24 xs:max-w-32 min-w-48 max-w-48 h-8"
              >
              </EcSelect>

              <!-- Data picker -->
              <EcBox
                v-else-if="
                  inputDefinition[idx]?.type === INPUT_TYPE_DATE || inputDefinition[idx]?.type === INPUT_TYPE_DATE_ADVANCED
                "
              >
                <EcDatePicker v-model="filter.value" class="xs:min-w-24 xs:max-w-32 min-w-48 max-w-48"> </EcDatePicker>
                <EcDatePicker
                  v-if="inputDefinition[idx]?.type === INPUT_TYPE_DATE_ADVANCED"
                  v-model="filter.valueEnd"
                  class="xs:min-w-24 xs:max-w-32 min-w-48 max-w-48 mt-3"
                >
                </EcDatePicker>
              </EcBox>

              <EcInputText v-model="filter.value" v-else class="xs:min-w-24 xs:max-w-32 min-w-48 max-w-48"></EcInputText>
            </EcFlex>

            <!-- Delete condition button -->
            <EcText
              class="ml-2 mt-2 hover:cursor-pointer hover:text-c1-800"
              @click="handleDeleteCondition(idx)"
              @click.stop="() => {}"
            >
              Delete
            </EcText>
          </EcFlex>
        </EcBox>
        <!-- End v-for-->

        <!-- End criteria -->
        <EcBox class="mt-1 mb-1 text-cError-600" v-if="v$.$dirty">
          <EcText> Please complete the filter conditions </EcText>
        </EcBox>

        <!-- Add condition -->
        <EcText
          v-if="filterCriterias?.length < filterOptions?.length"
          class="text-c1-800 mb-1 mt-2 hover:cursor-pointer w-32"
          @click="handleAddNewCondition"
          @click.stop="() => {}"
        >
          Add Condition
        </EcText>

        <!-- Action buttons -->
        <EcFlex class="mt-2">
          <EcButton variant="primary-sm" @click="handleApplyFilters">Apply filters</EcButton>
          <EcButton variant="tertiary-sm" class="ml-2" @click="handleClearFilters">Clear</EcButton>
        </EcFlex>
      </EcBox>
    </template>

    <!-- End popup-->
  </EcPopover>
</template>

<script>
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { TypeCompareEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"
import useVuelidate from "@vuelidate/core"
import { required, helpers } from "@vuelidate/validators"
import { ref } from "vue"

export default {
  name: "RDataFilter",

  emits: ["update:modelValue", "appliedFilters"],
  props: {
    modelValue: {
      required: true,
      type: Object,
      default: () => {},
    },

    clearTrigger: {},
    filterOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      apiCriteria: null,
    }
  },
  setup() {
    const appliedCriterias = ref([])
    const filterCriterias = ref([])

    const inputDefinition = ref([])

    const INPUT_TYPE_SELECT = "select"
    const INPUT_TYPE_TEXT = "text"
    const INPUT_TYPE_DATE = "date"
    const INPUT_TYPE_DATE_ADVANCED = "dateAdvanced"

    const rules = {
      filterCriterias: {
        $each: helpers.forEach({
          key: { required },
          operator: { required },
          value: { required },
        }),
      },
    }

    const v$ = useVuelidate(rules, { filterCriterias }, { $autoDirty: false })

    return {
      v$,
      appliedCriterias,
      filterCriterias,
      inputDefinition,

      // Input Type
      INPUT_TYPE_SELECT,
      INPUT_TYPE_DATE,
      INPUT_TYPE_TEXT,
      INPUT_TYPE_DATE_ADVANCED,
    }
  },

  computed: {
    santinizedFilterOptions() {
      return this.filterOptions?.map((option) => {
        option.disabled = this.isOptionAddedToFilterOptions(option)
        return option
      })
    },
  },

  mounted() {
    this.apiCriteria = this.modelValue
    // Check selected
    this.handlePreSelectedCriteria()
  },

  methods: {
    /**
     * Handle add new condition
     */
    handleAddNewCondition() {
      this.filterCriterias.push({
        key: "",
        operator: "",
        value: "",
        applied: false,
      })
    },

    /**
     * Handle set active condition
     * @param key
     * @param value
     * @param operator
     * @param applied
     */
    handleSetActiveCondition(key, value, operator, applied = true) {
      const search = {
        key,
        operator,
        value,
        applied,
      }
      this.filterCriterias.push(search)
      this.appliedCriterias.push(search)
    },

    handleSetActiveConditions(conditions) {
      if (!conditions) {
        return
      }

      for (let i = 0; i < conditions.length; i++) {
        this.handleSetActiveCondition(conditions[i].field, conditions[i].value, conditions[i].operator, false)
      }
    },

    /**
     * Delete condition
     * @param {*} idx
     */
    handleDeleteCondition(idx) {
      this.filterCriterias.splice(idx, 1)
      return true
    },

    /**
     * Apply filter
     */
    handleApplyFilters() {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      // Return results
      this.filterCriterias?.forEach((item) => {
        item.applied = true
      })

      this.appliedCriterias = this.filterCriterias?.map((item) => item)

      // Push filter to api criteria
      if (!(this.apiCriteria instanceof ApiCriteria)) {
        this.apiCriteria = new ApiCriteria("global")
      }

      // Reset criterias
      this.apiCriteria.clearSearch()
      this.apiCriteria.clearSorts()
      this.apiCriteria.resetPage()
      this.appliedCriterias?.forEach((criteria) => {
        if (criteria.operator === TypeCompareEnum.BETWEEN) {
          this.apiCriteria.setSearchBetween(criteria.key, criteria?.value, criteria?.valueEnd)
        } else {
          this.apiCriteria.setSearch(criteria.key, criteria.value, criteria.operator)
        }
      })

      this.$emit("update:modelValue", this.apiCriteria)
      this.$emit("appliedFilters", this.apiCriteria)

      this.apiCriteria.saveCriteriaAsUserPreference()

      this.$refs.modalFilter.isOpen = false
    },

    /**
     * Clear Filters
     */
    handleClearFilters() {
      // Return results
      this.appliedCriterias = []
      this.filterCriterias?.forEach((item) => {
        if (item?.applied) {
          this.appliedCriterias.push(JSON.parse(JSON.stringify(item)))
        }
      })
      this.filterCriterias = []
      this.$emit("update:modelValue", this.apiCriteria)
    },

    /**
     * Handle popup open
     */
    handlePopupOpen() {
      if (this.filterCriterias?.length <= 0 && this.appliedCriterias?.length > 0) {
        this.filterCriterias = this.appliedCriterias?.map((item) => item)
      }

      this.v$.$reset()
    },

    /**
     * Check to see if the option is added to filter options
     * @param {*} key
     */

    isOptionAddedToFilterOptions(option) {
      return this.filterCriterias.find((condition) => condition?.key === option?.key)
    },

    /**
     *
     * @param {*} key
     */
    getOptionOperators(key) {
      const option = this.filterOptions?.find((item) => item?.key === key)

      if (option) {
        // Only EQUAL to select
        if (option?.type === "select" && option?.data?.length > 0) {
          return [
            {
              key: TypeCompareEnum.EQUAL,
              label: "Is",
            },
          ]
        }

        if (option?.type === "date") {
          return [
            {
              key: TypeCompareEnum.LIKE,
              label: "Is",
            },
            {
              key: TypeCompareEnum.GREATER,
              label: "Greater Than",
            },
            {
              key: TypeCompareEnum.GREATER_THAN,
              label: "Greater Or Equal",
            },
            {
              key: TypeCompareEnum.LESS,
              label: "Less Than",
            },
            {
              key: TypeCompareEnum.LESS_THAN,
              label: "Less Than Or Equal",
            },
            {
              key: TypeCompareEnum.BETWEEN,
              label: "Between",
            },
          ]
        }
      }

      return [
        {
          key: TypeCompareEnum.EQUAL,
          label: "Is",
        },
        {
          key: TypeCompareEnum.LIKE,
          label: "Contains",
        },
        {
          key: TypeCompareEnum.GREATER,
          label: "Greater Than",
        },
        {
          key: TypeCompareEnum.GREATER_THAN,
          label: "Greater Or Equal",
        },
        {
          key: TypeCompareEnum.LESS_THAN,
          label: "Less Than",
        },
        {
          key: TypeCompareEnum.LESS_THAN,
          label: "Less Than Or Equal",
        },
      ]
    },

    /**
     *
     * @param {*} key
     */
    getOptionInputDefinition(filter) {
      const option = this.filterOptions?.find((item) => item?.key === filter?.key)

      if (option?.type === this.INPUT_TYPE_SELECT && option?.data?.length > 0) {
        filter = filter ?? {}
        filter.value = filter.value || option?.selected

        return {
          type: this.INPUT_TYPE_SELECT,
          data: option?.data,
          valueKey: option?.valueKey ?? "value",
          nameKey: option?.nameKey ?? "name",
        }
      }

      if (option?.type === this.INPUT_TYPE_DATE) {
        if (filter && filter?.operator === TypeCompareEnum.BETWEEN) {
          return {
            type: this.INPUT_TYPE_DATE_ADVANCED,
          }
        }
        return {
          type: this.INPUT_TYPE_DATE,
        }
      }

      return {
        type: this.INPUT_TYPE_TEXT,
      }
    },

    /**
     * Handle pre-selected criteria
     */
    handlePreSelectedCriteria() {
      this.filterOptions?.forEach((item) => {
        if (item?.selected?.length > 0) {
          this.filterCriterias.push({
            key: item?.key,
            operator: TypeCompareEnum.EQUAL,
            value: item?.selected,
          })
        }
      })

      if (this.filterCriterias?.length > 0) {
        this.handleApplyFilters()
      }
    },
  },
  watch: {
    clearTrigger: {
      handler() {
        this.appliedCriterias = []
        this.filterCriterias = []
      },
      deep: true,
    },
  },
}
</script>
