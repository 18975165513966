import isEmpty from "lodash.isempty"

export function useActivityWithExcelRecord() {
  const cleanName = (name) => {
    if (name === undefined || name === null) {
      return '';
    }

    if (typeof name !== 'string') {
      console.log(name)
      throw new Error('Not a string');
    }

    return name.trim().toLowerCase().replace(/–+/, '-');
  };

  const truthyValues = [
    'y',
    'yes',
    'true',
    '✅',
    '✓',
    '✔',
    '☑',
  ];

  const isYes = (value) => {
    const cleaned = cleanName(value).toLocaleLowerCase();

    return truthyValues.includes(cleaned);
  };

  const explodeInput = (input) => {
    const items = [];

    if (input === undefined || input === null) {
      return items;
    }

    input.split(",").forEach((part) => {
      part.split("\n").forEach((fragment) => {
        fragment = fragment.trim();

        if (fragment.length) {
          items.push(fragment.trim());
        }
      });
    });

    return items;
  }

  /**
   * Read data from clipboard
   *
   * @returns
   */
  const readActivityMultipleRowsFromClipboard = async () => {
    try {
      let excelTextRows = []

      const data = await window.navigator?.clipboard?.readText()

      if (data?.length > 0) {
        excelTextRows = data?.trim()?.split("$$")
      }

      return excelTextRows
    } catch (error) {
      console.warn("Brower does not support copy from Clipboard", error)
    }
  }

  /**
   * Parse data
   * @param {*} excelTextRows
   * @returns
   */
  const parseExcelRows = async () => {
    const parsedActivities = []
    const excelTextRows = await readActivityMultipleRowsFromClipboard()

    excelTextRows?.forEach((excelTextRow) => {
      const parsedRow = parseActivityRow(excelTextRow)

      if (parsedRow) {
        parsedActivities.push(parsedRow)
      }
    })

    return parsedActivities
  }

  /**
   * Parse data
   * @returns
   */
  const parseActivityRow = (excelTextRow) => {
    if (excelTextRow?.length < 0) {
      return
    }

    try {
      // V3 Template
      /* const columnMapping = {
        0: "process_no", // A
        1: "business_unit", // B
        2: "division", // C
        3: "name", // D
        4: "location", // E
        5: "roles", // F
        6: "alternative_roles", // G
        7: "min_people", // H
        8: "is_remote", // I
        9: "remote_access_factors", // J
        10: "on_site_requires", // K
        11: "applications", // L
        12: "is_reliant_server", // M
        13: "equipment", // N
        14: "utilities", // O
        15: "internal_dependencies", // P
        16: "suppliers", // Q
        17: "bcm_assessed", // R
        18: "tolerable_period_disruption", // S
        19: "reason_choose_dependent_time", // T
        20: "recoveryTime", // U
        21: "is_rto_tested", // V
        22: "threat_ranking", // W
        23: "3_workaround", // X
        24: "3_feasible_duration", // Y
        25: "3_feasible_activation", // Z
        26: "1_workaround", // AA
        27: "1_feasible_duration", // AB
        28: "1_feasible_activation", // AC
        29: "5_workaround", // AD
        30: "5_feasible_duration", // AE
        31: "5_feasible_activation", // AF
        32: "4_workaround", // AG
        33: "4_feasible_duration", // AH
        34: "4_feasible_activation", // AI
        35: "2_workaround", // AJ
        36: "2_feasible_duration", // AK
        37: "2_feasible_activation", // AL
      } */

      const columnMapping = {
        0: "process_no",                    // A    Process No
        1: "business_unit",                 // B    Business Unit
        2: "division",                      // C    Business Area
        3: "name",                          // D    Process / Function / Activity
        4: "activity_owner",                // E    Activity Owner (should be a user of readyBC to enable notifications)
        5: "roles",                         // F    Primary personnel for successful delivery of this function? (by role and quantity)
        6: "min_people",                    // G    Minimum personnel to deliver this function? (by role and quantity)
        7: "alternative_roles",             // H    Alternative personnel to support this function? (by role and quantity)
        8: "3_workaround",                  // I    Workaround: Loss of Personnel
        9: "3_feasible_activation",         // J    Feasible Activation
        10: "3_feasible_duration",          // K    Feasible Duration
        11: "location",                     // L    Primary location this function is performed from
        12: "is_remote",                    // M    Can this function be delivered through Remote Access (e.g. WFH)
        13: "remote_access_factors",        // N    What enables remote Access (e.g. Internet, VPN)
        14: "on_site_requires",             // O    If function cannot be delivered remotely - what sites / buildings are required? (e.g. Warehouse, mail centre etc)
        15: "5_workaround",                 // P    Workaround: Denial of Access to Site
        16: "5_feasible_activation",        // Q    Feasible Activation
        17: "5_feasible_duration",          // R    Feasible Duration
        18: "applications",                 // S    Software or Application Needs (e.g. MS Office)
        19: "is_reliant_server",            // T    Are you reliant on the organisation's servers?
        20: "mld_rpo",                      // U    MDL / RPO - Maximum Data Loss or Recovery Point Objective, The maximum amount of data loss that the business can tolerate in terms of time. Typically measured by how far back the business is able to reconstruct data.
        21: "1_workaround",                 // V    Workaround: Loss of IT Systems
        22: "1_feasible_activation",        // W    Feasible Activation
        23: "1_feasible_duration",          // X    Feasible Duration
        24: "equipment",                    // Y    Physical Equipment Needs (e.g. Laptop or PC, Printer)
        25: "utilities",                    // Z    Utilities Required to Support Function (e.g. Power, Internet, Gas)
        26: "4_workaround",                 // AA   Workaround: Loss of Utilities and Equipment
        27: "4_feasible_activation",        // AB   Feasible Activation
        28: "4_feasible_duration",          // AC   Feasible Duration
        29: "internal_dependencies",        // AD   Internal Dependencies outside of your area (e.g. HR, Legal)
        30: "suppliers",                    // AE   Critical Suppliers and/or outsourced arrangements for this Function (e.g. Print Company, Bank etc..)
        31: "bcm_assessed",                 // AF   Has BCM been assessed for these outsourced providers?
        32: "2_workaround",                 // AG   Workaround: Critical Supplier Disruption
        33: "2_feasible_activation",        // AH   Feasible Activation
        34: "2_feasible_duration",          // AI   Feasible Duration
        35: "tolerable_period_disruption",  // AJ   MTPD: How long could the business operate without this function before it becomes a 'moderate' impact?
        36: "reason_choose_dependent_time", // AK   Justification for MTPD
        37: "recoveryTime",                 // AL   RTO - Period of time within which minimum levels of services and/or products and the supporting systems, applications, or functions are to be recovered after a disruption has occurred. Must be less than the MTPD.
        38: "is_rto_tested",                // AM   Has the RTO been tested in real-time?
        39: "threat_ranking",               // AN   Threat Ranking - Continuity Five - Please rank the following in order of highest to lowest threat to each specific business function., 1 = Loss of IT, 2 = Loss of critical supplier, 3 = loss of key personnel, 4 = loss of utilities, 5 = loss of access to site
      }

      const activity = {}

      if (excelTextRow?.length > 0) {
        const parsedValues = excelTextRow?.replace(/^[\t]+/, "")?.split("\t")

        parsedValues.forEach((value, index) => {
          const property = columnMapping[index]

          activity[property] = value?.replace(/[\r\n]+/g, ",")?.replace(/"/g, "")
        })

        return activity
      }
    } catch (e) {
      console.warn("Brower does not support copy from Clipboard", e)
    }
  }

  /**
   * Fill out data
   */
  const fillOutActivityDataFromParsedRow = (activityRow, parsedActivity) => {
    activityRow.name = parsedActivity?.name
    activityRow.is_remote = isYes(parsedActivity?.is_remote);
    activityRow.on_site_requires = parsedActivity?.on_site_requires
    activityRow.reason_choose_dependent_time = parsedActivity?.reason_choose_dependent_time
    activityRow.is_reliant_server = isYes(parsedActivity?.is_reliant_server);

    // MTPD

    // RTO
    activityRow.is_rto_tested = isYes(parsedActivity?.is_rto_tested);
    activityRow.mld_rpo = parsedActivity?.mld_rpo

    // Min people
    fillOutActivityMinPeople(activityRow, parsedActivity)
  }

  /**
   *
   * @param {*} activityRow
   * @param {*} parsedActivity
   */
  const fillOutActivityMinPeople = (activityRow, parsedActivity) => {
    const minPeopleStrs = parsedActivity?.min_people?.replace(/[^0-9,]/g, "")?.split(",")

    let minPeopleNum = 0

    minPeopleStrs?.forEach((minPeopleStr) => {
      if (minPeopleStr?.length > 0) {
        minPeopleNum += Number.parseInt(minPeopleStr?.trim()) || 0
      }
    })

    activityRow.min_people = minPeopleNum
  }

  /**
   *
   * @param {*} activity
   * @param {*} parsedActivity
   * @param {*} existingDivisions
   */
  const excelFillInDivision = (activity, parsedActivity, existingDivisions, createItemCallback, afterResolveCallback) => {

    if (parsedActivity && parsedActivity?.roles?.length > 0) {
      // Handle

      existingDivisions?.forEach((existingDivision) => {
        if (cleanName(existingDivision?.name) === cleanName(parsedActivity?.division)) {
          activity.division = existingDivision
        }
      })
    }

    if (activity.division) {
      afterResolveCallback(activity, parsedActivity);

      return;
    }

    createItemCallback({
      name: parsedActivity.division,
    }).then((division) => {
      activity.division = division;

      afterResolveCallback(activity, parsedActivity);
    });
  }

  /**
   *
   * @param {*} activity
   * @param {*} parsedActivity
   * @param {*} existingBU
   */
  const excelFillInBU = (activity, parsedActivity, existingBUs, createItemCallback) => {
    existingBUs?.forEach((existingBU) => {
      if (cleanName(existingBU?.name) === cleanName(parsedActivity?.business_unit)) {
        activity.business_unit = existingBU
      }
    })

    if (activity.business_unit) {
      return;
    }

    createItemCallback({
      name: parsedActivity.business_unit,
      division: {
        uid: activity.division?.uid,
      },
    }).then((unit) => {
      activity.business_unit = unit;
    });
  }

  /**
   *
   * @param {*} activity
   * @param {*} parsedActivity
   * @param {*} existingBU
   */
  const excelFillInActivityOwner = (activity, parsedActivity, owners) => {
    const cleanedName = cleanName(parsedActivity.activity_owner);

    owners?.forEach((owner) => {
      const name = cleanName(owner.first_name + ' ' + owner.last_name);
      const email = cleanName(owner.email);

      if (name === cleanedName || email === cleanedName) {
        activity.assignee = owner;
      }
    })
  }

  /**
   *
   * @param {*} activity
   * @param {*} parsedActivity
   * @param {*} existingLocations
   */
  const excelFillInLocation = (activity, parsedActivity, existingLocations) => {
    const locations = parsedActivity?.location?.split(",")
    if (isEmpty(locations)) {
      return
    }

    activity.primary_locations = []
    existingLocations?.forEach((existingLocation) => {
      locations?.forEach((parsedLocation) => {
        if (cleanName(existingLocation?.name) === cleanName(parsedLocation)) {
          activity.primary_locations.push(existingLocation)
        }
      })
    })
  }

  /**
   * Fill in roles
   */
  const excelFillInRoles = (activity, parsedActivity, existingRoles, createItemCallback) => {
    if (parsedActivity && parsedActivity?.roles?.length > 0) {
      // Handle
      const rolesStrs = explodeInput(parsedActivity?.roles);
      activity.roles = []

      rolesStrs.forEach((roleName) => {
        const cleanedName = cleanName(roleName);

        let createNew = true;

        existingRoles?.forEach((existingRole) => {
          if (cleanName(existingRole?.name) === cleanedName || cleanName(existingRole?.label) === cleanedName) {
            activity.roles?.push(existingRole)
            createNew = false
          }
        })

        if (!createNew) {
          return
        }

        createItemCallback({
          name: roleName,
        }).then(role => {
          activity.roles?.push(role);
        });
      })
    }
  }

  /**
   * Fill in alternative roles
   */
  const excelFillInAlternativeRoles = (activity, parsedActivity, existingAlternativeRoles, createItemCallback) => {
    if (parsedActivity && parsedActivity?.alternative_roles?.length > 0) {
      // Handle
      const alternativeRolesStrs = explodeInput(parsedActivity?.alternative_roles)
      activity.alternative_roles = []

      alternativeRolesStrs.forEach((roleName) => {
        const cleanedName = cleanName(roleName)
        let createNew = true;

        existingAlternativeRoles?.forEach((existingRole) => {
          if (cleanName(existingRole?.name) === cleanedName || cleanName(existingRole?.label) === cleanedName) {
            activity.alternative_roles?.push(existingRole)
            createNew = false
          }
        })

        if (!createNew) {
          return;
        }

        createItemCallback({
          name: roleName,
        }).then((role) => {
          activity.alternative_roles?.push(role)
        })
      })
    }
  }

  /**
   * Fill in RAFs
   */
  const excelFillInRemoteAccessFactors = (activity, parsedActivity, existingRAFs, createItemCallback) => {
    if (parsedActivity && parsedActivity?.remote_access_factors?.length > 0) {
      // Handle
      const rafStrs = explodeInput(parsedActivity?.remote_access_factors)
      activity.remote_access_factors = []

      rafStrs.forEach((rafName) => {
        const cleanedName = cleanName(rafName)
        let createNew = true;

        existingRAFs?.forEach((existingRAF) => {
          if (cleanName(existingRAF?.name) === cleanedName) {
            activity.remote_access_factors?.push(existingRAF)
            createNew = false
          }
        })

        if (!createNew) {
          return;
        }

        createItemCallback({
          name: rafName,
        }).then((raf) => {
          activity.remote_access_factors?.push(raf);
        });
      })
    }
  }

  /**
   * Fill in Apps
   */
  const excelFillInApps = (activity, parsedActivity, existingApps, createItemCallback) => {
    if (parsedActivity && parsedActivity?.applications?.length > 0) {
      // Handle
      const appStrs = explodeInput(parsedActivity?.applications)
      activity.applications = []

      appStrs.forEach((appName) => {
        const cleanedName = cleanName(appName)
        let createNew = true;

        existingApps?.forEach((existingApp) => {
          if (cleanName(existingApp?.name) === cleanedName) {
            activity.applications?.push(existingApp)
            createNew = false
          }
        })

        if (!createNew) {
          return;
        }

        createItemCallback({
          name: appName,
        }).then((app) => {
          activity.applications?.push(app);
        });
      })
    }
  }

  /**
   * Fill in Apps
   */
  const excelFillUtilities = (activity, parsedActivity, existingUtilities, createItemCallback) => {
    if (parsedActivity && parsedActivity?.utilities?.length > 0) {
      // Handle
      const utilityStrs = explodeInput(parsedActivity?.utilities);
      activity.utilities = []

      utilityStrs.forEach((utilityName) => {
        let createNew = true
        const cleanedName = cleanName(utilityName);

        existingUtilities?.forEach((existingUtility) => {
          if (cleanName(existingUtility?.name) === cleanedName) {
            activity.utilities?.push(existingUtility)
            createNew = false
          }
        })

        if (!createNew) {
          return;
        }

        createItemCallback({
          name: utilityName,
        }).then((utility) => {
          activity.utilities?.push(utility);
        });
      })
    }
  }

  /**
   * Fill in Apps
   */
  const excelFillEquipment = (activity, parsedActivity, existingEquipment, createItemCallback) => {
    if (parsedActivity && parsedActivity?.equipment?.length > 0) {
      // Handle
      const equipmentStrs = explodeInput(parsedActivity?.equipment);
      activity.equipments = []

      equipmentStrs.forEach((equipmentName) => {
        let createNew = true
        const cleanedName = cleanName(equipmentName)

        existingEquipment?.forEach((existingEqm) => {
          if (cleanName(existingEqm?.name) === cleanedName) {
            activity.equipments?.push(existingEqm)
            createNew = false
          }
        })

        if (!createNew) {
          return;
        }

        createItemCallback({
          name: equipmentName,
        }).then((equipment) => {
          activity.equipments?.push(equipment);
        });
      })
    }
  }

  /**
   * Fill in Apps
   */
  const excelFillInternalDependencies = (activity, parsedActivity, internalDependencies) => {
    if (parsedActivity && parsedActivity?.internal_dependencies?.length > 0) {
      // Handle

      activity.internal_dependencies = []

      const dependencyStrs = explodeInput(parsedActivity?.internal_dependencies);

      dependencyStrs.forEach((dependencyName) => {
        const cleanedName = cleanName(dependencyName)

        internalDependencies?.forEach((dependency) => {
          if (cleanName(dependency?.transformName) === cleanedName) {
            activity.internal_dependencies.push(dependency)
          }
        })
      })
    }
  }

  /**
   * Fill in Apps
   */
  const excelFillSuppliers = (activity, parsedActivity, existingSuppliers, createItemCallback) => {
    if (parsedActivity && parsedActivity?.suppliers?.length > 0) {
      // Handle
      const supplierStrs = explodeInput(parsedActivity?.suppliers)
      activity.suppliers = []

      supplierStrs.forEach((supplierName) => {
        let createNew = true
        const cleanedName = cleanName(supplierName);

        existingSuppliers?.forEach((existingSupplier) => {
          if (cleanName(existingSupplier?.name) === cleanedName) {
            const supplier = JSON.parse(JSON.stringify(existingSupplier))
            // Clone for detach reference
            activity.suppliers?.push(supplier)
            createNew = false
          }
        })

        if (!createNew) {
          return;
        }

        createItemCallback({
          name: supplierName,
        }).then((supplier) => {
          activity.suppliers?.push(supplier);
        });
      })
    }
  }

  /**
   * Fill in BCM Assessed
   */
  const excelFillBcmAssessed = (activity, parsedActivity) => {
    if (!parsedActivity || parsedActivity?.suppliers?.length === 0 || parsedActivity?.bcm_assessed.length === 0) {
      return
    }

    const bcmLine = explodeInput(parsedActivity?.bcm_assessed);

    bcmLine.forEach((bcmAssessed, index) => {
      const bcmAssessedData = bcmAssessed?.split("-") ?? null
      let supplierName = bcmAssessedData[0] ?? null
      let assessedMethod = bcmAssessedData[2] ?? null

      if (assessedMethod === null) {
        assessedMethod = supplierName;
        supplierName = activity.suppliers[index]?.name ?? null;
      }


      if (!supplierName || !assessedMethod) {
        return
      }
      const suppliers = activity?.suppliers
      const supplier = suppliers?.find((item) => cleanName(item.name) === cleanName(supplierName)) ?? null

      if (supplier) {
        supplier.assessed_methods = assessedMethod.trim()
      }
    })
  }

  /**
   * Fill in MTPD
   */
  const excelFillMTPD = (activity, parsedActivity, mtpdTimeOptions) => {
    // if no options, exit
    if (!mtpdTimeOptions) {
      return;
    }

    if (parsedActivity && parsedActivity?.tolerable_period_disruption?.length > 0) {
      // try to match by name
      activity.tolerable_period_disruption = null;

      mtpdTimeOptions.forEach((mtpdOption) => {
        if (cleanName(mtpdOption?.name) === cleanName(parsedActivity?.tolerable_period_disruption)) {
          activity.tolerable_period_disruption = mtpdOption?.uid
        }
      })


      // Try match by tier name
      const tiers = {
        1: 24,
        2: 168,
        3: 1680,
        4: 100000,
      };

      const tier = (parsedActivity?.tolerable_period_disruption ?? '').replace(/^tier (\d)\s*.*$/i, '$1');
      const tierHours = tiers[tier] ?? null;

      // If tier matching failed, exit
      if (!tierHours) {
        return;
      }
      if (activity.tolerable_period_disruption === null) {
        const mtpdTimeOptionsSorted = mtpdTimeOptions.sort((a, b) => b.value - a.value);

        mtpdTimeOptionsSorted.forEach((mtpdOption) => {
          if (mtpdOption.value <= tierHours && !activity.tolerable_period_disruption) {
            activity.tolerable_period_disruption = mtpdOption?.uid
          }
        });
      }
    }
  }

  /**
   * Fill in MTPD
   */
  const excelFillRTO = (activity, parsedActivity, rtoTimeOptions) => {
    if (parsedActivity && parsedActivity?.recoveryTime?.length > 0) {
      // Handle

      rtoTimeOptions?.forEach((rtoTimeOption) => {
        if (cleanName(rtoTimeOption.name) === cleanName(parsedActivity.recoveryTime)) {
          activity.recoveryTime = rtoTimeOption?.uid
        }
      })
    }
  }

  /**
   * Fill in Scenarios
   */
  const excelFillInScenarios = (activity, parsedActivity, disruptionScenarios) => {
    if (parsedActivity && parsedActivity?.threat_ranking?.length > 0) {
      // Handle

      const mapScenarios = {
        1: "Loss of IT Systems",
        2: "Critical Supplier Disruption",
        3: "Loss of Personnel",
        4: "Loss of Utilities or Equipment",
        5: "Denial of Access to Site",
      }

      const threatRankingStrs = explodeInput(parsedActivity?.threat_ranking);
      activity.disruption_scenarios = []

      // With give ranking strings
      threatRankingStrs.forEach((threatRankingStr) => {
        const threatRanking = threatRankingStr.replace(/\D/g, "")?.trim()

        // Find the mapped scenarios

        if (mapScenarios[threatRanking]) {
          // Loop through scenarios

          disruptionScenarios?.forEach((disruptionScenario) => {
            // If the mapped scenario and the value from DB is matched
            if (cleanName(mapScenarios[threatRanking]) === cleanName(disruptionScenario?.name)) {
              const scenario = { ...disruptionScenario }

              // Workaround
              const workaround = parsedActivity[threatRanking + "_workaround"]
              const workaroundFeasibleDuration = parsedActivity[threatRanking + "_feasible_duration"]
              const workaroundFeasibleActivation = parsedActivity[threatRanking + "_feasible_activation"]

              if (workaround?.length > 0) {
                // scenario.workaround_option = 1

                // Solution
                scenario.workaround_solution = workaround

                // Duration
                scenario.workaround_feasibly = workaroundFeasibleDuration

                // Activation
                scenario.feasible_activation = workaroundFeasibleActivation
              }

              // Push the disruption scenario
              activity.disruption_scenarios?.push(scenario)
            }
          })
        }
      })
    }
  }

  return {
    parseExcelRows,
    fillOutActivityDataFromParsedRow,
    excelFillInDivision,
    excelFillInBU,
    excelFillInActivityOwner,
    excelFillInLocation,
    excelFillInRoles,
    excelFillInAlternativeRoles,
    excelFillInRemoteAccessFactors,
    excelFillInApps,
    excelFillUtilities,
    excelFillEquipment,
    excelFillInternalDependencies,
    excelFillSuppliers,
    excelFillMTPD,
    excelFillRTO,
    excelFillInScenarios,
    excelFillBcmAssessed,
  }
}
