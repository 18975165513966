<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("resiliencePlanStatus.title") }}
        </EcHeadline>

        <!-- Add  -->
        <EcButton class="mb-3 lg:mb-0" iconPrefix="plus-circle" variant="primary-sm" @click="handleClickAdd">
          {{ $t("resiliencePlanStatus.buttons.addResiliencePlanStatus") }}
        </EcButton>
      </EcFlex>
    </EcFlex>

    <!-- Table -->
    <EcTable
      ref="dataTableRef"
      name="tbl-resilience-plan-status-list"
      :isLoading="isLoading"
      :columns="headerData"
      :dataSource="listData"
      :actionMenu="true"
      :menuItems="menuItems"
      :apiCriteria="apiCriteria"
      @sorted="handleSort"
    >
      <template v-slot:preview="{ item }">
        <EcBadge :class="[item?.bg_color, item?.text_color]" class="rounded-full px-1 py-1 min-w-32">
          {{ item.name }}
        </EcBadge>
      </template>
    </EcTable>

    <!-- Pagination -->
    <EcFlex class="flex-col my-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="apiCriteria.pagination.page"
        :limit="apiCriteria.pagination.size"
        :totalCount="apiCriteria.pagination.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="apiCriteria.pagination.page"
        :itemPerPage="apiCriteria.pagination.size"
        :totalItems="apiCriteria.pagination.total"
        @pageSizeChanged="
          (size) => {
            apiCriteria.pagination.size = parseInt(size)
          }
        "
      />
    </EcFlex>

    <!-- Actions -->
    <EcFlex class="">
      <EcButton variant="tertiary" @click="handleBackToMasterList">
        {{ $t("resiliencePlanStatus.buttons.back") }}
      </EcButton>
    </EcFlex>
    <!-- Modal  delete owner -->
    <teleport to="#layer1">
      <ModalAddResiliencePlanStatus
        :isModalAddNewOpen="isModalAddNewOpen"
        @handleCloseAddNewModal="handleCloseAddNewModal"
        @handleCallbackAddNew="handleCallbackAddNew"
      />
    </teleport>
  </RLayout>
</template>

<script>
import { useResiliencePlanStatusList } from "@/modules/resilience_plan/use/status/useResiliencePlanStatusList"
import { useGlobalStore } from "@/stores/global"
import { formatData, goto } from "@/modules/core/composables"
import ModalAddResiliencePlanStatus from "../../components/status/ModalAddResiliencePlanStatus.vue"

export default {
  name: "ViewBIAStatusList",
  setup() {
    const globalStore = useGlobalStore()
    const { getResiliencePlanStatuses, handleSort, isLoading, t, statuses: listData, apiCriteria } = useResiliencePlanStatusList()

    return {
      globalStore,
      getResiliencePlanStatuses,
      handleSort,
      t,
      listData,
      apiCriteria,
      isLoading,
    }
  },
  data() {
    return {
      headerData: [
        { key: "name", title: this.$t("resiliencePlanStatus.labels.name"), sortable: true },
        { key: "description", title: this.$t("resiliencePlanStatus.labels.description") },
        { key: "name", slotKey: "preview", title: this.$t("resiliencePlanStatus.labels.preview") },
      ],

      menuItems: [
        {
          label: "Edit",
          icon: "edit",
          callback: this.handleClickEdit,
        },
      ],

      searchQuery: "",

      isModalAddNewOpen: false,
    }
  },

  computed: {
    /**
     * Format time
     */
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
  },

  mounted() {
    this.getResiliencePlanStatuses()
  },

  methods: {
    formatData,

    // Handle events

    /**
     * Add new bia
     */
    handleClickAdd() {
      this.isModalAddNewOpen = true
    },

    /**
     * Cancel add BIAStatus
     */
    handleCloseAddNewModal() {
      this.isModalAddNewOpen = false
    },

    /**
     * Create BIAStatus callback
     */
    handleCallbackAddNew() {
      this.getResiliencePlanStatuses()
    },

    /**
     * Back to master list
     */
    handleBackToMasterList() {
      goto("ViewMasterSetting")
    },

    /**
     *
     * @param {*}  BIAStatus Uid
     */
    handleClickEdit(item) {
      goto("ViewResiliencePlanStatusDetail", {
        params: {
          uid: item.id,
        },
      })
    },

    setPage(event) {
      this.currentPage = event.target.value
    },

    // ==== PRE-LOAD ==========
  },
  components: { ModalAddResiliencePlanStatus },
}
</script>
