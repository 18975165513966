<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="items-center flex-wrap">
      <EcBox class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ $t("activity.title.addBulkActivities") }}
        </EcHeadline>
        <EcFlex class="items-center text-sm hover:cursor-pointer" @click="handleShowInstruction">
          <EcLabel class="mr-1 hover:cursor-pointer">
            {{ $t("dependencyScenario.labels.instruction") }}
          </EcLabel>
          <EcIcon icon="QuestionMark" class="text-cSuccess-500" width="14" />
        </EcFlex>
      </EcBox>
    </EcFlex>

    <RLoading v-if="isLoading" />
    <RLoading v-if="isUpdating" />
    <!-- Body -->
    <EcBox class="w-full mt-1 p-4 pb-0 shadow-lg" v-if="!isLoading">
      <EcFlex class="flex-col form-data-grid striped">
        <BulkActivityContextMenu @handleContextMenuEvent="handleContextMenuEvent" ref="bulkCtxMenu" />
        <EcFlex
          class="gap-1 grid-header flex-row bg-cWhite absolute z-1 overflow-hidden"
          ref="dataGridHeader"
          id="dataGridHeader"
        >
          <EcBox
            v-for="(header, index) in gridHeaders"
            :key="index"
            class="break-words flex-none inline-header-item text-ellipsis h-[110px] px-1 overflow-hidden activity-name bg-c3-50 z-2 text-sm select-none relative font-medium"
            :class="[header.class]"
            :style="[header?.style, `width:${this.gridHeaderColumnWidthResize[index]}px;`]"
            :ref="`gridHeaderColumn${index}`"
          >
            {{ $t(header.text, header.textParams ?? null) }}
            <span
              class="resizer inline-block w-[5px] h-[100%] absolute right-0 cursor-col-resize z-1"
              @mousedown="startHeaderColumnDrag(index)"
            />
          </EcBox>
        </EcFlex>

        <EcFlex
          class="gray-scroll gap-1 grid-input-form flex-col mt-[110px] pb-4 overflow-auto max-h-[70vh] relative"
          :class="{ dragging: isDragging }"
          ref="dataGridContent"
          id="dataGridContent"
          @mousedown="startDrag"
          @mousemove="handleDrag"
          @mouseup="endDrag"
          @mouseleave="endDrag"
        >
          <!-- Grid row -->
          <EcFlex
            :class="[`grid-activity-record activity-record-index-${index} flex-nowrap flex-row gap-1`]"
            v-for="(record, index) in activities"
            v-bind:key="index"
            @click="handleClickDataGridRow(index)"
            v-on:click.right="openContextMenu($event, record, index)"
          >
            <!-- 0.A process_no -->
            <EcFlex
              class="flex-none items-center inline-record-item activity-index w-[60px]"
              :style="[`width:${this.gridHeaderColumnWidthResize[0]}px;`]"
              :title="getFixHeaderTooltip(index)"
            >
              <EcIcon
                icon="Activity"
                v-if="record.uid?.length > 0 && activityHasError500.indexOf(index) === -1"
                width="12"
                height="12"
                class="inline-block record-saved rounded text-cSuccess-500 absolute left-1"
              />

              <EcIcon
                icon="Activity"
                v-if="activityHasError500.indexOf(index) !== -1"
                width="12"
                height="12"
                class="inline-block record-saved rounded text-cError-500 absolute left-1 z-2"
              />

              <EcBox class="text-center text-sm w-full">
                <EcLabel class="text-sm w-full text-center" @click="openContextMenu($event, record, index, 'name')">{{
                  (seq = index + 1)
                }}</EcLabel>
              </EcBox>
            </EcFlex>

            <!-- 3.D activity_name -->
            <EcBox
              class="flex-none inline-record-item w-[200px] activity-name"
              :style="[`width:${this.gridHeaderColumnWidthResize[1]}px;`]"
            >
              <RFormInput
                v-model="record.name"
                componentName="EcInputText"
                variant="primary-sm"
                v-on:click.right="openContextMenu($event, record, index, 'name')"
                :tabindex="getTabIndexValue(index, 1)"
                @update:modelValue="cleanBackendValidationErrors(index, 'name')"
              />
              <InputValidateError :errors="getInputValidationError('name', index)" :customRuleMessage="customRuleMessage.name" />
            </EcBox>

            <!-- owner (assignee) -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 assignee"
              :style="[`width:${this.gridHeaderColumnWidthResize[2]}px;`]"
            >
              <RFormInput
                v-model="record.assignee"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :options="filteredActivityOwners"
                :isSingleSelect="true"
                :valueKey="'uid'"
                field="record.assignee"
                :addNewOnNotFound="allowAddNewActivityOwner"
                :closeOnAddNew="true"
                :noDataPlaceholder="
                  allowAddNewActivityOwner ? $t('activity.placeholders.noData') : $t('activity.placeholders.noDataPermission')
                "
                @addNewOptionOnNotFound="handleAddNewOwnerSelectOption"
                v-on:click.right="openContextMenu($event, record, index, 'assignee')"
                :tabindex="getTabIndexValue(index, 2)"
              />
            </EcBox>

            <!-- 2.C division -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 division"
              :style="[`width:${this.gridHeaderColumnWidthResize[3]}px;`]"
            >
              <RFormInput
                v-model="record.division"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :isSingleSelect="true"
                :options="divisions"
                :valueKey="'uid'"
                field="record.division.uid"
                :isLoadingDataNotFound="isLoadingDivisions"
                @update:modelValue="clearBusinessUnit(record)"
                v-on:click.right="openContextMenu($event, record, index, 'division')"
                :tabindex="getTabIndexValue(index, 3)"
              />
            </EcBox>

            <!-- 1.B business_unit -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 business-unit"
              :style="[`width:${this.gridHeaderColumnWidthResize[4]}px;`]"
            >
              <RFormInput
                v-model="record.business_unit"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :isSingleSelect="true"
                :options="filteredBusinessUnits(record)"
                :valueKey="'uid'"
                :isLoadingDataNotFound="isLoadingBusinessUnits"
                field="record.business_unit.uid"
                v-on:click.right="openContextMenu($event, record, index, 'business_unit')"
                :tabindex="getTabIndexValue(index, 4)"
              />
            </EcBox>

            <!-- 4.E roles -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 roles"
              :style="[`width:${this.gridHeaderColumnWidthResize[5]}px;`]"
            >
              <RFormInput
                v-model="record.roles"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :options="filteredRoles(record)"
                :valueKey="'uid'"
                :nameKey="'label'"
                field="record.roles"
                :addNewOnNotFound="true"
                :isLoadingDataNotFound="isLoadingRoles"
                :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'role' })"
                @addNewOptionOnNotFound="handleAddNewRoleSelectOption"
                v-on:click.right="openContextMenu($event, record, index, 'roles')"
                :tabindex="getTabIndexValue(index, 5)"
              />
            </EcBox>

            <!-- 5.F min_people -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 min-people"
              :style="[`width:${this.gridHeaderColumnWidthResize[6]}px;`]"
            >
              <RFormInput
                v-model="record.min_people"
                componentName="EcInputNumberStepper"
                variant="primary-sm"
                :step="1"
                :min="1"
                :max="100"
                field="record.min_people"
                v-on:click.right="openContextMenu($event, record, index, 'min-people')"
                :tabindex="getTabIndexValue(index, 6)"
              />
            </EcBox>

            <!-- 6.G alternative_roles -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 alternative-roles"
              :style="[`width:${this.gridHeaderColumnWidthResize[7]}px;`]"
            >
              <RFormInput
                v-model="record.alternative_roles"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :options="filteredAlternativeRoles(record)"
                :valueKey="'uid'"
                :nameKey="'label'"
                :isLoadingDataNotFound="isLoadingRoles"
                field="record.alternative_roles"
                :addNewOnNotFound="true"
                :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'role' })"
                @addNewOptionOnNotFound="handleAddNewRoleSelectOption"
                v-on:click.right="openContextMenu($event, record, index, 'alternative_roles')"
                :tabindex="getTabIndexValue(index, 7)"
              />
            </EcBox>

            <!-- 7.H 3_workaround  -> 9.J -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 workaround_solution"
              :style="[`width:${this.gridHeaderColumnWidthResize[8]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_LOSS_OF_PERSONNEL))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 8)"
                :value="scenario['workaround_solution'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'workaround_solution',
                    disruptionScenariosMapping[DS_LOSS_OF_PERSONNEL]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_LOSS_OF_PERSONNEL]?.name,
                    uid: disruptionScenariosMapping[DS_LOSS_OF_PERSONNEL]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 feasible_activation"
              :style="[`width:${this.gridHeaderColumnWidthResize[9]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_LOSS_OF_PERSONNEL))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 9)"
                :value="scenario['feasible_activation'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'feasible_activation',
                    disruptionScenariosMapping[DS_LOSS_OF_PERSONNEL]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_LOSS_OF_PERSONNEL]?.name,
                    uid: disruptionScenariosMapping[DS_LOSS_OF_PERSONNEL]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 workaround_feasibly"
              :style="[`width:${this.gridHeaderColumnWidthResize[10]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_LOSS_OF_PERSONNEL))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 10)"
                :value="scenario['workaround_feasibly'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'workaround_feasibly',
                    disruptionScenariosMapping[DS_LOSS_OF_PERSONNEL]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_LOSS_OF_PERSONNEL]?.name,
                    uid: disruptionScenariosMapping[DS_LOSS_OF_PERSONNEL]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <!-- 10.K location -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 primary-location"
              :style="[`width:${this.gridHeaderColumnWidthResize[11]}px;`]"
            >
              <RFormInput
                v-model="record.primary_locations"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :isSingleSelect="false"
                :options="locations"
                :valueKey="'uid'"
                field="record.primary_locations"
                :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'location' })"
                :addNewOnNotFound="true"
                @addNewOptionOnNotFound="handleAddNewLocationSelectOption"
                v-on:click.right="openContextMenu($event, record, index, 'primary_locations')"
                :tabindex="getTabIndexValue(index, 11)"
              />
            </EcBox>

            <!-- 11.L is_remote -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 text-center wfh"
              :style="[`width:${this.gridHeaderColumnWidthResize[12]}px;`]"
            >
              <EcCheckbox
                v-model="record.is_remote"
                class="mt-2"
                :tabindex="getTabIndexValue(index, 12)"
                @update:modelValue="handlesChangeActivityAllowRemote(record)"
              />
            </EcBox>

            <!--  12.M remote_access_factors -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 enable-remote"
              :style="[`width:${this.gridHeaderColumnWidthResize[13]}px;`]"
            >
              <RFormInput
                :notAllowedCursor="!isRemote(record)"
                v-model="record.remote_access_factors"
                :disabled="!isRemote(record)"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :options="filteredRemoteAccessFactors(record)"
                :isLoadingDataNotFound="isLoadingRemoteAccessFactors"
                :valueKey="'uid'"
                field="record.remote_access_factors"
                :addNewOnNotFound="true"
                :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'remote access factor' })"
                @addNewOptionOnNotFound="handleAddNewRemoteAccessSelectOption"
                :tabindex="getTabIndexValue(index, 13)"
              />
            </EcBox>

            <!-- 13.M on_site_requires -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 unable-enable-remote"
              :style="[`width:${this.gridHeaderColumnWidthResize[14]}px;`]"
            >
              <RFormInput
                v-model="record.on_site_requires"
                :notAllowedCursor="isRemote(record)"
                :disabled="isRemote(record) || isOnSiteDisabled(record)"
                componentName="EcInputText"
                variant="primary-sm"
                field="record.unable_enable_remote"
                v-on:click.right="openContextMenu($event, record, index, 'on_site_requires')"
                :tabindex="getTabIndexValue(index, 14)"
              />
            </EcBox>

            <!-- 14.O 5_workaround -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 workaround_solution"
              :style="[`width:${this.gridHeaderColumnWidthResize[15]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_DENIAL_OF_ACCESS_TO_SITE))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 15)"
                :value="scenario['workaround_solution'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'workaround_solution',
                    disruptionScenariosMapping[DS_DENIAL_OF_ACCESS_TO_SITE]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_DENIAL_OF_ACCESS_TO_SITE]?.name,
                    uid: disruptionScenariosMapping[DS_DENIAL_OF_ACCESS_TO_SITE]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 feasible_activation"
              :style="[`width:${this.gridHeaderColumnWidthResize[16]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_DENIAL_OF_ACCESS_TO_SITE))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 16)"
                :value="scenario['feasible_activation'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'feasible_activation',
                    disruptionScenariosMapping[DS_DENIAL_OF_ACCESS_TO_SITE]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_DENIAL_OF_ACCESS_TO_SITE]?.name,
                    uid: disruptionScenariosMapping[DS_DENIAL_OF_ACCESS_TO_SITE]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 workaround_feasibly"
              :style="[`width:${this.gridHeaderColumnWidthResize[17]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_DENIAL_OF_ACCESS_TO_SITE))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 17)"
                :value="scenario['workaround_feasibly'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'workaround_feasibly',
                    disruptionScenariosMapping[DS_DENIAL_OF_ACCESS_TO_SITE]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_DENIAL_OF_ACCESS_TO_SITE]?.name,
                    uid: disruptionScenariosMapping[DS_DENIAL_OF_ACCESS_TO_SITE]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <!-- 17.R applications -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 software"
              :style="[`width:${this.gridHeaderColumnWidthResize[18]}px;`]"
            >
              <RFormInput
                v-model="record.applications"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :options="filteredApplications(record)"
                :valueKey="'uid'"
                field="record.applications"
                :isLoadingDataNotFound="isLoadingSoftwares"
                :addNewOnNotFound="true"
                :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'software' })"
                @addNewOptionOnNotFound="handleAddNewAppSelectOption"
                v-on:click.right="openContextMenu($event, record, index, 'applications')"
                :tabindex="getTabIndexValue(index, 18)"
              />
            </EcBox>

            <!-- 18.S is_reliant_server -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 text-center reliant-on-servers"
              :style="[`width:${this.gridHeaderColumnWidthResize[19]}px;`]"
            >
              <EcCheckbox v-model="record.is_reliant_server" class="mt-2" :tabindex="getTabIndexValue(index, 16)" />
            </EcBox>

            <!-- 19.T mld_rpo -->
            <EcBox
              class="flex-none inline-record-item w-3/12 3xl:w-1/12 mdl_rpo"
              :style="[`width:${this.gridHeaderColumnWidthResize[20]}px;`]"
            >
              <RFormInput
                componentName="EcInputText"
                variant="primary-sm"
                v-model="record.mld_rpo"
                v-on:click.right="openContextMenu($event, record, index, 'mld_rpo')"
                :tabindex="getTabIndexValue(index, 20)"
              />
            </EcBox>

            <!-- 20.U 1_workaround  -> 23.X -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 workaround_solution"
              :style="[`width:${this.gridHeaderColumnWidthResize[21]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_LOSS_OF_IT_SYSTEMS))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 21)"
                :value="scenario['workaround_solution'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'workaround_solution',
                    disruptionScenariosMapping[DS_LOSS_OF_IT_SYSTEMS]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_LOSS_OF_IT_SYSTEMS]?.name,
                    uid: disruptionScenariosMapping[DS_LOSS_OF_IT_SYSTEMS]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 feasible_activation"
              :style="[`width:${this.gridHeaderColumnWidthResize[22]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_LOSS_OF_IT_SYSTEMS))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 22)"
                :value="scenario['feasible_activation'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'feasible_activation',
                    disruptionScenariosMapping[DS_LOSS_OF_IT_SYSTEMS]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_LOSS_OF_IT_SYSTEMS]?.name,
                    uid: disruptionScenariosMapping[DS_LOSS_OF_IT_SYSTEMS]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 workaround_feasibly"
              :style="[`width:${this.gridHeaderColumnWidthResize[23]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_LOSS_OF_IT_SYSTEMS))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 23)"
                :value="scenario['workaround_feasibly'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'workaround_feasibly',
                    disruptionScenariosMapping[DS_LOSS_OF_IT_SYSTEMS]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_LOSS_OF_IT_SYSTEMS]?.name,
                    uid: disruptionScenariosMapping[DS_LOSS_OF_IT_SYSTEMS]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <!-- 23.X equipment -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 equipments"
              :style="[`width:${this.gridHeaderColumnWidthResize[24]}px;`]"
            >
              <RFormInput
                v-model="record.equipments"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :options="filteredEquipments(record)"
                :valueKey="'uid'"
                field="record.equipments"
                :isLoadingDataNotFound="isLoadingEquipments"
                :addNewOnNotFound="true"
                :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'equipment' })"
                @addNewOptionOnNotFound="handleAddNewEquipmentSelectOption"
                v-on:click.right="openContextMenu($event, record, index, 'equipments')"
                :tabindex="getTabIndexValue(index, 24)"
              />
            </EcBox>

            <!-- 24.Y utilities -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 utilities"
              :style="[`width:${this.gridHeaderColumnWidthResize[25]}px;`]"
            >
              <RFormInput
                v-model="record.utilities"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :options="filteredUtilities(record)"
                :valueKey="'uid'"
                :addNewOnNotFound="true"
                :isLoadingDataNotFound="isLoadingUtilities"
                :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'utility' })"
                @addNewOptionOnNotFound="handleAddNewUtilitySelectOption"
                v-on:click.right="openContextMenu($event, record, index, 'utilities')"
                :tabindex="getTabIndexValue(index, 25)"
              />
            </EcBox>

            <!-- 25.Z 4_workaround  -> 27.AB-->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 workaround_solution"
              :style="[`width:${this.gridHeaderColumnWidthResize[16]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_LOSS_OF_UTILITIES_OR_EQUIPMENT))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 26)"
                :value="scenario['workaround_solution'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'workaround_solution',
                    disruptionScenariosMapping[DS_LOSS_OF_UTILITIES_OR_EQUIPMENT]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_LOSS_OF_UTILITIES_OR_EQUIPMENT]?.name,
                    uid: disruptionScenariosMapping[DS_LOSS_OF_UTILITIES_OR_EQUIPMENT]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 feasible_activation"
              :style="[`width:${this.gridHeaderColumnWidthResize[27]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_LOSS_OF_UTILITIES_OR_EQUIPMENT))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 27)"
                :value="scenario['feasible_activation'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'feasible_activation',
                    disruptionScenariosMapping[DS_LOSS_OF_UTILITIES_OR_EQUIPMENT]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_LOSS_OF_UTILITIES_OR_EQUIPMENT]?.name,
                    uid: disruptionScenariosMapping[DS_LOSS_OF_UTILITIES_OR_EQUIPMENT]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 workaround_feasibly"
              :style="[`width:${this.gridHeaderColumnWidthResize[28]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_LOSS_OF_UTILITIES_OR_EQUIPMENT))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 28)"
                :value="scenario['workaround_feasibly'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'workaround_feasibly',
                    disruptionScenariosMapping[DS_LOSS_OF_UTILITIES_OR_EQUIPMENT]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_LOSS_OF_UTILITIES_OR_EQUIPMENT]?.name,
                    uid: disruptionScenariosMapping[DS_LOSS_OF_UTILITIES_OR_EQUIPMENT]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <!-- 28.AC internal_dependencies -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 internal-dependencies"
              :style="[`width:${this.gridHeaderColumnWidthResize[29]}px;`]"
            >
              <RFormInput
                v-model="record.internal_dependencies"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :options="filteredInternalDependencies(record)"
                :valueKey="'uid'"
                :nameKey="`transformName`"
                field="record.internal_dependencies"
                v-on:click.right="openContextMenu($event, record, index, 'internal_dependencies')"
                :tabindex="getTabIndexValue(index, 29)"
              />
            </EcBox>

            <!-- 29.AD suppliers -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 suppliers tag-badge-group"
              :style="[`width:${this.gridHeaderColumnWidthResize[30]}px;`]"
            >
              <RFormInput
                v-model="record.suppliers"
                componentName="EcMultiSelect"
                variant="primary-sm"
                :isOptionsPositionAbsolute="true"
                :options="filteredSuppliers(record)"
                :valueKey="'uid'"
                :isLoadingDataNotFound="isLoadingSuppliers"
                :addNewOnNotFound="true"
                :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'supplier' })"
                @addNewOptionOnNotFound="handleAddNewSupplierSelectOption"
                v-on:click.right="openContextMenu($event, record, index, 'suppliers')"
                :tabindex="getTabIndexValue(index, 30)"
              />
            </EcBox>

            <!-- 30.AE bcm_assessed -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 bcm-assessed"
              :style="[`width:${this.gridHeaderColumnWidthResize[31]}px;`]"
            >
              <EcFlex v-if="record.suppliers?.length > 0" class="flex-col">
                <RFormInput
                  v-for="(supplier, si) in record.suppliers"
                  :key="si"
                  v-model="supplier.assessed_methods"
                  componentName="EcInputText"
                  variant="primary-sm"
                  :placeholder="$t('activity.labels.bcm_accessed_method', { supplierName: supplier.name })"
                  :title="$t('activity.labels.bcm_accessed_method', { supplierName: supplier.name })"
                  v-on:click.right="
                    openContextMenu($event, record, index, 'suppliers', { field: 'assessed_methods', uid: supplier.uid })
                  "
                  :tabindex="getTabIndexValue(index, 31)"
                  class="w-full"
                />
              </EcFlex>
              <EcBox v-else class="w-full text-center font-normal pt-3"> - </EcBox>
            </EcBox>

            <!-- 31.AF 2_workaround  -> 33.AH -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 workaround_solution"
              :style="[`width:${this.gridHeaderColumnWidthResize[32]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_CRITICAL_SUPPLIER_DISRUPTION))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 32)"
                :value="scenario['workaround_solution'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'workaround_solution',
                    disruptionScenariosMapping[DS_CRITICAL_SUPPLIER_DISRUPTION]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_CRITICAL_SUPPLIER_DISRUPTION]?.name,
                    uid: disruptionScenariosMapping[DS_CRITICAL_SUPPLIER_DISRUPTION]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 feasible_activation"
              :style="[`width:${this.gridHeaderColumnWidthResize[33]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_CRITICAL_SUPPLIER_DISRUPTION))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 33)"
                :value="scenario['feasible_activation'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'feasible_activation',
                    disruptionScenariosMapping[DS_CRITICAL_SUPPLIER_DISRUPTION]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_CRITICAL_SUPPLIER_DISRUPTION]?.name,
                    uid: disruptionScenariosMapping[DS_CRITICAL_SUPPLIER_DISRUPTION]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 workaround_feasibly"
              :style="[`width:${this.gridHeaderColumnWidthResize[34]}px;`]"
            >
              <RFormInput
                v-if="(scenario = getScenarioThreatRankingByName(index, DS_CRITICAL_SUPPLIER_DISRUPTION))"
                componentName="EcInputText"
                :tabindex="getTabIndexValue(index, 34)"
                :value="scenario['workaround_feasibly'] ?? ''"
                @input="
                  handleChangeThreatRankingValue(
                    index,
                    'workaround_feasibly',
                    disruptionScenariosMapping[DS_CRITICAL_SUPPLIER_DISRUPTION]?.uid
                  )
                "
                v-on:click.right="
                  openContextMenu($event, record, index, 'disruption_scenarios', {
                    field: disruptionScenariosMapping[DS_CRITICAL_SUPPLIER_DISRUPTION]?.name,
                    uid: disruptionScenariosMapping[DS_CRITICAL_SUPPLIER_DISRUPTION]?.uid,
                  })
                "
              />
              <EcLabel v-else class="text-center pt-3"> -</EcLabel>
            </EcBox>

            <!-- 34.AI tolerable_period_disruption -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 tolerant-time"
              :style="[`width:${this.gridHeaderColumnWidthResize[35]}px;`]"
            >
              <RFormInput
                v-model="record.tolerable_period_disruption"
                componentName="EcSelect"
                variant="primary-sm"
                :options="filteredTimeOptions"
                :allowSelectNothing="false"
                :valueKey="'uid'"
                :tabindex="getTabIndexValue(index, 35)"
                @update:modelValue="handleChangeMTPD(record)"
                v-on:click.right="openContextMenu($event, record, index, 'tolerable_period_disruption')"
              />
              <InputValidateError
                :errors="getInputValidationError('mtpdValue', index)"
                :customRuleMessage="customRuleMessage.mtpdValue"
              />
            </EcBox>

            <!-- 35.AJ reason_choose_dependent_time -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 tolerant-reason"
              :style="[`width:${this.gridHeaderColumnWidthResize[36]}px;`]"
            >
              <RFormInput
                v-model="record.reason_choose_dependent_time"
                :rows="2"
                componentName="EcInputText"
                variant="primary-sm"
                showLabel
                field="record.reason_choose_dependent_time"
                :tabindex="getTabIndexValue(index, 36)"
                v-on:click.right="openContextMenu($event, record, index, 'reason_choose_dependent_time')"
              />
            </EcBox>

            <!-- 36.AK recoveryTime-->
            <EcBox
              class="flex-none inline-record-item w-3/12 3xl:w-2/12 rto-period"
              :style="[`width:${this.gridHeaderColumnWidthResize[38]}px;`]"
            >
              <RFormInput
                v-model="record.recoveryTime"
                componentName="EcSelect"
                variant="primary-sm"
                :options="filteredRecoveryTimeOptions"
                :allowSelectNothing="false"
                :valueKey="'uid'"
                :tabindex="getTabIndexValue(index, 38)"
                @update:modelValue="handleChangeRTO(record)"
                v-on:click.right="openContextMenu($event, record, index, 'recoveryTime')"
              />
              <InputValidateError
                :errors="getInputValidationError('recoveryTimeValue', index)"
                :customRuleMessage="customRuleMessage.recoveryTimeValue"
              />
            </EcBox>

            <!-- Time Of -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 tolerant-time-of"
              :style="[`width:${this.gridHeaderColumnWidthResize[37]}px;`]"
            >
              <RFormInput
                :class="`h-[45px]`"
                v-model="record.dependent_time"
                :rows="2"
                componentName="EcInputText"
                variant="primary-sm"
                showLabel
                field="record.dependent_time"
                :tabindex="getTabIndexValue(index, 37)"
                v-on:click.right="openContextMenu($event, record, index, 'dependent_time')"
              />
            </EcBox>

            <!-- 37.AL is_rto_tested -->
            <EcBox
              class="flex-none inline-record-item w-2/12 3xl:w-1/12 text-center rto-tested-realtime"
              :style="[`width:${this.gridHeaderColumnWidthResize[39]}px;`]"
            >
              <EcCheckbox
                v-model="record.is_rto_tested"
                class="mt-2"
                :tabindex="getTabIndexValue(index, 39)"
                v-on:click.right="openContextMenu($event, record, index, 'is_rto_tested')"
              />
            </EcBox>

            <!-- 38.AM disruption_scenarios -->
            <EcBox
              class="flex-none inline-record-item w-5/12 3xl:w-2/12 rto-scenario"
              :style="[`width:${this.gridHeaderColumnWidthResize[40]}px;`]"
            >
              <EcBox class="relative">
                <RFormInput
                  v-model="record.disruption_scenarios"
                  componentName="EcMultiSelect"
                  variant="primary-sm"
                  :isOptionsPositionAbsolute="true"
                  :options="filteredDisruptionScenarios"
                  :valueKey="'uid'"
                  field="record.disruption_scenarios"
                  :closeOnAddNew="true"
                  class="disruption-scenarios"
                  :tabindex="getTabIndexValue(index, 40)"
                  v-on:click.right="openContextMenu($event, record, index, 'disruption_scenarios')"
                />
              </EcBox>
            </EcBox>
          </EcFlex>
        </EcFlex>

        <EcFlex
          v-show="showGridFixedColumn"
          id="dataGridFixedColumn"
          class="gap-1 grid-input-form flex-col absolute"
          :style="`width: ${gridFixedContentWidth}px;`"
        >
          <EcFlex class="flex-row gap-1 bg-cWhite z-1">
            <EcBox
              class="flex-none inline-header-item text-ellipsis h-[110px] overflow-hidden bg-c3-50 z-2 text-sm w-[60px] px-1 select-none font-medium"
              :style="[`width:${this.gridHeaderColumnWidthResize[0]}px;`]"
            >
              {{ $t("activity.bulkAddActivities.columns.excel_process_no") }}
            </EcBox>
            <EcBox
              class="flex-none w-[200px] inline-header-item text-ellipsis h-[110px] overflow-hidden bg-c3-50 z-2 text-sm px-1 select-none font-medium"
              :style="[`width:${this.gridHeaderColumnWidthResize[1]}px;`]"
            >
              {{ $t("activity.bulkAddActivities.columns.excel_activity") }}
            </EcBox>
          </EcFlex>

          <EcBox
            class="overflow-hidden max-h-[70vh] mt-[-5px] pb-4"
            ref="dataGridFixedColumnContent"
            id="dataGridFixedColumnContent"
          >
            <EcFlex
              :class="[`flex-col grid-activity-record mb-[0.25rem]`, index % 2 > 0 ? 'bg-c0-100' : 'bg-cWhite']"
              v-for="(record, index) in activities"
              v-bind:key="index"
              @click="handleClickDataGridRow(index)"
            >
              <EcFlex class="flex-row inline-record-fixed gap-1">
                <EcFlex
                  class="flex-none items-center inline-record-item activity-index relative"
                  :style="[
                    `width:${this.gridHeaderColumnWidthResize[0]}px;`,
                    `height: ${activityRowsDimension[index]?.height}px;`,
                  ]"
                  :title="getFixHeaderTooltip(index)"
                >
                  <EcIcon
                    icon="Activity"
                    v-if="record.uid?.length > 0 && activityHasError500.indexOf(index) === -1"
                    width="12"
                    height="12"
                    class="inline-block record-saved rounded text-cSuccess-500 absolute left-1"
                  />

                  <EcIcon
                    icon="Activity"
                    v-if="activityHasError500.indexOf(index) !== -1"
                    width="12"
                    height="12"
                    class="inline-block record-saved rounded text-cError-500 absolute left-1 z-2"
                  />
                  <EcBox class="text-center text-sm w-full">
                    <EcLabel class="text-sm w-full text-center">{{ (seq = index + 1) }}</EcLabel>
                  </EcBox>
                </EcFlex>
                <EcBox class="w-[200px] activity-name" :style="[`width:${this.gridHeaderColumnWidthResize[1]}px;`]">
                  <RFormInput
                    v-model="record.name"
                    componentName="EcInputText"
                    variant="primary-sm"
                    v-on:click.right="openContextMenu($event, record, index, 'name')"
                    @update:modelValue="cleanBackendValidationErrors(index, 'name')"
                    :tabindex="getTabIndexValue(index, 1)"
                  />
                  <InputValidateError
                    :errors="getInputValidationError('name', index)"
                    :customRuleMessage="customRuleMessage.name"
                  />
                </EcBox>
              </EcFlex>
            </EcFlex>
          </EcBox>
        </EcFlex>

        <!-- End grid row -->
      </EcFlex>
    </EcBox>

    <EcFlex class="w-full mt-6">
      <EcButton variant="tertiary-outline" @click="handleClickBack">
        {{ $t("activity.buttons.back") }}
      </EcButton>

      <EcButton variant="primary" class="ml-5" @click="handleClickSaveDraft">
        {{ $t("activity.buttons.saveAsDraft") }}
      </EcButton>

      <EcButton variant="primary" class="ml-5" @click="handleClickSaveDraftExit">
        {{ $t("activity.buttons.saveAsDraftExit") }}
      </EcButton>

      <EcButton variant="primary" class="ml-5" @click="handleClickFinish">
        {{ $t("activity.buttons.finish") }}
      </EcButton>

      <EcButton variant="warning" v-if="bulkDraftUid?.length > 0" class="ml-5" @click="handleClickDelete">
        {{ $t("activity.buttons.delete") }}
      </EcButton>

      <!-- Input more row -->
      <EcFlex class="absolute right-6">
        <EcInputText
          v-model="newRowCnt"
          class="mr-1 h-6 bg-cWhite border-1 border-c1-500 text-center"
          style="width: 60px"
          @keyup="handleInputNewRowNumber"
          @keydown.enter="handlerInputNewRows"
        ></EcInputText>

        <EcButton variant="wrapper" class="bg-c1-800 transparent-rounded rounded px-1 py-0" @click="handlerInputNewRows">
          <EcIcon icon="Plus" width="22" height="20" class="text-cWhite" />
        </EcButton>
      </EcFlex>
    </EcFlex>
    <!-- End body -->

    <!-- Modals -->
    <!-- Modals -->
    <teleport to="#layer1">
      <ModalBulkActivityInstruction
        id="modal-bulk-activity-instructions"
        :isShowInstructionModal="isShowInstructionModal"
        @handleCloseBulkActivityInstructionModal="handleCloseBulkActivityInstructionModal"
      />
    </teleport>

    <teleport to="#layer2">
      <ModalDeleteDraftBulkActivities
        id="modal-delete-draft-bulk-activities"
        :bulkDraftUid="bulkDraftUid"
        :isModalDeleteDraftOpen="isModalDeleteDraftOpen"
        @handleCloseDeleteDraftlModal="handleCloseDeleteDraftlModal"
      />
    </teleport>

    <teleport to="#layer3">
      <ModalBulkActivityAddNewOwner
        id="modalAddNewOwner"
        :activityOwner="tempActivityOwner"
        :isModalAddNewOwnerOpen="isModalAddNewOwnerOpen"
        @handleCloseAddNewOwnerModal="handleCloseAddNewOwnerModal"
        @handleCallbackAddNewOwner="handleCallbackAddNewOwner"
      />
    </teleport>
  </RLayout>
</template>

<script>
import { goto } from "@/modules/core/composables"
import { useGlobalStore } from "@/stores/global"
import { useCreateBulkActivities } from "@/modules/activity/use/useCreateBulkActivity"
import { useDivisionList } from "@/modules/activity/use/useDivisionList"
import { useBusinessUnitList } from "@/modules/activity/use/useBusinessUnitList"
import { useActivityOwnerList } from "@/modules/user/use/activityOwners/useActivityOwnerList"
import { useRoleList } from "@/modules/activity/use/role/useRoleList"
import { useUtilities } from "@/modules/activity/use/useUtilities"
import { useRemoteAccessFactors } from "@/modules/activity/use/useRemoteAccessFactors"
import { useApplications } from "../use/useApplications"
import { useEquipmentList } from "@/modules/equipment/use/equipment/useEquipmentList"
import { useActivityTolerant } from "@/modules/activity/use/useActivityTolerant"
import { useActivityRTO } from "@/modules/activity/use/useActivityRTO"
import { useSupplier } from "@/modules/activity/use/useSupplier"
import ModalBulkActivityAddNewOwner from "@/modules/activity/components/ModalBulkActivityAddNewOwner.vue"
import { ref } from "vue"
import { activityMasterDataTypeEnum } from "@/modules/activity/enum/activityMasterDataTypeEnum"
import isEmpty from "lodash.isempty"
import EcBox from "@/components/EcBox/index.vue"
import BulkActivityContextMenu from "../components/BulkActivityContextMenu.vue"
import * as menuAction from "@/modules/activity/use/useBulkActivityContextMenu"
import { useActivityDetail } from "../use/useActivityDetail"
import { useLocationList } from "@/modules/setting/use/master/locations/useLocationList"
import { useActivityWithExcelRecord } from "../use/useActivityWithExcelRecord"
import ModalDeleteDraftBulkActivities from "../components/ModalDeleteDraftBulkActivities.vue"
import ModalBulkActivityInstruction from "../components/ModalBulkActivityInstruction.vue"
import throttle from "lodash.throttle"
import getNestedObject from "lodash.get"
import InputValidateError from "@/modules/activity/components/InputValidateError.vue"

export default {
  name: "ViewActivityBulkCreate",
  components: {
    InputValidateError,
    EcBox,
    BulkActivityContextMenu,
    ModalBulkActivityAddNewOwner,
    ModalDeleteDraftBulkActivities,
    ModalBulkActivityInstruction,
  },
  data() {
    const gridHeaders = [
      // 0.A process_no
      {
        class: "activity-index grid-column-fixed",
        text: "activity.bulkAddActivities.columns.excel_process_no",
        style: "width:60px",
      },
      // 3.D activity_name
      { class: "activity-name grid-column-fixed w-[200px]", text: "activity.bulkAddActivities.columns.excel_activity" },
      // owner
      { class: "activity-assignee w-2/12 3xl:w-1/12", text: "activity.assignee.label" },
      // 2.C division
      { class: "activity-business-unit w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_business_area" },
      // 1.B business_unit
      { class: "activity-division w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_business_unit" },
      // 4.E roles
      { class: "activity-roles w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_who_required" },
      // 5.F min_people
      { class: "activity-min-people w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_minimum_people_support" },
      // 6.G alternative_roles
      {
        class: "activity-alternative-roles w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_who_required_alternate",
      },
      // 7.H 3_workaround
      {
        class: "activity-workaround w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround",
        textParams: { disruptionName: "Loss of Personnel" },
        name: "workaround_solution",
      },
      // 8.I 3_feasible_activation
      {
        class: "activity-workaround-feasible-activation w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround_feasible_activation",
        name: "feasible_activation",
      },
      // 9.I 3_feasible_duration
      {
        class: "activity-workaround-feasible-duration w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround_feasible_duration",
        name: "workaround_feasibly",
      },
      // 10.K location
      { class: "activity-primary-location w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_primary_location" },
      // 11.L is_remote
      { class: "activity-wfh w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_can_remote_access" },
      // 12.M remote_access_factors
      { class: "activity-enable-remote w-2/12 3xl:w-1/12", text: "activity.labels.enableRemote" },
      // 13.N on_site_requires
      {
        class: "activity-unable-enable-remote w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_what_sites_building_required",
      },
      // 14.O 5_workaround
      {
        class: "activity-workaround w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround",
        textParams: { disruptionName: "Denial of Access to Site" },
      },
      // 15.I 5_feasible_activation
      {
        class: "activity-workaround-feasible-activation w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround_feasible_activation",
      },
      // 16.Q 5_feasible_duration
      {
        class: "activity-workaround-feasible-duration w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround_feasible_duration",
      },
      // 17.R applications
      { class: "activity-software w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_software_needed" },
      // 18.S is_reliant_server
      {
        class: "activity-reliant-on-servers w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_reliant_on_the_organisation_server",
      },
      // 19.T mdl_rpo
      {
        class: "activity-reliant-on-servers w-3/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_mdl_rpo",
      },
      // 20.U 1_workaround
      {
        class: "activity-workaround w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround",
        textParams: { disruptionName: "Loss of IT Systems" },
        name: "workaround_solution",
      },
      // 21.V 1_feasible_activation
      {
        class: "activity-workaround-feasible-activation w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround_feasible_activation",
        name: "feasible_activation",
      },
      // 22.W 1_feasible_duration
      {
        class: "activity-workaround-feasible-duration w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround_feasible_duration",
        name: "workaround_feasibly",
      },
      // 23.X equipment
      { class: "activity-equipments w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_physic_equipment" },
      // 24.Y utilities
      { class: "activity-utilities w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_utilities" },
      // 25.Z 4_workaround
      {
        class: "activity-workaround w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround",
        textParams: { disruptionName: "Loss of Utilities and Equipment" },
      },
      // 26.AA 4_feasible_activation
      {
        class: "activity-workaround-feasible-activation w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround_feasible_activation",
      },
      // 27.AB 4_feasible_duration
      {
        class: "activity-workaround-feasible-duration w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround_feasible_duration",
      },
      // 28.AC internal_dependencies
      {
        class: "activity-internal-dependencies w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_internal_dependencies",
      },
      // 29.AD suppliers
      { class: "activity-suppliers w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_critical_suppliers" },
      // 30.AE bcm_assessed
      {
        class: "activity-bcm-assessed w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_BCM_access_these_outsource",
      },
      // // 31.AF 2_workaround
      {
        class: "activity-workaround w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround",
        textParams: { disruptionName: "Loss of Critical Supplier Disruption" },
      },
      // 32.AG 2_feasible_activation
      {
        class: "activity-workaround-feasible-activation w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround_feasible_activation",
      },
      // 33.AH 42_feasible_duration
      {
        class: "activity-workaround-feasible-duration w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_workaround_feasible_duration",
      },
      // 34.AI tolerable_period_disruption
      { class: "activity-tolerant-time w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_time_MTPD" },
      // 35.AJ reason_choose_dependent_time
      {
        class: "activity-tolerant-reason w-2/12 3xl:w-1/12",
        text: "activity.bulkAddActivities.columns.excel_justification_for_MTPD",
      },
      // 36.AK recoveryTime
      { class: "activity-rto-period w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_RTO_minimum" },
      // Time Of
      { class: "activity-tolerant-time-of w-2/12 3xl:w-1/12", text: "activity.tolerant.timeOf" },
      // 37.AL is_rto_tested
      { class: "activity-rto-tested-realtime w-2/12 3xl:w-1/12", text: "activity.bulkAddActivities.columns.excel_RTO_realtime" },
      // 38.AM threat_ranking
      {
        class: "activity-rto-scenario w-5/12 3xl:w-2/12",
        text: "activity.bulkAddActivities.columns.excel_scenario_threat_ranking",
      },
    ]
    return {
      gridHeaders,
      threatRankingHeaders: [],
      isLoading: false,
      isUpdating: false,
      isLoadingActivityOwners: false,
      isLoadingSuppliers: false,
      isLoadingRoles: false,
      isLoadingRemoteAccessFactors: false,
      isLoadingUtilities: false,
      isLoadingDivisions: false,
      isLoadingBusinessUnits: false,
      isPersistingActivityData: false,
      isLoadingEquipments: false,
      isLoadingSoftwares: false,
      isLoadingInternalDependencies: false,
      isLoadingLocations: false,
      roles: [],
      remoteAccessFactors: [],
      utilities: [],
      applications: [],
      equipments: [],
      suppliers: [],
      divisions: [],
      businessUnits: [],
      internalDependencies: [],
      locations: [],
      numOfRecord: 10,
      numOfGridColumns: 40,
      currentDraftIndex: 0,
      previousDraftIndex: 0,
      bulkDraftUid: "",
      tempActivityOwner: ref({}),
      currentActivityOwnerIndex: 0,
      isModalAddNewOwnerOpen: false,
      isModalDeleteDraftOpen: false,
      filteredActivityOwners: ref([]),
      isOpenContextMenu: false,
      copiedIndex: null,
      copiedFieldName: null,
      copiedFieldWithContextValue: null,
      newRowCnt: 0,
      divisionUid: null,
      businessUnitUid: null,
      editMode: false,
      activityRowsDimension: [],
      gridContentScrollPosition: { x: 0, y: 0 },
      isSyncScrolling: false,
      originActivities: [],
      isAllowHeaderResize: false,
      isGridSizeInitialized: false,
      currentIndexColumnResize: null,
      gridHeaderColumnWidthResize: [],
      gridHeaderColumnWidthOrigin: [],
      isAllowAppendForThreatRankingColumn: false,
      appendThreatRankingData: [],
      disruptionScenariosMapping: [],

      // Drag div
      isDragging: false,
      startX: 0,
      startY: 0,
      scrollLeft: 0,
      scrollTop: 0,

      // Instruction
      isShowInstructionModal: false,
    }
  },
  mounted() {
    this.loadAll()
  },
  setup() {
    const globalStore = useGlobalStore()

    const { cancelActivity } = useActivityDetail()
    const {
      parseExcelRows,
      fillOutActivityDataFromParsedRow,
      excelFillInDivision,
      excelFillInBU,
      excelFillInActivityOwner,
      excelFillInLocation,
      excelFillInRoles,
      excelFillInAlternativeRoles,
      excelFillInRemoteAccessFactors,
      excelFillInApps,
      excelFillUtilities,
      excelFillEquipment,
      excelFillInternalDependencies,
      excelFillSuppliers,
      excelFillMTPD,
      excelFillRTO,
      excelFillInScenarios,
      excelFillBcmAssessed,
    } = useActivityWithExcelRecord()

    // Pre-loaded
    const {
      activities,
      customRuleMessage,
      v$,
      createNewDraftBulkActivity,
      persistBulkActivitiesData,
      getBulkDraftDetail,
      createActivityMasterData,
      deleteBulkActivitiesDraft,
    } = useCreateBulkActivities()

    const { getApplicationList } = useApplications()
    const { getAllEquipments } = useEquipmentList()
    const { getDivisionList } = useDivisionList()
    const { getBusinessUnitList } = useBusinessUnitList()
    const { getAllActivityOwners } = useActivityOwnerList()
    const { getAllActivityRoleList } = useRoleList()
    const { getUtilitiesList } = useUtilities()
    const { getSupplierList } = useSupplier()
    const { getRemoteAccessFactorList } = useRemoteAccessFactors()
    const { mtdpTimeOptions, getMTDPTimeOptions } = useActivityTolerant()
    const { getAllLocations } = useLocationList()

    const {
      EW_NONE_IDENTIFIED,
      EW_FREE_TEXT,
      existingWorkarounds,
      recoveryTimeOptions,
      disruptionScenarios,
      getRecoveryTimeOptions,
      getDisruptionScenarios,
    } = useActivityRTO()
    const DS_LOSS_OF_IT_SYSTEMS = "Loss of IT Systems"
    const DS_CRITICAL_SUPPLIER_DISRUPTION = "Critical Supplier Disruption"
    const DS_LOSS_OF_PERSONNEL = "Loss of Personnel"
    const DS_LOSS_OF_UTILITIES_OR_EQUIPMENT = "Loss of Utilities or Equipment"
    const DS_DENIAL_OF_ACCESS_TO_SITE = "Denial of Access to Site"
    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
    const backendValidationErrors = ref([])
    const activityHasError500 = ref([])

    return {
      EW_NONE_IDENTIFIED,
      EW_FREE_TEXT,
      activities,
      v$,
      globalStore,
      customRuleMessage,
      backendValidationErrors,
      activityHasError500,
      getAllActivityOwners,
      getAllActivityRoleList,
      getUtilitiesList,
      getDivisionList,
      getSupplierList,
      getBusinessUnitList,
      getRemoteAccessFactorList,
      mtdpTimeOptions,
      existingWorkarounds,
      recoveryTimeOptions,
      disruptionScenarios,
      getApplicationList,
      getAllEquipments,
      getRecoveryTimeOptions,
      getDisruptionScenarios,
      getMTDPTimeOptions,
      persistBulkActivitiesData,
      createNewDraftBulkActivity,
      getBulkDraftDetail,
      createActivityMasterData,
      activityMasterDataTypeEnum,
      deleteBulkActivitiesDraft,
      cancelActivity,
      parseExcelRows,
      fillOutActivityDataFromParsedRow,
      getAllLocations,
      sleep,

      // Excel fill in data
      excelFillInDivision,
      excelFillInBU,
      excelFillInActivityOwner,
      excelFillInLocation,
      excelFillInRoles,
      excelFillInAlternativeRoles,
      excelFillInRemoteAccessFactors,
      excelFillInApps,
      excelFillUtilities,
      excelFillEquipment,
      excelFillInternalDependencies,
      excelFillSuppliers,
      excelFillMTPD,
      excelFillRTO,
      excelFillInScenarios,
      excelFillBcmAssessed,

      // Disruption Scenarios
      DS_LOSS_OF_IT_SYSTEMS,
      DS_CRITICAL_SUPPLIER_DISRUPTION,
      DS_LOSS_OF_PERSONNEL,
      DS_LOSS_OF_UTILITIES_OR_EQUIPMENT,
      DS_DENIAL_OF_ACCESS_TO_SITE,
    }
  },
  computed: {
    filteredTimeOptions() {
      return this.mtdpTimeOptions
    },

    filteredRecoveryTimeOptions() {
      return this.recoveryTimeOptions
    },

    filteredExistingWorkarounds() {
      return this.existingWorkarounds
    },

    filteredDisruptionScenarios() {
      return this.disruptionScenarios
    },
    allowAddNewActivityOwner() {
      return this.$permissions(`user.create`)
    },
    prefillMode() {
      return !this.editMode && (this.businessUnitUid?.length > 0 || this.divisionUid?.length > 0)
    },
    numberOfRecordActivities() {
      return this.activities.length ?? 0
    },
    gridFixedContentWidth() {
      if (this.numberOfRecordActivities === 0) return 0
      return this.gridHeaderColumnWidthResize[0]
    },
    showGridFixedColumn() {
      return this.gridContentScrollPosition?.x > 5
    },
  },
  watch: {
    numberOfRecordActivities(value) {
      if (!value || value === 0) {
        return
      }

      this.$nextTick(() => {
        if (!this.isSyncScrolling) {
          this.syncScrolling()
        }

        // Save grid origin width
        if (!this.isGridSizeInitialized) {
          for (let i = 0; i < this.numOfGridColumns + 1; i++) {
            const column = this.$refs["gridHeaderColumn" + i][0]?.$el ?? null
            this.gridHeaderColumnWidthOrigin[i] = column.offsetWidth
            this.gridHeaderColumnWidthResize[i] = column.offsetWidth
          }
          this.isGridSizeInitialized = true
        }

        const gridRowResizeObserver = new ResizeObserver(() => {
          window.requestAnimationFrame(() => {
            this.syncActivityRowHeight()
          })
        })

        const rows = document.getElementsByClassName("grid-activity-record")
        Array.prototype.forEach.call(rows, function (el) {
          gridRowResizeObserver.observe(el)
        })
      })
    },
  },
  methods: {
    loadAll() {
      const { uid } = this.$route.params
      this.bulkDraftUid = uid ?? ""
      if (this.bulkDraftUid?.length > 0) {
        this.editMode = true
      }
      this.isLoading = true
      this.fetchDisruptionScenarios()
      this.fetchActivityOwners()
      this.fetchRoles()
      this.fetchSuppliers()
      this.fetchUtilities()
      this.fetchDivisions()
      this.fetchBusinessUnits()
      this.fetchRemoteAccessFactors()
      this.fetchRecoveryTimeOptions()
      this.fetchMTDPTimeOptions()
      this.fetchApplications()
      this.fetchEquipments()
      this.fetchInternalDependencies()
      this.fetchLocations()

      if (this.bulkDraftUid?.length > 0) {
        this.fetchBulkDraftDetail()
      }

      this.divisionUid = this.$route.query.division ?? null
      this.businessUnitUid = this.$route.query.businessUnit ?? null

      if (this.activities?.length === 0) {
        this.addActivityRows(this.numOfRecord)
        this.syncActivityOriginState()
      }

      this.syncActivityRowWidth()
      this.listenToPaste()

      this.isLoading = false
    },
    listenToPaste() {
      const component = this

      document.addEventListener("paste", function (event) {
        component.ctxPasteFromExcel(0)
      })
    },
    /**
     * Sync grid height to fixed columns
     */
    syncActivityRowHeight() {
      for (let i = 0; i < this.activities.length; i++) {
        const row = document.querySelector(`.activity-record-index-${i}`)
        this.activityRowsDimension[i] = { height: row?.offsetHeight ?? 0, width: row?.offsetWidth ?? 0 }
      }
    },

    /**
     * Sync grid activity width to each grid part by adding resize observer
     */
    async syncActivityRowWidth() {
      const dataGridHeader = this.$refs.dataGridHeader.$el
      const dataGridContent = this.$refs.dataGridContent.$el
      dataGridHeader.style.width = `calc(${dataGridContent?.clientWidth}px)`

      try {
        const resizeObserver = new ResizeObserver(() => {
          window.requestAnimationFrame(() => {
            dataGridHeader.style.width = `calc(${dataGridContent?.clientWidth}px)`
          })
        })

        resizeObserver.observe(dataGridContent)
      } catch (e) {
        console.log("Unexpected error", e)
      }

      if (!this.isAllowAppendForThreatRankingColumn) {
        return
      }

      const offset = this.gridHeaders.length // - (this.threatRankingHeaders?.length ?? 0)
      if (offset === 0) {
        return
      }

      await this.sleep(1)

      // Add resizer for threat ranking columns
      for (let i = offset; i < this.gridHeaders.length; i++) {
        const column = this.$refs["gridHeaderColumn" + i][0]?.$el ?? null
        this.gridHeaderColumnWidthOrigin[i] = column.offsetWidth
        this.gridHeaderColumnWidthResize[i] = column.offsetWidth
      }
    },

    /**
     * Sync grid scroll
     */
    syncScrolling() {
      const dataGridContent = this.$refs.dataGridContent.$el
      const dataGridHeader = this.$refs.dataGridHeader.$el
      const dataGridFixedColumnContent = this.$refs.dataGridFixedColumnContent.$el
      dataGridContent.addEventListener("scroll", () => {
        this.gridContentScrollPosition.x = dataGridContent.scrollLeft
        this.gridContentScrollPosition.y = dataGridContent.scrollTop
        dataGridHeader.scrollLeft = this.gridContentScrollPosition?.x
        dataGridFixedColumnContent.scrollTop = this.gridContentScrollPosition?.y
      })
      this.isSyncScrolling = true
    },

    /**
     * Get tab index for input
     * @param rowIndex
     * @param columnIndex
     * @returns {*}
     */
    getTabIndexValue(rowIndex, columnIndex) {
      return rowIndex * this.numOfGridColumns + columnIndex
    },

    /**
     * Check is activity has invalid data
     * @param index
     * @returns {*|boolean}
     */
    isActivityDataInvalid(index) {
      return (
        this.v$.activities?.$each?.$response?.$errors[index]?.name?.length > 0 ||
        this.v$.activities?.$each?.$response?.$errors[index]?.recoveryTimeValue?.length > 0 ||
        this.v$.activities?.$each?.$response?.$errors[index]?.mtpdValue?.length > 0
      )
    },

    /**
     * Applications
     */
    async fetchSuppliers() {
      this.isLoadingSuppliers = true
      const response = await this.getSupplierList()

      if (response && response.data) {
        this.suppliers = response.data
      }

      this.isLoadingSuppliers = false
    },
    // Fetch Data
    /**
     * Fetch Users
     */
    async fetchActivityOwners() {
      this.isLoadingActivityOwners = true
      const response = await this.getAllActivityOwners()
      if (response) {
        this.filteredActivityOwners = response
      }
      this.isLoadingActivityOwners = false
    },

    /**
     * Fetch Roles
     */
    async fetchRoles() {
      this.isLoadingRoles = true
      const response = await this.getAllActivityRoleList()
      if (response) {
        this.roles = response
      }
      this.isLoadingRoles = false
    },

    async fetchRemoteAccessFactors() {
      this.isLoadingRemoteAccessFactors = true

      const response = await this.getRemoteAccessFactorList()

      if (response && response.data) {
        this.remoteAccessFactors = response.data
      }

      this.isLoadingRemoteAccessFactors = false
    },

    /**
     * Fetch Utilities
     */
    async fetchUtilities() {
      this.isLoadingUtilities = true
      const response = await this.getUtilitiesList()

      if (response && response.data) {
        this.utilities = response.data
      }
      this.isLoadingUtilities = false
    },

    /**
     * Fetch Divisions
     */
    async fetchDivisions() {
      this.isLoadingDivisions = true
      const response = await this.getDivisionList()
      if (response && response.data) {
        this.divisions = response.data
        // Prefill Division
        if (this.prefillMode && this.divisionUid?.length > 0) {
          const division = this.divisions.find((item) => item.uid === this.divisionUid) ?? null
          this.populateActivitiesProperty("division", division)
        }
      }

      this.isLoadingDivisions = false
    },

    /**
     * Fetch Business Units
     */
    async fetchBusinessUnits() {
      this.isLoadingBusinessUnits = true
      const response = await this.getBusinessUnitList()

      if (response && response.data) {
        this.businessUnits = response.data

        // Prefill Business Unit
        if (this.prefillMode && this.divisionUid?.length > 0) {
          const bu = this.businessUnits.find((item) => item.uid === this.businessUnitUid) ?? null
          this.populateActivitiesProperty("business_unit", bu)
        }
      }
      this.isLoadingBusinessUnits = false
    },

    /**
     * Fetch Applications
     */
    async fetchApplications() {
      this.isLoadingSoftwares = true
      const response = await this.getApplicationList()

      if (response && response.data) {
        this.applications = response.data
      }

      this.isLoadingSoftwares = false
    },

    /**
     * Fetch equipment
     * @returns {Promise<void>}
     */
    async fetchEquipments() {
      this.isLoadingEquipments = true
      const response = await this.getAllEquipments()

      if (response) {
        this.equipments = response
      }

      this.isLoadingEquipments = false
    },

    async fetchRecoveryTimeOptions() {
      this.isLoadingRecoveryTimes = true
      const response = await this.getRecoveryTimeOptions()

      if (response) {
        this.recoveryTimeOptions = response
      }

      this.isLoadingRecoveryTimes = false
    },

    async fetchDisruptionScenarios() {
      this.isLoadingDisruptionScenarios = true
      const response = await this.getDisruptionScenarios()

      if (response) {
        this.disruptionScenarios = response
        // this.appendGridHeaderForThreatRanking()
        this.makeScenariosMapping()
      }

      this.isLoadingDisruptionScenarios = false
    },

    async fetchMTDPTimeOptions() {
      this.isLoadingMTDPTimeOptions = true
      const response = await this.getMTDPTimeOptions()

      if (response) {
        this.mtdpTimeOptions = response
      }

      this.isLoadingMTDPTimeOptions = false
    },

    async fetchInternalDependencies() {
      this.isLoadingInternalDependencies = true
      const response = await this.getBusinessUnitList(true)

      if (response && response.data) {
        this.internalDependencies = this.transformInternalDependencies(response.data)
      }
      this.isLoadingInternalDependencies = false
    },

    /**
     * Fetch locations master data
     */
    async fetchLocations() {
      this.isLoadingLocations = true
      const response = await this.getAllLocations()
      if (response) {
        this.locations = response
      }
      this.isLoadingLocations = false
    },

    /**
     *
     * @param {*} activity
     */
    transformResponseToFormData(activity) {
      const tpd = activity?.tolerable_period_disruptions[0]
      if (tpd) {
        activity.dependent_time = tpd?.pivot?.dependent_time ?? null
        activity.reason_choose_dependent_time = tpd?.pivot?.reason_choose_dependent_time ?? null
        activity.tolerable_period_disruption = tpd.uid
        activity.mtpdValue = tpd.value
      } else {
        activity.tolerable_period_disruption = this.filteredTimeOptions[0]?.uid
        activity.mtpdValue = this.filteredTimeOptions[0]?.value ?? 1
      }

      const recoveryTime = activity?.recovery_times[0]
      activity.recoveryTime = recoveryTime?.uid ?? this.filteredRecoveryTimeOptions[0]?.uid
      activity.recoveryTimeValue = recoveryTime?.value ?? this.filteredRecoveryTimeOptions[0]?.value ?? 1
      activity.is_rto_tested = recoveryTime?.pivot?.is_rto_tested ?? false
      activity.mld_rpo = recoveryTime?.pivot?.mld_rpo ?? null

      if (activity?.assignees && activity?.assignees.length > 0) {
        const assignee = this.cloneObject(activity?.assignees[0])
        activity.assignee = assignee?.assignable || {}
        activity.assignee.type = assignee?.type
      }

      if (activity.disruption_scenarios) {
        const ds = this.cloneObject(activity.disruption_scenarios)
        delete activity.disruption_scenarios
        activity.disruption_scenarios = ds?.map((item) => {
          return {
            uid: item?.uid,
            name: item?.name,
            // workaround_option: item?.pivot?.workaround_solution?.length > 0 ? this.EW_FREE_TEXT : this.EW_NONE_IDENTIFIED,
            workaround_solution: item?.pivot?.workaround_solution,
            workaround_feasibly: item?.pivot?.workaround_feasibly,
            feasible_activation: item?.pivot?.feasible_activation,
          }
        })
      }
      if (activity?.internal_dependencies?.length > 0) {
        this.transformInternalDependencies(activity.internal_dependencies)
      }

      // transform suppliers and bcm assessed method
      if (activity.suppliers.length > 0) {
        activity.suppliers?.forEach((supplier) => {
          supplier.assessed_methods = supplier.pivot?.metadata?.assessed_methods ?? null
        })
      }
    },

    /**
     * Transform payload
     */
    transformToPayloadSaveBulkActivity(activity) {
      delete activity.tolerable_period_disruptions
      activity.tolerable_period_disruptions = [
        {
          uid: activity.tolerable_period_disruption ?? this.filteredTimeOptions[0]?.uid,
          dependent_time: activity.dependent_time,
          reason_choose_dependent_time: activity.reason_choose_dependent_time,
        },
      ]
      activity.recovery_times = [
        {
          uid: activity.recoveryTime ?? this.filteredRecoveryTimeOptions[0]?.uid,
          is_rto_tested: activity.is_rto_tested ?? false,
          mld_rpo: activity.mld_rpo ?? null,
        },
      ]
    },

    /**
     * Transform internal dependencies
     * @param {*} data
     */
    transformInternalDependencies(data) {
      return data
        .map((item) => {
          item.transformName = this.transformBUName(item)
          return item
        })
        .sort(function (a, b) {
          const x = a.transformName.toLowerCase()
          const y = b.transformName.toLowerCase()
          return x < y ? -1 : x > y ? 1 : 0
        })
    },

    /**
     * Transform BU
     */
    transformBUName(bu) {
      return (bu.division ? bu.division.name + " - " : "") + bu.name
    },

    /**
     * Create draft bulk activity session
     */
    async createDraftBulkActivitySession() {
      const payload = {
        name: "Bulk Add Activities " + new Date().toLocaleString("en-AU"),
      }
      const response = await this.createNewDraftBulkActivity(payload)
      if (response) {
        this.bulkDraftUid = response.data.uid
        const url = this.$router.resolve({
          name: "ViewActivityBulkDraftDetail",
          params: { uid: this.bulkDraftUid },
        })

        if (url) {
          history.pushState({}, null, url.fullPath)
        }
      }
    },

    /**
     * Persist draft activity
     * @param {*} index
     */
    async persistDraftActivity(index) {
      if (!this.bulkDraftUid || this.bulkDraftUid?.length === 0) {
        await this.createDraftBulkActivitySession()
      }
      this.v$.$touch()
      const activity = this.activities[index]
      this.transformToPayloadSaveBulkActivity(activity)
      const payload = {
        activity_draft_uid: this.bulkDraftUid,
        is_finish: false,
        bulk_activities: [activity],
      }
      const response = await this.persistBulkActivitiesData(payload)

      if (response && response.data?.errors?.length === 0) {
        this.activities[index].uid = response.data.activities[0].uid
        this.syncActivityOriginState(index)
        this.cleanBackendValidationErrors(index)
        this.activityHasError500 = []
      }

      if (response?.data?.errors?.length > 0) {
        this.parseBackendProcessErrorForIndex(response.data.errors, index)
      }
    },

    /**
     * Fetch bulk draft detail
     */
    async fetchBulkDraftDetail() {
      this.isUpdating = true
      const response = await this.getBulkDraftDetail(this.bulkDraftUid)
      if (!response) {
        goto("ViewActivityList")
      }

      this.activities = response?.data?.activities ?? []
      this.activities.forEach((activity) => {
        this.transformResponseToFormData(activity)
        this.originActivities = this.cloneObject(activity)
        this.backendValidationErrors.push([])
      })

      if (this.activities.length < this.numOfRecord) {
        this.addActivityRows(this.numOfRecord - this.activities.length)
      }

      this.syncActivityOriginState()
      this.isUpdating = false
    },

    // Filter data

    /**
     * Filtered application
     * @param {*} activity
     */
    filteredApplications(activity) {
      const selectedAppUids = activity?.applications?.map((app) => {
        return app.uid
      })
      return this.cloneObject(this.applications)?.map((app) => {
        app.disabled = selectedAppUids?.includes(app.uid)

        return app
      })
    },

    /**
     * Filtered equipment
     * @param {*} activity
     */
    filteredEquipments(activity) {
      const selectedEquipmentUids = activity?.equipments?.map((equ) => {
        return equ.uid
      })

      return this.cloneObject(this.equipments)?.map((equipment) => {
        equipment.disabled = selectedEquipmentUids?.includes(equipment.uid)

        return equipment
      })
    },

    filteredSuppliers(activity) {
      const selectedSupplierIds = activity?.suppliers?.map((item) => {
        return item.uid
      })
      return this.cloneObject(this.suppliers)?.map((supplier) => {
        supplier.disabled = selectedSupplierIds?.includes(supplier.uid)

        return supplier
      })
    },

    filteredInternalDependencies(activity) {
      const selectedInternalDependencyUIDs = activity?.internal_dependencies?.map((app) => {
        return app.uid
      })
      return this.cloneObject(this.internalDependencies)?.map((app) => {
        app.disabled = selectedInternalDependencyUIDs?.includes(app.uid)

        return app
      })
    },

    filteredRoles(activity) {
      const selectedRoleUids = activity?.roles?.map((r) => {
        return r.uid
      })

      return this.cloneObject(this.roles)?.map((role) => {
        role.disabled = selectedRoleUids?.includes(role.uid)

        return role
      })
    },

    filteredAlternativeRoles(activity) {
      const selectedAlternativeRoleUids = activity?.alternative_roles?.map((r) => {
        return r.uid
      })
      const selectedRoleUids = activity?.roles?.map((r) => {
        return r.uid
      })
      return this.cloneObject(this.roles)
        ?.filter((role) => {
          return !selectedRoleUids?.includes(role.uid)
        })
        .map((role) => {
          role.disabled = selectedAlternativeRoleUids?.includes(role.uid)

          return role
        })
    },

    filteredRemoteAccessFactors(activity) {
      const selectedRAFUids = activity?.remote_access_factors?.map((raf) => {
        return raf.uid
      })

      return this.cloneObject(this.remoteAccessFactors)?.map((remoteAccessFactor) => {
        remoteAccessFactor.disabled = selectedRAFUids?.includes(remoteAccessFactor.uid)

        return remoteAccessFactor
      })
    },

    filteredUtilities(activity) {
      const selectedUtilityIds = activity.utilities?.map((item) => {
        return item.uid
      })
      return this.cloneObject(this.utilities)?.map((utility) => {
        utility.disabled = selectedUtilityIds?.includes(utility.uid)

        return utility
      })
    },

    cloneObject(object) {
      return JSON.parse(JSON.stringify(object))
    },

    filteredBusinessUnits(activity) {
      if (isEmpty(activity.division?.uid)) {
        return this.businessUnits
      }

      return this.businessUnits?.filter((bu) => {
        return !bu.division?.uid || bu.division?.uid === activity.division?.uid
      })
    },

    isRemote(activity) {
      return activity?.is_remote
    },

    isOnSiteDisabled(activity) {
      if (!activity.remote_access_factors) {
        return false
      }

      return (
        activity.remote_access_factors.filter((item) => {
          return item?.uid?.length > 0
        }).length > 0
      )
    },

    /**
     *
     * @param {*} activity
     */
    clearBusinessUnit(activity) {
      activity.business_unit = null
    },

    /**
     * Get activity disruption scenario by uid
     * @param rowIndex
     * @param uid
     */
    getScenarioThreatRanking(rowIndex, uid) {
      const scenarios = this.activities[rowIndex]?.disruption_scenarios ?? null
      const scenario = scenarios?.find((item) => item.uid === uid) ?? null
      return scenario ?? null
    },

    getScenarioThreatRankingByName(rowIndex, name) {
      const scenarios = this.activities[rowIndex]?.disruption_scenarios ?? null
      const scenario = scenarios?.find((item) => item.name === name) ?? null
      return scenario ?? null
    },

    /**
     * Append Threat ranking column
     */
    appendGridHeaderForThreatRanking() {
      if (isEmpty(this.filteredDisruptionScenarios)) {
        return
      }

      this.filteredDisruptionScenarios?.forEach((scenario) => {
        const workaroundColumns = [
          {
            class: "activity-workaround w-2/12 3xl:w-1/12",
            text: "activity.bulkAddActivities.columns.excel_workaround",
            textParams: { disruptionName: scenario.name },
            name: "workaround_solution",
            uid: scenario?.uid,
          },
          {
            class: "activity-workaround-feasible-duration w-2/12 3xl:w-1/12",
            text: "activity.bulkAddActivities.columns.excel_workaround_feasible_duration",
            name: "workaround_feasibly",
            uid: scenario?.uid,
          },
          {
            class: "activity-workaround-feasible-activation w-2/12 3xl:w-1/12",
            text: "activity.bulkAddActivities.columns.excel_workaround_feasible_activation",
            name: "feasible_activation",
            uid: scenario?.uid,
          },
        ]

        this.gridHeaders = [...this.gridHeaders, ...workaroundColumns]
        this.threatRankingHeaders = [...this.threatRankingHeaders, ...workaroundColumns]
      })
      this.numOfGridColumns = this.gridHeaders.length
      this.isAllowAppendForThreatRankingColumn = true
      for (let i = 0; i < this.activities.length; i++) {
        this.appendThreatRankingData[i] = []
      }
      this.syncActivityRowWidth()
    },

    /**
     * Make scenario mapping
     */
    makeScenariosMapping() {
      if (isEmpty(this.filteredDisruptionScenarios)) {
        return
      }

      const scenarioMapping = []

      this.filteredDisruptionScenarios?.forEach((scenario) => {
        scenarioMapping[scenario.name] = scenario
      })
      this.disruptionScenariosMapping = scenarioMapping
    },

    /**
     * Handle Change ThreatRanking Value
     * @param index
     * @param fieldName
     * @param scenarioUID
     */
    handleChangeThreatRankingValue(index, fieldName, scenarioUID) {
      const scenario = this.activities[index]?.disruption_scenarios?.find((item) => item.uid === scenarioUID) ?? null
      if (scenario) {
        scenario[fieldName] = event.target.value
      }
    },

    /**
     * Init header column draggable
     *
     */
    startHeaderColumnDrag(index) {
      this.isAllowHeaderResize = true
      this.currentIndexColumnResize = index
      window.addEventListener("mousemove", this.handleHeaderColumnDrag)
      window.addEventListener("mouseup", this.handleHeaderColumnStopDrag)
    },

    /**
     * Handle column drag
     */
    handleHeaderColumnDrag(event) {
      if (!this.isAllowHeaderResize) {
        return
      }

      const column = this.$refs["gridHeaderColumn" + this.currentIndexColumnResize][0]?.$el ?? null
      if (!column) {
        return
      }

      const startPosition = column.getBoundingClientRect()?.right
      const originWidth = column.offsetWidth
      const draggableWidth = event.clientX - startPosition
      const resizeWidth = originWidth + draggableWidth
      const minWidth = (this.gridHeaderColumnWidthOrigin[this.currentIndexColumnResize] * 2) / 3
      this.gridHeaderColumnWidthResize[this.currentIndexColumnResize] = resizeWidth > minWidth ? resizeWidth : minWidth
    },

    /**
     * Handle column stop drag
     */
    handleHeaderColumnStopDrag() {
      this.isAllowHeaderResize = false
      this.currentIndexColumnResize = null
      window.removeEventListener("mousemove", this.handleHeaderColumnDrag)
      window.removeEventListener("mouseup", this.handleHeaderColumnStopDrag)
    },

    /**
     *
     * @param {*} activity
     */
    handleResetRemoteAccessFactor(activity) {
      activity.remote_access_factors = []
      activity.on_site_requires = ""
    },

    /**
     * Save row when clicking out
     */
    handleClickDataGridRow(index) {
      if (this.currentDraftIndex !== index) {
        this.previousDraftIndex = this.currentDraftIndex
        this.currentDraftIndex = index

        // Set default value for name
        this.activities[this.previousDraftIndex].name = this.activities[this.previousDraftIndex].name || ""

        if (!this.isAllowToPersistActivityData(this.previousDraftIndex)) {
          return
        }

        this.persistActivityData(this.previousDraftIndex)
      }
    },

    /**
     *
     * @param {*} recordIndex
     */
    isAllowToPersistActivityData(recordIndex) {
      this.v$.$touch()
      const activity = this.activities[recordIndex]
      return (
        activity?.name?.length > 0 &&
        !this.v$.activities?.$each?.$response?.$errors[recordIndex]?.name?.length &&
        this.isActivityDirty(recordIndex)
      )
    },

    /**
     *
     */
    persistActivityData: throttle(function (recordIndex) {
      this.isPersistingActivityData = true
      this.persistDraftActivity(recordIndex)
      this.isPersistingActivityData = false
    }, 1500),

    /**
     *
     * @param {*} numOfRows
     * @param syncOrigin
     */
    addActivityRows(numOfRows, syncOrigin = false) {
      const prefillData = {}
      if (this.prefillMode) {
        if (this.divisionUid?.length > 0) {
          prefillData.division = this.divisions.find((item) => item.uid === this.divisionUid) ?? null
        }

        if (this.businessUnitUid?.length > 0) {
          prefillData.business_unit = this.businessUnits.find((item) => item.uid === this.businessUnitUid) ?? null
        }
      }

      for (let i = 0; i < numOfRows; i++) {
        const initialData = this.getActivityInitialData()
        const activity = {
          ...initialData,
          ...prefillData,
        }
        this.activities.push(activity)
        this.appendThreatRankingData.push([])
        this.backendValidationErrors.push([])
        if (syncOrigin) {
          this.originActivities.push(this.cloneObject(activity))
        }
      }
    },

    populateActivitiesProperty(column, value) {
      this.activities.forEach((activity) => {
        activity[column] = value
      })
    },

    // Handle page action
    handleClickBack() {
      goto("ViewActivityList")
    },

    async handleClickSaveDraftExit() {
      const success = await this.handleClickSaveDraft()

      if (success) {
        this.handleClickBack()
      }
    },

    /**
     * Handle click save draft
     */
    async handleClickSaveDraft() {
      this.v$.$touch()
      if (this.v$.$invalid || this.isBackendValidatorHasError()) {
        this.globalStore.addErrorToastMessage(this.$t("activity.bulkAddActivities.messages.invalidData"))
        return false
      }

      const saveRecords = this.activities.filter((obj, index) => {
        return !isEmpty(obj) && obj?.name?.length > 0
      })

      if (saveRecords.length === 0) {
        this.globalStore.addErrorToastMessage(this.$t("activity.bulkAddActivities.messages.saveRequiredAtLeastOneRecord"))
        return false
      }

      this.isUpdating = true
      saveRecords.forEach((activity) => {
        this.transformToPayloadSaveBulkActivity(activity)
      })

      if (!this.bulkDraftUid || this.bulkDraftUid?.length === 0) {
        await this.createDraftBulkActivitySession()
      }

      const payload = {
        activity_draft_uid: this.bulkDraftUid,
        is_finish: false,
        bulk_activities: saveRecords,
      }

      const savedData = await this.persistBulkActivitiesData(payload)
      this.isUpdating = false

      if (savedData && savedData?.data?.errors?.length === 0) {
        this.globalStore.addSuccessToastMessage(this.$t("activity.bulkAddActivities.messages.saveDraftSuccess"))
        this.syncActivityOriginState()
        this.cleanBackendValidationErrors()
        this.activityHasError500 = []

        this.activities.forEach((activity, index) => {
          if (savedData.data?.activities[index]?.uid) {
            const uid = savedData.data.activities[index].uid

            this.activities[index].uid = uid
          }
        })

        return true
      }

      if (savedData?.data?.errors?.length > 0) {
        this.parseBackendProcessError(savedData.data.errors)
      }

      return false
    },

    /**
     * Click Finish
     */
    async handleClickFinish() {
      this.v$.$touch()
      if (this.v$.$invalid || this.isBackendValidatorHasError()) {
        this.globalStore.addErrorToastMessage(this.$t("activity.bulkAddActivities.messages.invalidData"))
        return
      }

      const saveRecords = this.activities.filter((obj, index) => {
        return !isEmpty(obj) && obj?.name?.length > 0
      })
      this.isUpdating = true

      if (saveRecords.length === 0) {
        if (this.bulkDraftUid?.length > 0) {
          await this.deleteBulkActivitiesDraft(this.bulkDraftUid)
        }
        goto("ViewActivityList")
        return
      }

      if (!this.bulkDraftUid || this.bulkDraftUid?.length === 0) {
        await this.createDraftBulkActivitySession()
      }

      saveRecords.forEach((activity) => {
        this.transformToPayloadSaveBulkActivity(activity)
      })
      const payload = {
        activity_draft_uid: this.bulkDraftUid,
        is_finish: true,
        bulk_activities: saveRecords,
      }

      const savedData = await this.persistBulkActivitiesData(payload)
      this.isUpdating = false

      if (savedData && savedData?.data?.errors?.length === 0) {
        this.globalStore.addSuccessToastMessage(this.$t("activity.bulkAddActivities.messages.saveSuccess"))
        await this.sleep(1500)
        goto("ViewActivityList")
      }

      if (savedData?.data?.errors?.length > 0) {
        this.parseBackendProcessError(savedData.data.errors)
      }
    },

    /**
     * Delete draft
     */
    handleClickDelete() {
      this.isModalDeleteDraftOpen = true
    },

    /**
     *
     */
    handleCloseAddNewOwnerModal() {
      this.isModalAddNewOwnerOpen = false
    },

    /**
     * Close delete draft
     */
    handleCloseDeleteDraftlModal() {
      this.isModalDeleteDraftOpen = false
    },

    /**
     * Sync activities origin state
     */
    syncActivityOriginState(index) {
      if (index && this.activities[index]) {
        this.originActivities[index] = this.cloneObject(this.activities[index])
        return
      }

      this.originActivities = this.cloneObject(this.activities)
    },

    /**
     * Check is activity has dirty
     * @param recordIndex
     * @returns {boolean}
     */
    isActivityDirty(recordIndex) {
      // the simple way to check dirty
      return JSON.stringify(this.originActivities[recordIndex]) !== JSON.stringify(this.activities[recordIndex])
    },

    /**
     * Get row initial Data
     */
    getActivityInitialData() {
      return {
        tolerable_period_disruption: this.filteredTimeOptions[0]?.uid ?? null,
        mtpdValue: this.filteredTimeOptions[0]?.value ?? 1,
        recoveryTime: this.filteredRecoveryTimeOptions[0]?.uid ?? null,
        recoveryTimeValue: this.filteredRecoveryTimeOptions[0]?.value ?? 1,
        is_remote: false,
        is_reliant_server: false,
        is_critical: false,
        is_rto_tested: false,
      }
    },

    /**
     *
     * @param {*} type
     * @param {*} payload
     */
    async handleCreateActivityMasterData(type, payload) {
      return await this.createActivityMasterData({
        ...payload,
        type,
      })
    },

    /**
     *
     * @param {*} owner
     */
    async handleCallbackAddNewOwner(owner) {
      if (owner) {
        this.filteredActivityOwners?.push(owner)
        this.activities[this.currentDraftIndex].assignee = owner
      }
    },

    async handleAddNewDivisionSelectOption(option) {
      this.isLoadingDivisions = true
      const division = await this.handleCreateActivityMasterData(this.activityMasterDataTypeEnum.DIVISION, option)
      if (division) {
        this.divisions.push(division)
      }
      this.isLoadingDivisions = false

      return division
    },

    async handleAddNewBusinessUnitSelectOption(option) {
      this.isLoadingBusinessUnits = true
      const unit = await this.handleCreateActivityMasterData(this.activityMasterDataTypeEnum.BUSINESS_UNIT, option)
      if (unit) {
        this.businessUnits.push(unit)
      }
      this.isLoadingBusinessUnits = false

      return unit
    },

    /**
     *
     * @param {*} option
     */
    async handleAddNewRoleSelectOption(option) {
      this.isLoadingRoles = true
      const role = await this.handleCreateActivityMasterData(this.activityMasterDataTypeEnum.ACTIVITY_ROLE, option)
      if (role) {
        this.roles.push(role)
      }
      this.isLoadingRoles = false

      return role
    },

    /**
     *
     * @param {*} option
     */
    async handleAddNewLocationSelectOption(option) {
      this.isLoadingLocations = true
      const location = await this.handleCreateActivityMasterData(this.activityMasterDataTypeEnum.LOCATION, option)
      if (location) {
        this.locations.push(location)
      }
      this.isLoadingLocations = false

      return location
    },

    /**
     *
     * @param {*} option
     */
    async handleAddNewRemoteAccessSelectOption(option) {
      this.isLoadingRemoteAccessFactors = true
      const remoteAccessFactor = await this.handleCreateActivityMasterData(
        this.activityMasterDataTypeEnum.REMOTE_ACCESS_FACTOR,
        option
      )
      if (remoteAccessFactor) {
        this.remoteAccessFactors.push(remoteAccessFactor)
      }
      this.isLoadingRemoteAccessFactors = false

      return remoteAccessFactor
    },

    /**
     * Add new Utility option
     * @param {*} option
     */
    async handleAddNewUtilitySelectOption(option) {
      this.isLoadingUtilities = true
      const utility = await this.handleCreateActivityMasterData(this.activityMasterDataTypeEnum.UTILITY, option)
      if (utility) {
        this.utilities.push(utility)
      }
      this.isLoadingUtilities = true

      return utility
    },

    /**
     *
     * @param {*} option
     * Add new App select option
     */
    async handleAddNewAppSelectOption(option) {
      this.isLoadingSoftwares = true
      const application = await this.handleCreateActivityMasterData(this.activityMasterDataTypeEnum.APPLICATION, option)
      if (application) {
        this.applications.push(application)
      }
      this.isLoadingSoftwares = false

      return application
    },

    /**
     *
     * @param {*} option
     */
    async handleAddNewEquipmentSelectOption(option) {
      this.isLoadingEquipments = true
      const equipment = await this.handleCreateActivityMasterData(this.activityMasterDataTypeEnum.EQUIPMENT, option)
      if (equipment) {
        this.equipments.push(equipment)
      }
      this.isLoadingEquipments = false

      return equipment
    },

    /**
     *
     * @param {*} option
     */
    async handleAddNewSupplierSelectOption(option) {
      this.isLoadingSuppliers = true
      option.status = "active"
      const supplier = await this.handleCreateActivityMasterData(this.activityMasterDataTypeEnum.SUPPLIER, option)
      if (supplier) {
        this.suppliers.push(supplier)
      }
      this.isLoadingSuppliers = false

      return supplier
    },

    /**
     *
     * @param {*} option
     */
    handleAddNewOwnerSelectOption(option) {
      this.tempActivityOwner = option
      this.isModalAddNewOwnerOpen = true
      // this.filteredActivityOwners.push(option)
    },

    /**
     * Handle change activity is remote value
     * @param activity
     */
    handlesChangeActivityAllowRemote(activity) {
      if (!this.isRemote(activity)) {
        activity.remote_access_factors = null
      } else {
        activity.on_site_requires = null
      }
    },

    /**
     * Open context Menu
     */
    openContextMenu(event, record, idx, field, fieldContext = null) {
      event.preventDefault()

      if (record?.name?.length < 0) {
        return
      }

      if (this.$refs.bulkCtxMenu.isCtxOpenByMouse) {
        return
      }

      this.$refs.bulkCtxMenu.activityRow = record
      this.$refs.bulkCtxMenu.activityIndex = idx
      this.$refs.bulkCtxMenu.field = field
      this.$refs.bulkCtxMenu.isCtxOpenByMouse = event
      this.$refs.bulkCtxMenu.fieldContext = fieldContext
    },

    /**
     * Close context menu
     */
    closeContextMenu() {
      this.$refs.bulkCtxMenu.isCtxOpenByMouse = false
      this.$refs.bulkCtxMenu.fieldContext = null
    },

    /**
     *
     * @param {*} eventName
     * @param {*} onActionRowIndex
     * @param field
     * @param fieldContext
     */
    async handleContextMenuEvent(eventName, onActionRowIndex, field, fieldContext = null) {
      switch (eventName) {
        case menuAction.COPY_ROW:
          this.ctxCopyRow(onActionRowIndex)
          break

        case menuAction.PASTE_ROW:
          this.ctxPasteRow(onActionRowIndex)
          break

        case menuAction.DUPLICATE_ROW:
          this.ctxDuplicateRow(onActionRowIndex)
          break
        case menuAction.INSERT_ROW:
          this.ctxInsertNewRowBellow(onActionRowIndex)
          break

        case menuAction.REMOVE_ROW:
          await this.ctxRemoveRow(onActionRowIndex)
          break

        case menuAction.COPY_FIELD:
          if (fieldContext) {
            this.ctxCopyFieldWithContext(onActionRowIndex, field, fieldContext)
          } else {
            this.ctxCopyField(onActionRowIndex, field)
          }
          break

        case menuAction.PASTE_FIELD:
          if (this.copiedFieldName?.includes("#") || fieldContext !== null) {
            this.ctxPasteFieldWithContext(onActionRowIndex, field, fieldContext)
          } else {
            this.ctxPasteField(onActionRowIndex, field)
          }
          break

        case menuAction.PASTE_FROM_EXCEL:
          this.ctxPasteFromExcel(onActionRowIndex)
          break

        default:
          break
      }
    },

    /**
     * Input new row number
     */
    handleInputNewRowNumber() {
      if (isNaN(this.newRowCnt)) {
        this.newRowCnt = 0
      }

      if (this.newRowCnt > 10 || this.newRowCnt < 0) {
        this.newRowCnt = 10
      }
    },

    /**
     * Add new row to activities array
     */
    handlerInputNewRows() {
      this.addActivityRows(this.newRowCnt, true)
      this.newRowCnt = 0
    },

    /**
     * Handle change RTO
     * @param activity
     */
    handleChangeRTO(activity) {
      const rto = this.recoveryTimeOptions.find((t) => t.uid === activity.recoveryTime)
      activity.recoveryTimeValue = rto?.value
      this.v$.$touch()
    },

    /**
     * Handle change MTPD(
     * @param activity
     */
    handleChangeMTPD(activity) {
      const mtpd = this.mtdpTimeOptions.find((t) => t.uid === activity.tolerable_period_disruption)
      activity.mtpdValue = mtpd?.value
      this.v$.$touch()
    },

    /**
     * ========= Context menu function ========
     */

    /**
     * Copy row
     */
    ctxCopyRow(onActionRowIndex) {
      this.copiedIndex = onActionRowIndex
      this.closeContextMenu()
      this.globalStore.addSuccessToastMessage("Copied row")
    },

    /**
     * Paste row
     */
    ctxPasteRow(onActionRowIndex) {
      if (this.copiedIndex !== null && this.activities?.[this.copiedIndex] && this.activities?.[onActionRowIndex]) {
        const existedUid = this.activities[onActionRowIndex]?.uid
        this.activities[onActionRowIndex] = JSON.parse(JSON.stringify(this.activities[this.copiedIndex]))
        this.activities[onActionRowIndex].name = this.activities[onActionRowIndex].name + " - copied"
        this.activities[onActionRowIndex].uid = existedUid
      }

      this.closeContextMenu()
    },

    /**
     *
     * @param {*} onActionRowIndex
     */
    ctxDuplicateRow(onActionRowIndex) {
      if (onActionRowIndex !== null && this.activities?.[onActionRowIndex]) {
        const duplicatedRow = JSON.parse(JSON.stringify(this.activities[onActionRowIndex]))
        duplicatedRow.uid = null

        duplicatedRow.name = duplicatedRow.name + " - duplicated"

        this.activities.splice(onActionRowIndex + 1, 0, duplicatedRow)
        this.originActivities.splice(onActionRowIndex + 1, 0, this.getActivityInitialData())
        this.backendValidationErrors.splice(onActionRowIndex + 1, 0, [])
      }

      this.closeContextMenu()
    },

    /**
     * Insert new row bellow
     */
    ctxInsertNewRowBellow(onActionRowIndex) {
      this.activities.splice(onActionRowIndex + 1, 0, this.getActivityInitialData())
      this.originActivities.splice(onActionRowIndex + 1, 0, this.getActivityInitialData())
      this.backendValidationErrors.splice(onActionRowIndex + 1, 0, [])
    },

    /**
     *
     * @param {*} onActionRowIndex
     */
    async ctxRemoveRow(onActionRowIndex) {
      if (this.activities?.[onActionRowIndex]) {
        if (this.activities[onActionRowIndex]?.uid?.length > 0) {
          const res = await this.cancelActivity(this.activities[onActionRowIndex]?.uid)
          if (res) {
            this.activities.splice(onActionRowIndex, 1)
            this.originActivities.splice(onActionRowIndex, 1)
            this.backendValidationErrors.splice(onActionRowIndex, 1)
          } else {
            this.globalStore.addErrorToastMessage("Unable to remove selected row")
          }
        } else {
          this.activities.splice(onActionRowIndex, 1)
          this.originActivities.splice(onActionRowIndex, 1)
          this.backendValidationErrors.splice(onActionRowIndex, 1)
        }
      }

      this.closeContextMenu()
    },

    /**
     *
     * @param {*} onActionRowIndex
     * @param {*} field
     */
    ctxCopyField(onActionRowIndex, field) {
      this.copiedIndex = onActionRowIndex

      if (
        onActionRowIndex !== null &&
        this.activities?.[onActionRowIndex] &&
        Object.hasOwn(this.activities?.[onActionRowIndex] || {}, field)
      ) {
        this.copiedFieldName = field
        this.copiedFieldWithContextValue = null
        this.globalStore.addSuccessToastMessage("Copied field")
      }
    },

    /**
     * Copy field with context
     * @param onActionRowIndex
     * @param field
     * @param fieldContext
     */
    ctxCopyFieldWithContext(onActionRowIndex, field, fieldContext) {
      if (onActionRowIndex === null || !fieldContext || fieldContext?.uid?.length === 0 || fieldContext?.field?.length === 0) {
        return
      }

      const activity = this.activities?.[onActionRowIndex]
      if (!activity) {
        return
      }

      const object = field?.includes(".") ? activity[field] : getNestedObject(activity, field)

      if (!object) {
        return
      }

      if (!(object instanceof Array)) {
        return
      }

      const copyTargetObject = object?.find((item) => item?.uid === fieldContext.uid) ?? null
      if (!copyTargetObject || !copyTargetObject[fieldContext.field] || copyTargetObject[fieldContext.field]?.length === 0) {
        return
      }

      this.copiedFieldName = field + "#" + fieldContext.uid
      this.copiedFieldWithContextValue = copyTargetObject[fieldContext.field]
      this.globalStore.addSuccessToastMessage("Copied field")
    },

    /**
     * @param {*} onActionRowIndex
     * @param {*} field
     */
    ctxPasteField(onActionRowIndex, field) {
      /**
       * Make sure that the action row index existed
       *
       */

      if (
        this.copiedIndex !== null &&
        this.activities?.[this.copiedIndex] &&
        this.activities?.[onActionRowIndex] &&
        Object.hasOwn(this.activities?.[this.copiedIndex] || {}, field)
      ) {
        this.activities[onActionRowIndex][field] = JSON.parse(JSON.stringify(this.activities?.[this.copiedIndex][field]))
      }
    },

    /**
     * Paste field with context
     * @param onActionRowIndex
     * @param field
     * @param fieldContext
     */
    ctxPasteFieldWithContext(onActionRowIndex, field, fieldContext) {
      if (
        onActionRowIndex === null ||
        !fieldContext ||
        fieldContext?.uid?.length === 0 ||
        fieldContext?.field?.length === 0 ||
        !this.copiedFieldWithContextValue ||
        !this.copiedFieldName?.includes(fieldContext.uid)
      ) {
        return
      }

      const activity = this.activities?.[onActionRowIndex]
      if (!activity) {
        return
      }

      const object = field?.includes(".") ? activity[field] : getNestedObject(activity, field)

      if (!object) {
        return
      }

      if (!(object instanceof Array)) {
        return
      }

      const targetPasteObject = object?.find((item) => item?.uid === fieldContext.uid) ?? null
      if (!targetPasteObject) {
        return
      }

      targetPasteObject[fieldContext.field] = this.copiedFieldWithContextValue
    },

    /**
     *
     * @param {*} onActionRowIndex
     */
    async ctxPasteFromExcel(onActionRowIndex) {
      if (this.activities?.[onActionRowIndex]) {
        const parsedActivities = await this.parseExcelRows()

        parsedActivities?.forEach((parsedActivity) => {
          if (parsedActivity) {
            if (onActionRowIndex > this.activities?.length - 1) {
              this.addActivityRows(1)
            }

            const activity = this.activities[onActionRowIndex]

            // Fill in plain text data
            this.fillOutActivityDataFromParsedRow(activity, parsedActivity)

            /**
             * Check and fill in options
             *
             */

            // Division
            this.excelFillInDivision(
              activity,
              parsedActivity,
              this.divisions,
              this.handleAddNewDivisionSelectOption,
              (activityObject, parsedActivityObject) => {
                // BU
                this.excelFillInBU(
                  activityObject,
                  parsedActivityObject,
                  this.businessUnits,
                  this.handleAddNewBusinessUnitSelectOption
                )
              }
            )

            this.excelFillInActivityOwner(activity, parsedActivity, this.filteredActivityOwners)

            this.excelFillInLocation(activity, parsedActivity, this.locations)

            // Roles
            this.excelFillInRoles(activity, parsedActivity, this.roles, this.handleAddNewRoleSelectOption)

            this.excelFillInAlternativeRoles(activity, parsedActivity, this.roles, this.handleAddNewRoleSelectOption)

            // RAFs
            this.excelFillInRemoteAccessFactors(
              activity,
              parsedActivity,
              this.remoteAccessFactors,
              this.handleAddNewRemoteAccessSelectOption
            )

            // Apps
            this.excelFillInApps(activity, parsedActivity, this.applications, this.handleAddNewAppSelectOption)

            // Utilities
            this.excelFillUtilities(activity, parsedActivity, this.utilities, this.handleAddNewUtilitySelectOption)

            // Equipments
            this.excelFillEquipment(activity, parsedActivity, this.equipments, this.handleAddNewEquipmentSelectOption)

            // Internal dependencies
            this.excelFillInternalDependencies(activity, parsedActivity, this.internalDependencies)

            // Supplier
            this.excelFillSuppliers(activity, parsedActivity, this.suppliers, this.handleAddNewSupplierSelectOption)

            // BCM Assessed
            this.excelFillBcmAssessed(activity, parsedActivity)

            // MTPD
            this.excelFillMTPD(activity, parsedActivity, this.mtdpTimeOptions)

            // RTO
            this.excelFillRTO(activity, parsedActivity, this.recoveryTimeOptions)
            this.excelFillInScenarios(activity, parsedActivity, this.disruptionScenarios)
          }

          onActionRowIndex++
        })
      }
    },

    /**
     * ======== DIV Drag and move
     */

    /**
     * Start Drag
     */
    startDrag(event) {
      this.isDragging = true
      this.startX = event.clientX
      this.startY = event.clientY
      this.scrollLeft = this.$refs.dataGridContent.$el.scrollLeft
      this.scrollTop = this.$refs.dataGridContent.$el.scrollTop
    },

    /**
     * Handle drag
     * @param {*} event
     */
    handleDrag(event) {
      if (!this.isDragging) {
        return
      }

      const deltaX = event.clientX - this.startX
      const deltaY = event.clientY - this.startY

      this.$refs.dataGridContent.$el.scrollLeft = this.scrollLeft - deltaX
      this.$refs.dataGridContent.$el.scrollTop = this.scrollTop - deltaY
    },

    /**
     * End drag
     */
    endDrag() {
      this.isDragging = false
    },

    /**
     * === Instruction
     */
    /**
     *
     */
    handleShowInstruction() {
      this.isShowInstructionModal = true
    },

    handleCloseBulkActivityInstructionModal() {
      this.isShowInstructionModal = false
    },

    /**
     * Backend Validator
     */
    getInputValidationError(key, index) {
      let errors = this.v$.activities?.$each?.$response?.$errors[index][key] ?? null
      if (errors && errors.length > 0) {
        return errors
      }

      errors = this.backendValidationErrors[index][key] ?? null

      if (errors && errors.length > 0) {
        return errors
      }

      return []
    },

    /**
     * Parse backend error
     * @param errors
     */
    parseBackendProcessError(errors) {
      let hasError500 = false
      for (let i = 0; i < errors?.length; i++) {
        const err = errors[i]
        if (err.type === "validation_exception") {
          const errKeys = Object.keys(err.errors)
          errKeys?.forEach((errKey) => {
            this.backendValidationErrors[err.index] = []
            this.backendValidationErrors[err.index][errKey] = err.errors[errKey]
          })
        }
        hasError500 = true
        if (this.activityHasError500.indexOf(err.index) === -1) {
          this.activityHasError500.push(err.index)
        }
      }
      if (hasError500) {
        this.globalStore.addErrorToastMessage(this.$t("activity.bulkAddActivities.messages.saveError"))
      }
    },

    /**
     * Parse backend error for custom row
     * @param errors
     * @param index
     */
    async parseBackendProcessErrorForIndex(errors, index) {
      let hasError500 = false
      for (let i = 0; i < errors?.length; i++) {
        const err = errors[i]
        if (err.type === "validation_exception") {
          const errKeys = Object.keys(err.errors)
          errKeys?.forEach((errKey) => {
            this.backendValidationErrors[index] = []
            this.backendValidationErrors[index][errKey] = err.errors[errKey]
          })
        }
        hasError500 = true
      }
      if (hasError500 && this.activityHasError500.indexOf(index) === -1) {
        this.activityHasError500.push(index)
      }
      await this.sleep(200)
    },

    /**
     * Clean backend validator
     * @param index
     * @param field
     */
    cleanBackendValidationErrors(index = null, field = null) {
      if (index != null) {
        if (field != null) {
          delete this.backendValidationErrors[index][field]
        } else {
          this.backendValidationErrors[index] = []
        }
      } else {
        for (let i = 0; i < this.activities.length; i++) {
          this.backendValidationErrors[i] = []
        }
      }
    },

    /**
     * Check backend validation is fixed
     * @returns {boolean}
     */
    isBackendValidatorHasError() {
      const activitiesError = this.backendValidationErrors.filter((recordError) => {
        return recordError && Object.keys(recordError)?.length > 0
      })

      return activitiesError?.length > 0
    },

    /**
     * Get header tooltip
     * @param index
     * @returns {*|string}
     */
    getFixHeaderTooltip(index) {
      if (this.activityHasError500.indexOf(index) !== -1) {
        return this.$t("activity.tooltips.activity_unsaved")
      } else if (this.activities[index].uid?.length > 0) {
        return this.$t("activity.tooltips.activity_saved")
      }
      return ""
    },
  },
}
</script>

<style>
.dragging {
  cursor: grabbing;
}

.tag-badge-group .tag-badge {
  width: 100%;
  justify-content: end;
  height: 22px;
  line-height: 1em;
  overflow: hidden;
  margin-bottom: 14px;
}
.tag-badge-group .tag-badge:last-of-type {
  margin-bottom: 4px;
}
.tag-badge-group .tag-badge span:first-child {
  width: 100%;
  text-align: right;
  display: inline-block;
  white-space: nowrap;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
