<template>
  <EcBox class="overflow-x-auto lg:overflow-visible mt-8 lg:mt-8 p-2">
    <!-- Header -->
    <EcFlex class="items-center">
      <EcHeadline as="h4" variant="h4" class="text-c1-800"> Business units </EcHeadline>

      <!-- Selected division -->
      <EcText
        v-if="selectedDivision"
        class="box-content text-xs text-c1-500 ml-4 p-1 border-1 border-c1-200 rounded-lg shadow-lg hover:cursor-pointer"
        @click="handleRemoveSelectedDivision"
      >
        {{ selectedDivision?.name }} <span class="font-semibold">x</span>
      </EcText>
    </EcFlex>

    <!-- Add button and Search box -->
    <EcFlex class="flex-grow items-center w-full md:w-auto mt-8 mb-4 mr-4">
      <EcFlex class="justify-start w-1/2">
        <!-- Add button -->
        <EcButton
          v-permissions:business_unit-create
          variant="primary-sm"
          class="mb-3 lg:mb-0"
          id="btn-add-bu"
          iconPrefix="PlusCircle"
          @click="handleClickAddBusinessUnit"
        >
          {{ $t("organization.add") }}
        </EcButton>
      </EcFlex>

      <!-- Search box -->
      <EcFlex class="justify-end w-1/2">
        <RSearchBox
          v-model="searchQuery"
          :isSearched="searchQuery !== ''"
          :placeholder="$t('organization.searchBU')"
          class="w-full md:max-w-xs text-sm"
          id="search-bu"
          @search="handleSearch"
          @clear-search="handleClearSearch"
        />
      </EcFlex>
    </EcFlex>
    <EcFlex class="flex-grow items-center w-full md:w-auto mt-8 mb-4 mr-4">
      <EcFlex class="justify-start w-2/3"></EcFlex>
      <EcFlex class="justify-end w-1/3">
        <RFormInput
          class="w-full"
          v-model="selectedSort"
          componentName="EcSelect"
          :options="sortOptions"
          :allowSelectNothing="false"
          :placeholder="$t('organization.sortBy.sortBy')"
          nameKey="label"
          valueKey="name"
          @change="handleSort"
        />
      </EcFlex>
    </EcFlex>
    <!-- Business Unit items -->
    <EcFlex v-if="!this.isLoading" class="lg:flex-wrap grid grid-cols-1">
      <BusinessUnitCardItem
        v-for="item in filteredBusinessUnit"
        :organizationUid="organization?.uid"
        :businessUnit="item"
        :key="item.uid"
        @handleDeletedBuItem="handleDeletedBuItem"
      />

      <!-- No data found -->
      <EcBox v-show="businessUnits.length <= 0">
        <EcText>{{ $t("core.noDataHere") }}</EcText>
      </EcBox>
    </EcFlex>

    <EcFlex v-else class="items-center">
      <EcSpinner />
    </EcFlex>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus :currentPage="currentPage" :limit="size" :totalCount="totalItems" class="mb-4 sm:mb-0" />
      <RPagination
        v-model="currentPage"
        :itemPerPage="size"
        :totalItems="totalItems"
        @input="setPage($event)"
        @pageSizeChanged="pageSizeChanged"
      />
    </EcFlex>
  </EcBox>
</template>

<script>
import BusinessUnitCardItem from "./BusinessUnitCardItem"
import { useBusinessUnitList } from "../../use/business_unit/useBusinessUnitList"
import { goto } from "@/modules/core/composables"
import { ref } from "vue"
import EcBox from "@/components/EcBox/index.vue"
import EcFlex from "@/components/EcFlex/index.vue"
import { getSortOptions } from "@/readybc/composables/helpers/sortHelper"
import { APIQueryBuilder } from "@/readybc/composables/helpers/apiQuery/apiQuery"
import { SortDirectionEnum, TypeCompareEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"
export default {
  name: "BusinessUnitList",
  components: {
    BusinessUnitCardItem,
    EcBox,
    EcFlex,
  },
  data() {
    const filters = {
      filter: [],
      page: 1,
    }

    return {
      isLoading: true,
      searchQuery: "",
      selectedSort: {},
      filteredBusinessUnit: [],
      filters,
      apiQueryBuilder: new APIQueryBuilder(),
      sortOptions: {
        byDivisionName: {
          name: "sort_by_division_name",
          label: this.$t("organization.sortBy.byDivisionName"),
        },
        byBusinessUnitName: {
          name: "sort_by_bu_name",
          label: this.$t("organization.sortBy.byBusinessUnitName"),
        },
      },
      size: 0,
      totalItems: 0,
    }
  },

  setup() {
    const businessUnits = ref([])
    const { currentPage, getBusinessUnits } = useBusinessUnitList()

    return {
      getBusinessUnits,
      businessUnits,
      currentPage,
      getSortOptions,
    }
  },
  props: {
    organization: {
      type: Object,
    },
    selectedDivision: {
      type: Object,
    },
  },

  computed: {
    organizationOrDivisionName() {
      return this.selectedDivision ? this.selectedDivision?.name : this.organization?.name
    },
  },

  mounted() {
    this.fetchBusinessUnits()
  },
  methods: {
    async fetchBusinessUnits() {
      this.isLoading = true

      const response = await this.getBusinessUnits(this.apiQueryBuilder.toQuery())

      if (response && response.data) {
        this.businessUnits = response.data
        this.filteredBusinessUnit = this.businessUnits
      }

      // Pagination
      this.currentPage = response.current_page
      this.size = response.per_page
      this.totalItems = response.total

      this.isLoading = false
    },

    async filterBusinessUnitsByDivision() {
      if (this.selectedDivision) {
        this.apiQueryBuilder.setFilter("division.uid", this.selectedDivision.uid).setPage(1)
      } else {
        this.apiQueryBuilder.clearFilter("division.uid")
      }
      await this.fetchBusinessUnits()
    },
    handleClickAddBusinessUnit() {
      goto("ViewBusinessUnitNew")
    },

    /**
     *
     */
    async handleDeletedBuItem() {
      await this.fetchBusinessUnits()
    },

    handleClearSearch() {
      this.searchQuery = ""
      this.apiQueryBuilder.clearFilter("name")
      this.fetchBusinessUnits()
    },

    async handleSearch() {
      if (this.searchQuery.length > 0) {
        this.apiQueryBuilder.setFilter("name", this.searchQuery, TypeCompareEnum.LIKE)
      } else {
        this.apiQueryBuilder.clearFilter("name")
      }
      await this.fetchBusinessUnits()
    },

    /**
     * Remove selected division
     */
    handleRemoveSelectedDivision() {
      this.$emit("handleRemoveSelectedDivision")
      this.filteredBusinessUnit = this.businessUnits
    },
    handleSort() {
      let field = "name"
      if (this.selectedSort === "sort_by_division_name") {
        field = "divisions|divisions.name"
      }
      this.apiQueryBuilder.clearSorts()
      this.apiQueryBuilder.setSorts(field, SortDirectionEnum.ASC)
      // Back to first page
      this.apiQueryBuilder.setPage(1)
      this.fetchBusinessUnits()
    },
    pageSizeChanged(size) {
      this.size = Number(size);
      this.apiQueryBuilder.pagination.per_page = Number(size);
      this.fetchBusinessUnits()
    },
  },
  watch: {
    selectedDivision() {
      this.filterBusinessUnitsByDivision()
    },

    currentPage() {
      this.apiQueryBuilder.setPage(this.currentPage)
      this.fetchBusinessUnits()
    },
  },
}
</script>
