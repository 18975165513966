import { reactive, ref, toRefs, watch } from "vue"
import * as api from "../api/userFetcher"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"
import { handleErrorForUser } from "../api"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"
// import dayjs from "dayjs"

export function useUserList() {
  const globalStore = useGlobalStore()

  const STATUS_CONFIRMED = "CONFIRMED"
  const STATUS_UNCONFIRMED = "UNCONFIRMED"

  const selectedCaseId = ref("")

  //  Filter Data
  const filterData = reactive({
    entityName: "",
    email: "",
    status: "",
    internalCode: "",
    role: "",
    createdAt: {
      type: "between",
      value: [],
      quickOptionValue: "",
    },
  })

  const { t } = useI18n()

  // Initial data
  const isLoading = ref(false)
  const users = ref([])
  const apiCriteria = reactive(new ApiCriteria('list_user'))

  // Fetch user lists
  async function fetchUserList() {
    try {
      isLoading.value = true
      const { data } = await api.getUserList(apiCriteria.toQuery())

      if (!data || data.error) {
        handleErrorForUser({ error: data?.error, $t: t })
      }

      if (data && data.data) {
        users.value = data.data

        apiCriteria.setTotalPage(data.total)
      }

      isLoading.value = false
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("user.errors.listUser"))
      isLoading.value = true
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        fetchUserList()
      }
    },
    { deep: true }
  )

  /**
   * Change page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        apiCriteria.resetPage()
        fetchUserList()
      }
    },
    { deep: true }
  )

  /**
   *
   * @param {*} data
   */
  async function handleSort(col, sorted) {
    const direction = sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC

    apiCriteria.setSort(col?.sortKey || col?.key, direction, col?.customSort)

    apiCriteria.resetPage()
    await fetchUserList()
  }

  // Fetch All users
  async function fetchAllUsers() {
    try {
      const { data } = await api.getAll()

      if (!data || data.error) {
        globalStore.addErrorToastMessage(data?.error || this.$t("user.errors.listUser"))
      }

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("user.errors.listUser"))
    }
  }

  // Fetch user lists
  async function reinviteUser(id) {
    try {
      const { data } = await api.reinviteUser(id)

      if (!data || data.error) {
        handleErrorForUser({ error: data?.error, $t: t })
      }

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("user.errors.listUser"))
    }
  }

  return {
    fetchAllUsers,
    fetchUserList,
    reinviteUser,
    t,
    selectedCaseId,
    ...toRefs(filterData),
    STATUS_CONFIRMED,
    STATUS_UNCONFIRMED,
    handleSort,
    apiCriteria,
    users,
    isLoading,
  }
}
