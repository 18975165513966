import { reactive, ref, watch } from "vue"
import * as api from "../api/reportFetcher"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export function useReportList() {
  const globalStore = useGlobalStore()

  const listReports = ref([])
  const apiCriteria = reactive(new ApiCriteria('list_report'))
  const isGetReportLoading = ref(false)

  const { t } = useI18n()

  // Default size
  apiCriteria.pagination.size = 20

  /**
   *
   * @returns
   */
  async function getReportList() {
    try {
      isGetReportLoading.value = true
      const { data } = await api.fetchListRepost(apiCriteria.toQuery())

      if (data && data.data) {
        listReports.value = data.data

        // Pagination
        apiCriteria.pagination.total = data.total
      }

      isGetReportLoading.value = false
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("report.errors.listReport"))
      listReports.value = []
      isGetReportLoading.value = false
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        getReportList()
      }
    },
    { deep: true }
  )

  /**
   * Change page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        apiCriteria.resetPage()
        getReportList()
      }
    },
    { deep: true }
  )

  /**
   * Column Sorting
   */
  const handleColumnSort = async (col, sorted) => {
    const direction = sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC

    apiCriteria.setSort(col?.sortKey || col?.key, direction, col?.customSort)

    apiCriteria.resetPage()
    await getReportList()
  }

  return {
    listReports,
    getReportList,
    handleColumnSort,
    apiCriteria,
    isGetReportLoading,
    t,
  }
}
