import * as api from "@/modules/resilience_plan/api/status/apiResiliencePlanStatus"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"
import { reactive, ref, watch } from "vue"

export function useResiliencePlanStatusList() {
  const apiCriteria = reactive(new ApiCriteria('list_resilience_plan_status'))

  const isLoading = ref(false)
  const statuses = ref([])

  /**
   *
   * @returns
   */
  const getResiliencePlanStatuses = async () => {
    try {
      isLoading.value = true
      const { data } = await api.fetchResiliencePlanStatuses(apiCriteria.toQuery())

      if (data && data.data) {
        statuses.value = data.data

        apiCriteria.setTotalPage(data.total)
      }

      isLoading.value = false

      return data
    } catch (error) {
      return false
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        getResiliencePlanStatuses()
      }
    },
    { deep: true }
  )

  /**
   * Change page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (parseInt(current) !== parseInt(oldVal)) {
        apiCriteria.resetPage()
        getResiliencePlanStatuses()
      }
    },
    { deep: true }
  )

  /**
   *
   * @param {*} col
   * @param {*} sorted
   */
  function handleSort(col, sorted) {
    const direction = sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC

    apiCriteria.setSort(col?.sortKey || col?.key, direction, col?.customSort)

    apiCriteria.resetPage()

    getResiliencePlanStatuses()
  }

  return {
    isLoading,
    apiCriteria,
    statuses,
    getResiliencePlanStatuses,
    handleSort,
  }
}
